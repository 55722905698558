import React from 'react';
import { TopbarProps } from '../../types_and_interfaces/topbar';
import styled from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useNavigate, useNavigation } from 'react-router-dom';

const Topbar = (props: TopbarProps) => {
	const { title } = props;
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<Container>
			<CaretIcon onClick={goBack} />
			<Title>{title || ''}</Title>
		</Container>
	);
};

export default Topbar;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100svw;
	box-sizing: border-box;
	padding: 8px 16px 16px;
	margin: 0px -40px 8px;
	border-bottom: 0.5px solid ${Colors.grey.darkGrey3};
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 700;
	color: ${Colors.primary.white};
	margin-left: auto;
	margin-right: auto;
`;

const CaretIcon = styled(ArrowForwardIosSharpIcon)`
	color: ${Colors.primary.red};
	transform: rotate(180deg);
`;
