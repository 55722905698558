import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import styled from 'styled-components';
import ContactDetails from '../../components/ContactDetails/ContactDetails';

const ExistingMembership = () => {
	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '48px' }} />
			<Title
				title='Oops'
				color={Colors.primary.white}
			/>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<CustomText
				text='Looks like you have an existing Select membership. Please contact customer support if you would like to cancel it and activate the GoWell gym pass.'
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '49px' }} />
			<ContactDetails />
			<Spacer space={{ x: '100%', y: '363px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default ExistingMembership;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
