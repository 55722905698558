import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import styled from 'styled-components';
import SuccessIcon from '../../assets/images/SuccessIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes } from '../../types_and_interfaces/common-context';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import TextButton from '../../components/CustomButtons/TextButton';


const PhotoUpdateSuccess = () => {

	const { flow } = useCommonContext();

	const { state } = useLocation();

    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email') || '';
    
	const navigate = useNavigate();
	const goToMiniProfile = () => {
		navigate('/miniProfile?email=' + email);
	}
	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '32px' }} />
			<IconContainer>
				<Icon src={SuccessIcon} />
			</IconContainer>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<Title
				title={'Your profile photo change was successful'}
				color={Colors.primary.white}
			/>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<CustomText
				text={'Your profile photo has now been updated. You will be able to see it on your profile shortly.'}
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
            <TextButton color={Colors.primary.red} onClick={goToMiniProfile}>Go Back to Profile</TextButton>
            <Spacer space={{ x: '100%', y: '120px' }} />
		</PageWrapper>
	);
};

export default PhotoUpdateSuccess;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

const Icon = styled.img`
	width: 191px;
	aspect-ratio: 1/1;
`;
