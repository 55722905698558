import React from 'react';
import { styled } from 'styled-components';
import SelectLogo from '../../assets/images/SELECT Logo.png';

const LogoHeader = () => {
	return (
		<></>
		// <LogoContainer>
		// 	<Logo src={SelectLogo} />
		// </LogoContainer>
	);
};

export default LogoHeader;

const LogoContainer = styled.div`
	display: block;
	width: 100%;
`;

const Logo = styled.img`
	width: 83px;
	aspect-ratio: 7/2;
`;
