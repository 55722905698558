import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import CloseIcon from '@mui/icons-material/Close';
import Spacer from '../Spacer/Spacer';
import JohnDoe from '../../assets/images/JohnDoe.svg';
import BorderedCornersBG from '../../assets/images/CornerBorders.svg';
import CircleIcon from '@mui/icons-material/Circle';
import QRCode from 'react-qr-code';
import { QRCodeDisplayProps } from '../../types_and_interfaces/qrcode-dispay';
import { GetCheckinQRCode } from '../../utils/services/api/miniProfile';
import moment from 'moment';
import { useCommonContext } from '../../context/CommonContext';

const QRCodeDisplay = (props: QRCodeDisplayProps) => {
	const { show = false, close = () => {}, userId, membershipId } = props;
	const [qrCode, setQRCode] = useState('');
	const [currentTime, setCurrentTime] = useState<number>(59);
	let intervalRef = useRef<any>(null).current;
	let timeOutRef = useRef<any>(null).current;

	const { hideLoader } = useCommonContext();

	const getCheckinQRCode = () => {
		if (membershipId && show) {
			GetCheckinQRCode(userId, membershipId).then((qrCode) => {
				setQRCode(qrCode);
				intiateTimer();
				hideLoader();
			});
		}
	};

	useEffect(() => {
		getCheckinQRCode();
	}, [userId, show]);

	const intiateTimer = () => {
		resetTimers();
		setCurrentTime(59);

		intervalRef = setInterval(() => {
			setCurrentTime((current) => (current > 0 ? current - 1 : current));
		}, 1000);

		timeOutRef = setTimeout(() => {
			resetTimers();
			getCheckinQRCode();
		}, 60000);
	};

	const resetTimers = () => {
		if (intervalRef) clearInterval(intervalRef);
		if (timeOutRef) clearTimeout(timeOutRef);
		intervalRef = null;
		timeOutRef = null;

		const interval_id = window.setInterval(function () {},
		Number.MAX_SAFE_INTEGER);

		for (let i = 1; i < interval_id; i++) {
			window.clearInterval(i);
		}
	};

	const onClose = () => {
		close();
		resetTimers();
	};

	return (
		<Wrapper show={show}>
			<CloseButtonContainer>
				<CloseButton onClick={onClose}>
					<CustomCloseIcon />
				</CloseButton>
			</CloseButtonContainer>
			<Spacer space={{ x: '100%', y: '37px' }} />
			<ProfilePicture src={props.imgeUrl} />
			<Spacer space={{ x: '100%', y: '21px' }} />
			<UserName>{props.name}</UserName>
			<Spacer space={{ x: '100%', y: '32px' }} />
			<IDText>ID: {props.membershipId}</IDText>
			<Spacer space={{ x: '100%', y: '29px' }} />
			<QRCodeWrapper>
				<ContentWrapper>
					<ActiveTextWrapper>
						<ActiveIcon />
						<span>Active</span>
					</ActiveTextWrapper>
					<Spacer space={{ x: '100%', y: '16px' }} />
					{qrCode && (
						<QRCode
							value={qrCode || 'https://selectapp.com'}
							size={185}
						/>
					)}
					{!qrCode && <Spacer space={{ x: '100%', y: '185px' }} />}
					<Spacer space={{ x: '100%', y: '16px' }} />
					<Timer>{moment(`${currentTime}`, 'ss').format('mm:ss')}</Timer>
				</ContentWrapper>
			</QRCodeWrapper>
			<Spacer space={{ x: '100%', y: '24px' }} />
			<ExpireText>Expiry: &nbsp;{props.expiryDate || '-'}</ExpireText>
		</Wrapper>
	);
};

export default QRCodeDisplay;

const Wrapper = styled.div<{ show?: boolean }>`
	display: block;
	position: absolute;
	top: ${(props) => (props?.show ? '0vh' : '100vh')};
	bottom: 0;
	left: 0;
	width: 100%;
	height: ${(props) => (props?.show ? '100vh' : '0vh')};
	box-sizing: border-box;
	background-color: ${Colors.grey.darkGrey2};
	z-index: 90;
	border-radius: 8px;
	padding: ${(props) => (props?.show ? '12px 25px' : 'unset')};
	overflow: ${(props) => (props?.show ? 'scroll' : 'hidden')};
	transition: 0.55s ease;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const CloseButton = styled.button`
	background: unset;
	border: 0px solid transparent;
	color: ${Colors.primary.white};
	padding: 0px;
`;

const CustomCloseIcon = styled(CloseIcon)`
	font-size: 30px !important;
`;

const ProfilePicture = styled.img`
	display: block;
	width: 120px;
	aspect-ratio: 1/1;
	margin-left: auto;
	margin-right: auto;
	border-radius: 100%;
	object-fit: cover;
`;

const UserName = styled.div`
	font-family: PlayfairDisplay;
	font-size: 22px;
	font-weight: 700;
	line-height: 26px;
	text-align: center;
	color: ${Colors.primary.white};
`;

const IDText = styled.div`
	font-size: 14px;
	font-family: Roboto;
	color: ${Colors.primary.white};
`;

const QRCodeWrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
	aspect-ratio: 1/1;
	border-radius: 10px;
	background: ${Colors.primary.white};
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
	padding: 14px;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	box-sizing: border-box;
	width: 100%;
	aspect-ratio: 1/1;
	background: url(${BorderedCornersBG});
	background-repeat: no-repeat;
	background-size: cover;
`;

const ActiveTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: Roboto;
	color: ${Colors.primary.black};
	text-align: center;
`;

const ActiveIcon = styled(CircleIcon)`
	font-size: 16px !important;
	color: ${Colors.system.success};
	margin-right: 8px;
`;

const Timer = styled.div`
	font-size: 16px;
	font-family: Roboto;
	color: ${Colors.primary.black};
	text-align: center;
`;

const ExpireText = styled.div`
	font-size: 14px;
	font-family: Roboto;
	text-align: center;
	color: ${Colors.primary.white};
`;
