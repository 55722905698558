import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import {
	customPasswordInputProps,
	passwordInputWrapper,
} from '../../types_and_interfaces/custom-input';
import { Colors } from '../../utils/configs/Colors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ErrorText from '../CustomText/ErrorText';

const CustomPasswordInput = (props: customPasswordInputProps) => {
	const {
		title,
		value,
		onChange = (_e) => {},
		inputProps,
		enablePasswordToggle,
		disableErrorFade,
		disableErrorBox,
		error = false,
		getErrorState = (_errorState) => {},
	} = props;

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [errorBoxState, setErrorBoxState] = useState<boolean>(false);
	const [isCapsOn, setIsCapsOn] = useState<boolean>(false);

	const toggleState = () => {
		setShowPassword((current) => !current);
	};

	const showErrorBox = () => {
		let state = !(
			validateCharacterLength() &&
			validateUpperCase() &&
			validateLowerCase() &&
			validateNumber()
		);
		if (state && value !== '' && !disableErrorBox) {
			setErrorBoxState(true);
		} else {
			hideErrorBox();
		}
	};

	const hideErrorBox = () => {
		if (!disableErrorFade) {
			setErrorBoxState(false);
		}
	};

	const validateCharacterLength = () => {
		if (typeof value !== 'string' || !!value === false) {
			return false;
		}
		return value.length >= 6;
	};

	const validateUpperCase = () => {
		const regex = /[A-Z]+/;
		if (typeof value !== 'string' || !!value === false) {
			return false;
		}
		return regex.test(value);
	};

	const validateLowerCase = () => {
		const regex = /[a-z]+/;
		if (typeof value !== 'string' || !!value === false) {
			return false;
		}
		return regex.test(value);
	};

	const validateNumber = () => {
		const regex = /[0-9]+/;
		if (typeof value !== 'string' || !!value === false) {
			return false;
		}
		return regex.test(value);
	};

	const checkCapsLockState = (event: any) => {
		if (event.code === 'CapsLock') {
			let isCapsLockOn = event.getModifierState('CapsLock');
			if (isCapsLockOn) {
				setIsCapsOn(true);
				console.log('Caps Lock turned on');
			} else {
				setIsCapsOn(false);
				console.log('Caps Lock turned off');
			}
		}
	};

	useEffect(() => {
		document.addEventListener('keyup', checkCapsLockState);
		document.addEventListener('keydown', checkCapsLockState);

		return () => {
			document.removeEventListener('keyup', checkCapsLockState);
			document.removeEventListener('keydown', checkCapsLockState);
		};
	}, []);

	useEffect(() => {
		showErrorBox();
		getErrorState(
			!(
				validateCharacterLength() &&
				validateUpperCase() &&
				validateLowerCase() &&
				validateNumber()
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<Container>
			<TitleContainer>
				<TitleText>{title}</TitleText>
			</TitleContainer>
			<InputWrapper
				enabled={!!enablePasswordToggle}
				error={error || errorBoxState || isCapsOn}>
				<Input
					type={showPassword ? 'text' : 'password'}
					value={value}
					onChange={(e) => {
						onChange(e);
					}}
					{...inputProps}
					onFocus={(...props) => {
						showErrorBox();
						if (
							inputProps?.onFocus &&
							typeof inputProps?.onFocus === 'function'
						)
							inputProps?.onFocus(...props);
					}}
					onBlur={(...props) => {
						hideErrorBox();
						if (inputProps?.onBlur && typeof inputProps?.onBlur === 'function')
							inputProps?.onBlur(...props);
					}}
				/>
				<ToggleButton onClick={toggleState}>
					{showPassword ? (
						<VisibilityOutlinedIcon />
					) : (
						<VisibilityOffOutlinedIcon />
					)}
				</ToggleButton>
			</InputWrapper>
			<ErrorContainer>
				<ErrorBox show={errorBoxState}>
					<ErrorBoxTitle>Your password must contain</ErrorBoxTitle>
					<ErrorBoxItem>
						<ItemIcon
							iconColor={
								validateCharacterLength()
									? Colors.system.success
									: Colors.secondary.offGrey
							}
						/>
						<ItemTitle>At least 6 charachters</ItemTitle>
					</ErrorBoxItem>
					<ErrorBoxItem>
						<ItemIcon
							iconColor={
								validateUpperCase()
									? Colors.system.success
									: Colors.secondary.offGrey
							}
						/>
						<ItemTitle>Uppercase letter</ItemTitle>
					</ErrorBoxItem>
					<ErrorBoxItem>
						<ItemIcon
							iconColor={
								validateLowerCase()
									? Colors.system.success
									: Colors.secondary.offGrey
							}
						/>
						<ItemTitle>Lowercase letter</ItemTitle>
					</ErrorBoxItem>
					<ErrorBoxItem>
						<ItemIcon
							iconColor={
								validateNumber()
									? Colors.system.success
									: Colors.secondary.offGrey
							}
						/>
						<ItemTitle>A number</ItemTitle>
					</ErrorBoxItem>
				</ErrorBox>
			</ErrorContainer>
		</Container>
	);
};

export default CustomPasswordInput;

const Container = styled.div`
	width: 100%;
	margin-bottom: 8px;
`;

const TitleContainer = styled.div`
	margin-bottom: 6px;
`;

const TitleText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const Input = styled.input`
	background-color: unset;
	border: unset;
	padding: 12px 16px;
	width: 100%;
	box-sizing: border-box;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
`;

const InputWrapper = styled.div<passwordInputWrapper>`
	${(props) => (props.enabled ? 'display: flex;' : '')}
	flex-direction: row;
	align-items: center;
	background: ${Colors.primary.white};
	border: 1px solid
		${(props) => (props.error ? Colors.primary.red : Colors.secondary.grey)};
	border-radius: 5px;

	&:focus {
		outline: none;
	}
`;

const ToggleButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: unset;
	border: unset;
	padding: 8px;
	cursor: pointer;
`;

const ErrorContainer = styled.div`
	position: relative;
`;

const ErrorBox = styled.div<{ show: boolean }>`
	position: absolute;
	background: ${Colors.grey.darkGrey2};
	border-radius: 8px;
	min-width: 150px;
	padding: 8px;
	height: ${(props) => (props.show ? '116px' : '0px')};
	opacity: ${(props) => (props.show ? '1' : '0')};
	overflow: hidden !important;
	transition: 0.5s ease-in;
`;

const ErrorBoxTitle = styled.div`
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: ${Colors.primary.white};
	margin-bottom: 8px;
`;

const ErrorBoxItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const ItemIcon = styled(DoneIcon)<{ iconColor: string }>`
	color: ${(props) => props.iconColor};
	font-size: 8px;
`;

const ItemTitle = styled.div`
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: ${Colors.primary.white};
	margin-left: 4px;
`;
