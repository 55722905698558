import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import Loading from '../../screens/Loading/Loading';
import { PopupProps } from '../../types_and_interfaces/popup-component';

const LoaderPopup = (props: PopupProps) => {
	const { show } = props;

	return (
		<Wrapper show={show}>
			<Loading />
		</Wrapper>
	);
};

export default LoaderPopup;

const Wrapper = styled.div<{ show?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 0vw;
	height: 0vh;
	opacity: 0;
	box-sizing: border-box;
	background-color: ${Colors.primary.black};
	z-index: 90;
	border-radius: 8px;
	padding: unset;
	overflow: hidden;
	transition: 0.55s ease;
	animation: ${(props) => (props?.show ? 'show 1s' : 'unset')};
	animation-fill-mode: ${(props) => (props?.show ? 'forwards' : 'unset')};

	@keyframes show {
		0% {
			width: 0vw;
			height: 0vh;
			opacity: 0;
		}

		20% {
			width: 100vw;
			height: 100vh;
			opacity: 0;
		}

		100% {
			width: 100vw;
			height: 100vh;
			opacity: 0.8;
		}
	}
`;
