/* eslint-disable no-useless-escape */
export interface LoginProps {
	Username: string;
	Password: string;
	RememberMe?: boolean;
	ReturnUrl?: string;
	GoWellUserId?: string;
}

export interface LoginPropsValid {
	Username: boolean;
	Password: boolean;
}

export const LoginPropsPattern = {
	Username: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
	Password: /([A-z0-9\@\#\$\%\&\*\!]){6,}/,
};
