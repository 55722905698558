import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { PopupProps } from '../../types_and_interfaces/popup-component';

const ProfileInUsePopup = (props: PopupProps) => {
	const { show, confirmAction = () => {}, cancelAction = () => {} } = props;

	return (
		<Wrapper show={show}>
			<Popup>
				<Body>
					<Title>Profile in use</Title>
					<Message>
                        Your GoWell profile is associated with another Select email. Please Log In, or contact support.
					</Message>
				</Body>
				<ButtonRow>
					<ButtonLeft onClick={cancelAction}>Support</ButtonLeft>
					<Button onClick={confirmAction}>Log In</Button>
				</ButtonRow>
			</Popup>
		</Wrapper>
	);
};

export default ProfileInUsePopup;

const Wrapper = styled.div<{ show?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	/* top: ${(props) => (props?.show ? '0vh' : '100vh')}; */
	bottom: ${(props) => (props?.show ? '0vh' : '-100vh')};
	left: 0;
	width: 100%;
	height: ${(props) => (props?.show ? '100vh' : '0vh')};
	box-sizing: border-box;
	background-color: ${Colors.primary.black}73;
	z-index: 90;
	border-radius: 8px;
	padding: ${(props) => (props?.show ? '12px 56px' : 'unset')};
	overflow: hidden;
	transition: 0.55s ease;
`;

const Popup = styled.div`
	width: 100%;
	box-sizing: border-box;
	background-color: rgba(252, 252, 252, 0.95);
	backdrop-filter: blur(13.5px);
	border-radius: 14px;
`;

const Body = styled.div`
	text-align: center;
	padding: 24px 16px;
`;

const Title = styled.div`
	color: ${Colors.grey.darkGrey8};
	font-size: 16px;
	font-weight: 700;
	letter-spacing: -0.41px;
	margin-bottom: 10px;
`;

const Message = styled.div`
	font-size: 13px;
	line-height: 16px;
	letter-spacing: -0.08px;
`;

const ButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-top: 0.5px solid ${Colors.secondary.grey5};
`;

const Button = styled.button`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 12px;
	text-align: center;
	background-color: unset;
	border: unset;
	color: ${Colors.primary.blue};
	font-size: 17px;
	letter-spacing: -0.41px;
`;

const ButtonLeft = styled(Button)`
	border-right: 0.5px solid ${Colors.secondary.grey5};
`;
