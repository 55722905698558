import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Font Import
import './assets/fonts/SF-Pro-Display-Black.ttf';
import './assets/fonts/SF-Pro-Display-BlackItalic.ttf';
import './assets/fonts/SF-Pro-Display-Bold.ttf';
import './assets/fonts/SF-Pro-Display-BoldItalic.ttf';
import './assets/fonts/SF-Pro-Display-Heavy.ttf';
import './assets/fonts/SF-Pro-Display-HeavyItalic.ttf';
import './assets/fonts/SF-Pro-Display-Light.ttf';
import './assets/fonts/SF-Pro-Display-LightItalic.ttf';
import './assets/fonts/SF-Pro-Display-Medium.ttf';
import './assets/fonts/SF-Pro-Display-MediumItalic.ttf';
import './assets/fonts/SF-Pro-Display-Regular.ttf';
import './assets/fonts/SF-Pro-Display-RegularItalic.ttf';
import './assets/fonts/SF-Pro-Display-Semibold.ttf';
import './assets/fonts/SF-Pro-Display-SemiboldItalic.ttf';
import './assets/fonts/SF-Pro-Display-Thin.ttf';
import './assets/fonts/SF-Pro-Display-ThinItalic.ttf';
import './assets/fonts/SF-Pro-Display-Ultralight.ttf';
import './assets/fonts/SF-Pro-Display-UltralightItalic.ttf';
import './assets/fonts/PlayfairDisplay.ttf';
import './assets/fonts/PlayfairDisplay-Italic.ttf';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
