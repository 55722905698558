import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Spacer from '../Spacer/Spacer';
import SideBarIcon from '../../assets/images/SidebarIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes } from '../../types_and_interfaces/common-context';

const Sidebar = (props: any) => {

	const {
		email
	} = props;

	const [show, setShow] = useState<boolean>(false);

	const {updateFlow} = useCommonContext();

	const navigate = useNavigate();

	return (
		<>
			<OpenButton onClick={() => setShow((current) => !current)}>
				<OpenButtonIcon src={SideBarIcon} />
			</OpenButton>
			<Wrapper show={show}>
				<Backdrop>
					<Container>
						<CloseButtonContainer>
							<CloseButton onClick={() => setShow((current) => !current)}>
								<CustomCloseIcon />
							</CloseButton>
						</CloseButtonContainer>
						<Spacer space={{ x: '100%', y: '72px' }} />
						<Row onClick={() => navigate('/privacyPolicy')}>
							<RowText>Privacy Policy</RowText>
							<IconRight />
						</Row>
						<Row onClick={() => navigate('/termsConditions')}>
							<RowText>Terms and Conditions</RowText>
							<IconRight />
						</Row>
						<Row onClick={() => navigate('/feedbackSupport')}>
							<RowText>Feedback & Support</RowText>
							<IconRight />
						</Row>
						<Row onClick={() =>{ updateFlow(FlowTypes.UpdatePassword); navigate('/enterCurrentPassword', {state: {email: email}})}}>
							<RowText>Change Password</RowText>
							<IconRight />
						</Row>
						<Row onClick={() =>{ updateFlow(FlowTypes.UpdateMobile); navigate('/enterYourEmailchangeMobile', {state: {email: email}})}}>
							<RowText>Change Mobile Number</RowText>
							<IconRight />
						</Row>
					</Container>
				</Backdrop>
			</Wrapper>
		</>
	);
};

export default Sidebar;

const OpenButton = styled.button`
	position: absolute;
	background: unset;
	border: 0px solid transparent;
	right: 40px;
	top: 20px;
	z-index: 88;
	padding: unset;
`;

const OpenButtonIcon = styled.img``;

const Wrapper = styled.div<{ show?: boolean }>`
	display: block;
	position: absolute;
	width: ${(props) => (props.show ? '100%' : '0%')};
	height: 100vh;
	overflow: hidden;
	right: 0px;
	top: 0px;
	z-index: 99;
	transition: 0.55s ease;
`;

const Backdrop = styled.div<{ show?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	width: 100vw;
	height: 100%;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	backdrop-filter: blur(15px) !important;
	-webkit-backdrop-filter: blur(15px) !important;
	background-color: ${Colors.primary.black}22;
`;

const Container = styled.div`
	width: 80vw;
	min-width: 80vw;
	max-width: 80vw;
	height: 100%;
	box-sizing: border-box;
	background-color: ${Colors.grey.darkGrey2};
	padding: 30px 34px;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

const CloseButton = styled.button`
	background: unset;
	border: 0px solid transparent;
	color: ${Colors.primary.white};
	padding: 0px;
`;

const CustomCloseIcon = styled(CloseIcon)`
	font-size: 17.5px !important;
`;

const Row = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	background: unset;
	border: 0px solid transparent;
	padding: 26px 5px 26px 0px;
	border-bottom: 1px solid ${Colors.primary.white}0D;
	cursor: pointer;
`;

const RowText = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const IconRight = styled(ChevronRight)`
	color: ${Colors.primary.red};
`;
