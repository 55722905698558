import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { customTextProps } from '../../types_and_interfaces/custom-text';

const CustomText = (props: customTextProps) => {
	const { styles, text, mv, mh, alignment = 'left', color, size } = props;
	return (
		<CustomTextContainer
			style={styles?.container}
			margin={`${mh || '0px'} ${mv || '0px'}`}>
			<CustomTextText
				style={styles?.text}
				alignment={alignment}
				color={color}
				size={size}>
				{text}
			</CustomTextText>
		</CustomTextContainer>
	);
};

export default CustomText;

const CustomTextContainer = styled.div<{ margin?: string }>`
	text-align: center;
	margin: ${(props) => (props.margin ? props.margin : 'unset')};
`;

const CustomTextText = styled.div<{ alignment?: string; color?: string, size?: string }>`
	font-weight: 400;
	font-size: ${(props) => props.size || '14px'};
	line-height: 17px;
	text-align: ${(props) => props.alignment || 'left'};
	color: ${(props) => props.color || Colors.grey.lightGrey2};
`;
