import CryptoJS from "crypto-js";

export const CommonEncryption = (text: string) => {
  const key = CryptoJS.enc.Utf8.parse("8080808080808080");
  const iv = CryptoJS.enc.Utf8.parse("8080808080808080");

  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};
