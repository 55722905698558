import React, { useCallback, useMemo, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import Title from '../../components/Title/Title';
import CustomText from '../../components/CustomText/CustomText';
import ProfilePicturePreviewBox from '../../components/ProfilePicturePreviewBox/ProfilePicturePreviewBox';
import Spacer from '../../components/Spacer/Spacer';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import TextButton from '../../components/CustomButtons/TextButton';
import { Colors } from '../../utils/configs/Colors';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import { uploadProfilePicture } from '../../utils/services/api/profilePicture';
import { UploadProfilePictureProps } from '../../types_and_interfaces/upload-profile-picture';
import { useLocation, useNavigate } from 'react-router-dom';
import { BuyGoWellGymMembership } from '../../utils/services/api/signup';
import {
	GetMiniProfileById,
	GetMiniProfileByNewB2BLoginUserId,
} from '../../utils/services/api/signup';
import { useCommonContext } from '../../context/CommonContext';
import {
	FlowTypes,
	ToastTypes,
} from '../../types_and_interfaces/common-context';
import Resizer from "react-image-file-resizer";

const AddProfilePhoto = () => {
	const { addToast, flow } = useCommonContext();
	const search = useLocation().search;
	const UUID = new URLSearchParams(search).get('userId') || '';
	const UserEmail = new URLSearchParams(search).get('userEmail') || '';

	const [imageUploaderOpenState, setImageUploaderOpenState] =
		useState<boolean>(false);

	const [image, setImage] = useState<{
		base64: string;
		type: string;
		size: number;
		width: number;
		height: number;
	}>({
		base64: '',
		type: '',
		size: 0,
		width: 0,
		height: 0,
	});

	const navigate = useNavigate();

	const open = async () => {
		if (validateImage()) {
			upload();
		} else {
			setImageUploaderOpenState(true);
		}
	};

	const close = () => {
		setImageUploaderOpenState(false);
	};

	const resizeFile = (file : any) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				300,
				300,
				"PNG",
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				"File"
			);
		});

	const hanldeImageChange = async (img: any) => {

		const resizedImg = await resizeFile(img) as any;

		const reader = new FileReader();
		reader.readAsDataURL(img);
		reader.onloadend = () => {
			let i = new Image();
			i.onload = () => {
				if (i.width && i.height) {
					setImage({
						base64: resizedImg || reader.result?.toString() || '',
						type: "image/png" || img?.type || '',
						size: 64000 || img?.size || 0,
						width: 300 || i.width,
						height: 300 || i.height,
					});
				}
			};
			i.src = resizedImg || reader.result?.toString() || '';
		};
		close();
	};

	const upload = () => {
		console.log(image);
		let data: UploadProfilePictureProps = {
			documentOwnerTypeId: 10, //TODO: 6
			documentOwnerId: UUID, //TODO: aspnetuser Id
			base64Value: image?.base64 || '',
			documentFormat: image?.type || '',
		};

		if (flow === FlowTypes.MiniProfile) {
			data.documentOwnerTypeId = 6;
		}

		uploadProfilePicture(data).then((res) => {
			window.sessionStorage.clear();
			callNativeApp();
			if (flow === FlowTypes.MiniProfile) {
				navigate(`/miniProfile?&email=${UserEmail}`);
			} else {
				GetMiniProfileByNewB2BLoginUserId(UUID).then((data) => {
					buyGoWellGymMembership();
				});
			}
		});
	};

	const buyGoWellGymMembership = () => {
		BuyGoWellGymMembership(UUID).then((res) => {
			window.location.href = res;
		});
	};

	const validateImage = useCallback(() => {
		const maxImageSize = 20971520;
		let valid = false;
		console.log(image);

		if (image.size > 0 && image.size < maxImageSize) {
			if (
				image.width <= 1600 &&
				image.width > 0 &&
				image.height <= 1200 &&
				image.height > 0
			) {
				valid = true;
			} else {
				addToast({
					type: ToastTypes.Error,
					message:
						'Your uploaded failed. Please choose a photo that matches our criteria, or try again.',
				});
			}
		}
		return valid;
	}, [image]);

	const goBack = () => {
		navigate(-1);
	};

	const callNativeApp = () => {
		try {
            (window as any).webkit.messageHandlers.callbackHandler.postMessage("SelectProfileCreated");
        } catch(err) {
            console.log('The native context does not exist yet');
        }
	}

	const ProfilePicture = useMemo(
		() => (
			<ProfilePicturePreviewBox
				image={image?.base64 || undefined}
				error={!!image.base64 && !validateImage()}
			/>
		),
		[image, validateImage]
	);

	return (
		<PageWrapper>
			<LogoHeader />
			<Title title='Add profile photo' />
			<CustomText text='Please take a photo where the face is clearly visible.' />
			<Spacer space={{ x: '100%', y: '16px' }} />
			{ProfilePicture}
			<Spacer space={{ x: '100%', y: '40px' }} />
			<CustomText
				text={
					<>
						<b>Please note:</b> This photo will be used as identification, and
						further changes to photos may require an approval-based process.
					</>
				}
			/>
			<Spacer space={{ x: '100%', y: '27%' }} />
			<PrimaryButton onClick={open}>
				{image.size > 0 ? 'Continue' : 'Continue'}
			</PrimaryButton>
			<TextButton
				color={Colors.primary.red}
				onClick={goBack}>
				Go Back
			</TextButton>
			<Spacer space={{ x: '100%', y: '28px' }} />
			<ImageUploader
				show={imageUploaderOpenState}
				close={close}
				onChange={hanldeImageChange}
			/>
			<GoWellFooter />
		</PageWrapper>
	);
};

export default AddProfilePhoto;
