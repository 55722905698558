import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';

const BorderButton = styled.button<{ background?: string; color?: string; borderColor?: string; }>`
	width: -webkit-fill-available;
	background: ${(props) =>
		props.disabled
			? Colors.secondary.grey
			: props?.background || Colors.primary.red};
	border-color: ${(props)=> props?.borderColor || 'none'};;
	border-radius: 8px;
	color: ${(props) =>
		props.disabled
			? Colors.grey.lightGrey
			: props?.color || Colors.primary.white} !important;
	padding: 14px;
	text-align: center;
	font-weight: 510;
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
`;

export default BorderButton;
