import { routeType } from '../types_and_interfaces/route';
import SignUp from '../screens/SignUp/SignUp';
import EmailConfirmation from '../screens/EmailConfirmation/EmailConfirmation';
import MobileConfirmation from '../screens/MobileConfirmation/MobileConfirmation';
import AddProfilePhoto from '../screens/AddProfilePhoto/AddProfilePhoto';
import Login from '../screens/Login/Login';
import RecoverPassword from '../screens/RecoverPassword/RecoverPassword';
import CreateNewPassword from '../screens/CreateNewPassword/CreateNewPassword';
import CreateNewPasswordV2 from '../screens/CreateNewPassword/CreateNewPasswordV2';
import EnterCurrentPassword from '../screens/ChangePassword/EnterCurrentPassword';
import CreatedNewPassword from '../screens/CreatedNewPassword/CreatedNewPassword';
import CreatedNewPasswordV2 from '../screens/CreatedNewPassword/CreatedNewPasswordV2';
import AddNewMobileNumber from '../screens/ChangeMobileNumber/AddNewMobileNumber';
import ChangedMobileNumber from '../screens/ChangeMobileNumber/ChangedMobileNumber';
import FeedbackSupport from '../screens/FeedbackSupport/FeedbackSupport';
import PaymentSuccess from '../screens/PaymentSuccess/PaymentSuccess';
import PaymentFailed from '../screens/PaymentFailed/PaymentFailed';
import ExistingMembership from '../screens/ExistingMembership/ExistingMembership';
import PrivacyPolicy from '../screens/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from '../screens/TermsConditions/TermsConditions';
import SignupSuccess from '../screens/SignupSuccess/SignupSuccess';
import MiniProfileSuccess from '../screens/MiniProfileSuccess/MiniProfileSuccess';
import ErrorTechIssue from '../screens/ErrorTechIssue/ErrorTechIssue';
import ErrorConnectionLost from '../screens/ErrorConnectionLost/ErrorConnectionLost';
import Error404 from '../screens/Error404/Error404';
import MiniProfile from '../screens/MiniProfile/MiniProfile';
import ChurnSelectIdEmpty from '../screens/ChurnSelectIdEmpty/ChurnSelectIdEmpty';
import Loading from '../screens/Loading/Loading';
import MembershipCancelSuccess from '../screens/MembershipCancelSuccess/MembershipCancelSuccess';
import Venues from '../screens/Venues/Venues';
import AccountSupport from '../screens/SignUp/AccountSupport';
import EmailConfirmationSignUp from '../screens/SignUp/EmailConfirmationSignUp';
import MobileConfirmationSignUp from '../screens/SignUp/MobileConfirmationSignUp';
import EmailConfirmationRecoveryPwd from '../screens/RecoverPassword/EmailConfirmationRecoveryPwd';
import MobileConfirmationRecoveryPwd from '../screens/RecoverPassword/MobileConfirmationRecoveryPwd';
import EnterYourEmailChangePwd from '../screens/ChangePassword/EnterYourEmailChangePwd';
import EmailConfirmationChangePwd from '../screens/ChangePassword/EmailConfirmationChangePwd';
import MobileConfirmationChangePwd from '../screens/ChangePassword/MobileConfirmationChangePwd';
import EnterYourEmailChangeMobile from '../screens/ChangeMobileNumber/EnterYourEmailChangeMobile';
import EmailConfirmationChangeMobile from '../screens/ChangeMobileNumber/EmailConfirmationChangeMobile';
import MobileConfirmationChangeMobile from '../screens/ChangeMobileNumber/MobileConfirmationChangeMobile';
import PaymentSuccessChurnFlow from '../screens/ChurnSelectIdEmpty/PaymentSuccessChurnFlow';
import UpdateProfilePhoto from '../screens/UpdateProfilePhoto/UpdateProfilePhoto';
import PaymentSuccessMiniProfile from '../screens/MiniProfile/PaymentSuccessMiniProfile';
import PurchasingSupport from '../screens/ChurnSelectIdEmpty/PurchasingSupport';
import PhotoUpdateSuccess from '../screens/UpdateProfilePhoto/PhotoUpdateSuccess';
import ErrorRecoverPassword from '../screens/RecoverPassword/ErrorRecoverPassword';
import ErrorChangeMobileNumber from '../screens/ChangeMobileNumber/ErrorChangeMobileNumber';
import ErrorChangePassword from '../screens/ChangePassword/ErrorChangePassword';
import PaymentSuccessSubscribeFlow from '../screens/ChurnSelectIdEmpty/PaymentSuccessSubscribeFlow';
import PurchasingSupport2 from '../screens/ChurnSelectIdEmpty/PurchasingSupport2';
import UserDetail from '../screens/Admin/UserDetail';

const Routes: routeType[] = [
	{
		path: '/',
		element: <SignUp />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/signup',
		element: <SignUp />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/emailConfirmationSignUp',
		element: <EmailConfirmationSignUp />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/mobileConfirmationSignUp',
		element: <MobileConfirmationSignUp />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/emailConfirmation',
		element: <EmailConfirmation />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/mobileConfirmation',
		element: <MobileConfirmation />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/addProfilePhoto',
		element: <AddProfilePhoto />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/updateProfilePhoto',
		element: <UpdateProfilePhoto />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/photoUpdateSuccess',
		element: <PhotoUpdateSuccess />,
		routerConfig: {
			errorElement: <></>,
		},
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/recoverPassword',
		element: <RecoverPassword />,
	},
	{
		path: '/errorRecoverPwd',
		element: <ErrorRecoverPassword />,
	},
	{
		path: '/enterYourEmailChangePwd',
		element: <EnterYourEmailChangePwd />,
	},
	{
		path: '/emailConfirmationChangePwd',
		element: <EmailConfirmationChangePwd />,
	},
	{
		path: '/mobileConfirmationChangePwd',
		element: <MobileConfirmationChangePwd />,
	},
	{
		path: '/emailConfirmationRecoveryPwd',
		element: <EmailConfirmationRecoveryPwd />,
	},
	{
		path: '/mobileConfirmationRecoveryPwd',
		element: <MobileConfirmationRecoveryPwd />,
	},
	{
		path: '/createNewPassword',
		element: <CreateNewPassword />,
	},
	{
		path: '/createNewPasswordV2',
		element: <CreateNewPasswordV2 />,
	},
	{
		path: '/enterCurrentPassword',
		element: <EnterCurrentPassword />,
	},
	{
		path: '/errorChangePwd',
		element: <ErrorChangePassword />,
	},
	{
		path: '/createdNewPassword',
		element: <CreatedNewPassword />,
	},
	{
		path: '/createdNewPasswordv2',
		element: <CreatedNewPasswordV2 />,
	},
	{
		path: '/enterYourEmailchangeMobile',
		element: <EnterYourEmailChangeMobile />,
	},
	{
		path: '/emailConfirmationChangeMobile',
		element: <EmailConfirmationChangeMobile />,
	},
	{
		path: '/addNewMobileNumber',
		element: <AddNewMobileNumber />,
	},
	{
		path: '/errorChangeMobile',
		element: <ErrorChangeMobileNumber />,
	},
	{
		path: '/mobileConfirmationChangeMobile',
		element: <MobileConfirmationChangeMobile />,
	},
	{
		path: '/changedMobileNumber',
		element: <ChangedMobileNumber />,
	},
	{
		path: '/feedbackSupport',
		element: <FeedbackSupport />,
	},
	{
		path: '/paymentSuccess',
		element: <PaymentSuccess />,
	},
	{
		path: '/paymentFailed',
		element: <PaymentFailed />,
	},
	{
		path: '/existingMembership',
		element: <ExistingMembership />,
	},
	{
		path: '/privacyPolicy',
		element: <PrivacyPolicy />,
	},
	{
		path: '/termsConditions',
		element: <TermsConditions />,
	},
	{
		path: '/signupSuccess',
		element: <SignupSuccess />,
	},
	{
		path: '/miniProfileSuccess',
		element: <MiniProfileSuccess />,
	},
	{
		path: '/errorTechIssue',
		element: <ErrorTechIssue />,
	},
	{
		path: '/errorConnectionLost',
		element: <ErrorConnectionLost />,
	},
	{
		path: '/errorNotFound',
		element: <Error404 />,
	},
	{
		path: '/miniProfile',
		element: <MiniProfile />,
	},
	{
		path: '/paymentSuccessMiniProfile',
		element: <PaymentSuccessMiniProfile />
	},
	{
		path: '/churnSelectIdEmpty',
		element: <ChurnSelectIdEmpty />,
	},
	{
		path: '/paymentSuccessChurnFlow',
		element: <PaymentSuccessChurnFlow />,
	},
	{
		path: '/paymentSuccessSubscribeFlow',
		element: <PaymentSuccessSubscribeFlow />,
	},
	{
		path: '/loading',
		element: <Loading />,
	},
	{
		path: '/membershipCancelSuccess',
		element: <MembershipCancelSuccess />,
	},
	{
		path: '/venues',
		element: <Venues />,
	},
	{
		path: '/accountSupport',
		element: <AccountSupport />
	},
	{
		path: '/purchasingSupport',
		element: <PurchasingSupport />
	},
	{
		path: '/purchasingSupport2',
		element: <PurchasingSupport2 />
	},
	{
		path: '/admin/user',
		element: <UserDetail />
	}
];

export default Routes;
