import axios from 'axios';
import { baseURL } from '../../contants/api';
import { getLSItem } from '../storage';
import { StorageConstants } from '../storage/constants';
import { useEffect } from 'react';
import { useCommonContext } from '../../../context/CommonContext';

const baseAxios = axios.create();

baseAxios.defaults.headers.common['requestToken'] = '';
baseAxios.defaults.headers.common['signature'] = '';
baseAxios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
baseAxios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
baseAxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

baseAxios.defaults.withCredentials = true;

const get = async (
	origin = 'origin1',
	path = '',
	requestToken = false,
	requestSecret = null
) => {
	let signature = '';
	if (requestSecret) {
		signature = 'generateSignature()';
	}
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: signature,
		},
		// withCredentials : true
	};

	const authCookie = window.localStorage.getItem('AUTHCOOKIE');
	configHeaders.headers['Cookie'] = authCookie;

	configHeaders.headers['Access-Control-Allow-Origin'] = '*';
	configHeaders.headers['Access-Control-Allow-Credentials'] = 'true';

	let url = baseURL[origin] + path;

	return new Promise((resolve, reject) => {
		baseAxios
			.get(url, configHeaders)
			.then((response) => {
				if (
					response.status === 200 &&
					(response.data || response.data == false)
				) {
					if (true || ('' + path).indexOf('b2buser/document/upload') >= 0) {
						const authCookie = response.headers.get('set-cookie');
						//console.log("authCookie=================", authCookie);
						//(""+method).indexOf("user/add") >=0
						if (authCookie) {
							localStorage.setItem('AUTHCOOKIE', authCookie);
						}
					}

					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

const post = async (
	origin = 'origin1',
	path = '',
	req,
	requestToken = false,
	requestSecret = null
) => {
	let signature = '';
	if (requestSecret) {
		signature = 'generateSignature()';
	}
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: signature,
		},
		// withCredentials : true
	};

	configHeaders.headers['Access-Control-Allow-Origin'] = '*';
	configHeaders.headers['Access-Control-Allow-Credentials'] = 'true';

	const authCookie = window.localStorage.getItem('AUTHCOOKIE');
	configHeaders.headers['Cookie'] = authCookie;

	let url = baseURL[origin] + path;

	return new Promise((resolve, reject) => {
		baseAxios
			.post(url, req, configHeaders)
			.then((response) => {
				if (response.status === 200 && response.data) {
					if (true || ('' + path).indexOf('b2buser/document/upload') >= 0) {
						const authCookie = response.headers.get('set-cookie');
						//console.log("authCookie=================", authCookie);
						//(""+method).indexOf("user/add") >=0
						if (authCookie) {
							localStorage.setItem('AUTHCOOKIE', authCookie);
						}
					}
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

const put = async (
	origin = 'origin1',
	path = '',
	req,
	requestToken = false,
	requestSecret = null
) => {
	let signature = '';
	if (requestSecret) {
		signature = 'generateSignature()';
	}
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: signature,
		},
	};

	configHeaders.headers['Access-Control-Allow-Origin'] = '*';
	configHeaders.headers['Access-Control-Allow-Credentials'] = 'true';

	let url = baseURL[origin] + path;

	return new Promise((resolve, reject) => {
		baseAxios
			.put(url, req, configHeaders)
			.then((response) => {
				if (response.status === 200 && response.data) {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

const api_delete = async (
	origin = 'origin1',
	path = '',
	requestToken = false,
	requestSecret = null
) => {
	let signature = '';
	if (requestSecret) {
		signature = 'generateSignature()';
	}
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: signature,
		},
	};

	configHeaders.headers['Access-Control-Allow-Origin'] = '*';
	configHeaders.headers['Access-Control-Allow-Credentials'] = 'true';

	let url = baseURL[origin] + path;

	return new Promise((resolve, reject) => {
		baseAxios
			.delete(url, configHeaders)
			.then((response) => {
				if (
					(response.status === 202 || response.status === 200) &&
					response.data
				) {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

const getFileFromApi = async (path = '', requestToken = false) => {
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: '',
		},
		responseType: 'arraybuffer',
	};

	let url = 's3bucket' + path;

	return new Promise((resolve, reject) => {
		baseAxios
			.get(url, configHeaders)
			.then((response) => {
				if (response.status === 200 && response.data) {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

const postUploadFile = async (
	path = '',
	requestToken = false,
	key,
	id = 0,
	file
) => {
	let token = '';

	if (requestToken) {
		token = `Bearer ${
			(await getLSItem(StorageConstants.compress_token))?.value || ''
		}`;
	}

	const configHeaders = {
		headers: {
			Authorization: token,
			signature: '',
			id: id,
			filename: file.name || '',
			filetype: file.filetype || file.type || 'image/jpeg',
			key: key || '',
		},
	};
	let url = 's3bucket path / authorized url' + path;

	/**
    const formData = new FormData();
    formData.append(
        key,
        file,
        file.name
    );
    */

	return new Promise((resolve, reject) => {
		baseAxios
			.post(url, file, configHeaders)
			.then((response) => {
				if (response.status === 200 && response.data) {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				} else if (
					response.status === 200 ||
					response.status === 201 ||
					response.status === 204
				) {
					reject({ error: 'Status : ' + response.status + ' no content.' });
				} else {
					reject({
						error: response.status
							? 'An status : ' + response.status + ' has occurred.'
							: 'An unexpected error has occurred.',
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					reject({
						error: error.response || 'An unexpected error has occurred.',
					});
				} else {
					reject({ error: 'An unexpected error has occurred.' });
				}
			});
	});
};

export const AxiosInterceptor = ({ children }) => {
	const { openLoader, hideLoader } = useCommonContext();

	useEffect(() => {
		const interceptorPreRequestInstance =
			baseAxios.interceptors.request.use(apiCallPreBoarding);
		const interceptorResponseInstance = baseAxios.interceptors.response.use(
			apiPreResponseHandler,
			apiPreErrorHandler
		);
		return () => {
			if (interceptorPreRequestInstance)
				baseAxios.interceptors.request.eject(interceptorPreRequestInstance);
			if (interceptorResponseInstance)
				baseAxios.interceptors.response.eject(interceptorResponseInstance);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const apiCallPreBoarding = (req) => {
		openLoader();
		return req;
	};

	const apiPreResponseHandler = (response) => {
		setTimeout(() => hideLoader(), 1000);
		return response;
	};

	const apiPreErrorHandler = (error) => {
		setTimeout(() => hideLoader(), 1000);
		return Promise.reject(error);
	};

	return children;
};

export { get, post, put, postUploadFile, api_delete, getFileFromApi };
