import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';

const PageWrapper = styled.div<{ padding?: string; chrun?: boolean }>`
	display:  ${(props) => (props?.chrun ? 'grid' : 'flex')};
	flex: 1;
	max-height: 100%;
	max-width: 100vw;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	max-height: 120vh;
	background-color: ${Colors.primary.black};
	padding: ${(props) => props?.padding || '10px 40px'};
	box-sizing: border-box;
	overflow: ${(props) => (props?.chrun ? 'scroll' : 'hidden')};
`;

export default PageWrapper;
