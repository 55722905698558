import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import styled from 'styled-components';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import ErrorIcon from '../../assets/images/ErrorIcon.svg';

const PaymentFailed = () => {
	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '32px' }} />
			<IconContainer>
				<Icon src={ErrorIcon} />
			</IconContainer>
			<Title
				title='Your payment failed'
				color={Colors.primary.white}
			/>
			<CustomText
				text='Your payment has failed. Before you can use your membership, you must complete the sign up payment. 
				Please try again.'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton>Try Again</PrimaryButton>
			<SwitcherContainer>
				<SwitcherLink>&nbsp;Go Back to Profile</SwitcherLink>
			</SwitcherContainer>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default PaymentFailed;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

const Icon = styled.img`
	width: 191px;
	aspect-ratio: 1/1;
	object-position: center;
`;
