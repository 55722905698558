import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { otpInputProps } from '../../types_and_interfaces/custom-input';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Spacer from '../Spacer/Spacer';

const CustomOTPInput = (props: otpInputProps) => {
	const { title, value, onChange = (_value: any) => {}, error } = props;

	const [showPassword, setShowPassword] = useState<boolean>(false);

	const toggleState = () => {
		setShowPassword((current) => !current);
	};

	return (
		<Container>
			<TitleContainer>
				<TitleText>{title}</TitleText>
			</TitleContainer>
			<InputWrapper>
				<OtpInput
					value={value}
					onChange={onChange}
					numInputs={6}
					shouldAutoFocus
					renderSeparator={<Spacer space={{ x: '8px', y: '8px' }} />}
					renderInput={(props) => (
						<Input
							{...props}
							type={showPassword ? 'text' : 'password'}
							inputMode='numeric'
							error={error}
							autoComplete='none'
							onSelect={(e: any) => {
								e.target.selectionStart = e?.target?.selectionEnd;
							}}
						/>
					)}
				/>
				<ToggleButton onClick={toggleState}>
					{showPassword ? (
						<VisibilityOutlinedIcon htmlColor={Colors.primary.white} />
					) : (
						<VisibilityOffOutlinedIcon htmlColor={Colors.primary.white} />
					)}
				</ToggleButton>
			</InputWrapper>
			{error ? <ErrorText>Please enter a valid code</ErrorText> : null}
		</Container>
	);
};

export default CustomOTPInput;

const Container = styled.div`
	width: 100%;
	margin-bottom: 8px;
`;

const TitleContainer = styled.div`
	margin-bottom: 6px;
`;

const TitleText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const Input = styled.input<{ error?: boolean }>`
	background: ${Colors.primary.white};
	border: 1px solid
		${(props) => (props.error ? Colors.primary.red : Colors.secondary.grey)};
	border-radius: 5px;
	padding: 12px 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	&:focus {
		outline: none;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
`;

const ToggleButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: unset;
	border: unset;
	padding: 8px;
	cursor: pointer;
`;

const ErrorText = styled.div`
	margin-top: 4px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${Colors.system.error};
`;
