export enum FlowTypes {
	SignUp = 'SignUp',
	Login = 'Login',
	Churn = 'Churn',
	UpdatePassword = 'UpdatePassword',
	ForgotPassword = 'ForgotPassword',
	UpdateMobile = 'UpdateMobile',
	NoProductsAvailable = 'NoProductsAvailable',
	MiniProfile = 'MiniProfile',
}

export type FlowTypesType = keyof typeof FlowTypes;

export enum ToastTypes {
	Success = 'Success',
	Error = 'Error',
}

export type ToastTypesType = keyof typeof ToastTypes;

export interface ToastProps {
	type: ToastTypesType;
	message: string;
	autoHide?: boolean;
	autoHideTime?: number;
}

export interface loaderProps {
	autoHide?: boolean;
	autoHideTime?: number;
}

export interface commonContextProps {
	flow: FlowTypesType;
	disableLoader: boolean;
	updateFlow: (newFlow: FlowTypesType) => void;
	addToast: (toast: ToastProps) => void;
	openLoader: (props?: loaderProps) => void;
	hideLoader: () => void;
	toggleLoaderDisableState: (state: boolean) => void;
}
