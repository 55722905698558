import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import Topbar from '../../components/Topbar/Topbar';

const FeedbackSupport = () => {
	return (
		<PageWrapper>
			<Topbar title='Feedback and support' />
			<Spacer space={{ x: '100%', y: '45px' }} />
			<CustomText
				text='Our office hours are Saturday-Thursday, 10AM to 10PM.'
				alignment='left'
			/>
			<CustomText
				text='Our offices are closed on weekends and Public Holidays.'
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '49px' }} />
			<ContactDetails />
			<Spacer space={{ x: '100%', y: '579px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default FeedbackSupport;
