import React from 'react';
import Routes from './routes';
import { Route } from 'react-router-dom';

const Router = () => {
	return Routes.map((route) => {
		if (route?.children && Array.isArray(route.children)) {
			return (
				<>
					{route.children.map((subRoute) => {
						return (
							<Route
								path={`${route.path}${subRoute.path}`}
								element={subRoute.element}
								{...(subRoute?.routerConfig || {})}
							/>
						);
					})}
				</>
			);
		} else {
			return (
				<Route
					path={route.path}
					element={route.element}
					{...(route?.routerConfig || {})}
				/>
			);
		}
	});
};

export default Router;
