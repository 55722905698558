import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { textButtonProps } from '../../types_and_interfaces/text-button';

const TextButton = styled.button<textButtonProps>`
	width: ${(props) => props.width || '-webkit-fill-available'};
	background-color: unset !important;
	border: unset;
	border-radius: 8px;
	color: ${(props) => props.color || Colors.grey.lightGrey} !important;
	padding: ${(props) => props.padding || '14px'};
	margin: ${(props) => props.margin || 'unset'};
	text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	cursor: pointer;
`;

export default TextButton;
