import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import TextButton from '../../components/CustomButtons/TextButton';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes } from '../../types_and_interfaces/common-context';

const CreatedNewPasswordV2 = () => {

	const {  flow } = useCommonContext();

	const {state} = useLocation();

	const navigate = useNavigate();

	const goToLogin = () => {
		navigate('/login');
	};


	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title='New password created'
				color={Colors.primary.white}
			/>
			<CustomText
				text={'You have successfully created new password. Click the button below to go back to login.'}
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<TextButton color={Colors.primary.red} onClick={goToLogin}>{'Go Back to Login'}</TextButton>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default CreatedNewPasswordV2;
