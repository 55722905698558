import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
	AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Colors } from '../../utils/configs/Colors';

const CustomAccordionContainer = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	borderBottom: `1px solid ${Colors.primary.white}88 !important`,
	'& .MuiPaper-root-MuiAccordion-root': {
		backgroundColor: 'unset !important',
	},
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={
			<ArrowForwardIosSharpIcon
				sx={{ color: Colors.primary.red }}
				fontSize='medium'
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	paddingTop: '21px',
	paddingBottom: '21px',
	backgroundColor: Colors.grey.darkGrey1,
	'& .MuiAccordionSummary-expandIconWrapper': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(270deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(0),
	},
}));

const CustomAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	backgroundColor: Colors.grey.darkGrey1,
	padding: '0px 16px 16px 16px',
	// borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomAccordion = {
	Container: CustomAccordionContainer,
	Summary: CustomAccordionSummary,
	Details: CustomAccordionDetails,
};

export default CustomAccordion;
