/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { styled } from 'styled-components';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import BillingDetails from '../../components/BillingDetails/BillingDetails';
import Spacer from '../../components/Spacer/Spacer';
import MembershipDetails from '../../components/MembershipDetails/MembershipDetails';
import ViewVenuesButton from '../../components/ViewVenuesButton/ViewVenuesButton';
import Sidebar from '../../components/Sidebar/Sidebar';
import QRCodeDisplay from '../../components/QRCodeDisplay/QRCodeDisplay';
import { MiniProfileProps } from '../../types_and_interfaces/mini-profile';
import { useNavigate } from 'react-router-dom';
import {
	GetMiniProfileById,
	GetMiniProfileByNewB2BLoginUserId,
} from '../../utils/services/api/signup';
import { useLocation } from 'react-router-dom';
import { useCommonContext } from '../../context/CommonContext';
import {
	FlowTypes,
	ToastTypes,
} from '../../types_and_interfaces/common-context';
import ProfileImage from '../../assets/images/profile.png';
import { getHashValue } from '../../utils/helper/hashfunctions';
import ProfileDetails from '../../components/ProfileDetails/ProfileDetails';
import ProfileInUsePopup from '../SignUp/ProfileInUsePopup';
import ContinuePaymentConfirmationPopup from './ContinuePaymentConfirmationPopup';

const MiniProfile = () => {
	const { search } = useLocation();

	const b2bloginuserId = new URLSearchParams(search).get('userId') || '';
	const userEmail = new URLSearchParams(search).get('email') || '';
	const message = getHashValue('message') || '';
	const { addToast, updateFlow } = useCommonContext();
	const { hideLoader, openLoader } = useCommonContext();

	//#message=updatePaymentMethodSuccess

	useEffect(() => {
		openLoader()
		updateFlow(FlowTypes.MiniProfile);
	}, []);

	useEffect(() => {
		if (!!b2bloginuserId) {
			getMiniProfile();
		} else if (!!userEmail) {
			getMiniProfile();
		}
	}, [b2bloginuserId, userEmail]);

	// useEffect(() => {
	// 	if (userEmail.length > 0) {
	// 		getMiniProfile();
	// 	}
	// }, [userEmail]);

	useEffect(() => {
		console.log(message);
		if (message.length > 0) {
			addToast({
				type: ToastTypes.Success,
				message:
					'Your details have been updated. Please allow up to 5 minutes for your card to be verified, before triggering a payment.',
				autoHide: true,
				autoHideTime: 10000,
			});
			window.location.hash = '';
		}
	}, [message]);

	const [showQRCode, setShowQRCode] = useState<boolean>(false);

	const [formData, setFormData] = useState<MiniProfileProps>({
		userId: '',
		userProfileImage: '',
		userProfileName: '',
		userProfileMembershipId: '',
		billingDetailStartDate: '',
		billingDetailNextBillingDate: '',
		billingDetailOutstandingAmount: '',
		membershipDetailName: '',
		membershipDetailMonthlyBilling: '',
		membershipDetailMonthlyBillingWithVAT: '',
		currencyCode: '',
		b2bloginuserId: b2bloginuserId,
		amountDueInvoicesIds: [],
		userState: '',
		userEmail: userEmail || '',
		mobileNumber: '',
		automaticCancellationDate: '',
	});

	useEffect(() => {
		if (
			formData.billingDetailOutstandingAmount.length > 0 &&
			formData.billingDetailOutstandingAmount > '0.00'
		) {
			addToast({
				message:
					'You have an outstanding amount of AED ' +
					formData?.billingDetailOutstandingAmount +
					' on your profile. Please clear the amount before ' +
					formData.automaticCancellationDate +
					' to avoid automatic membership cancellation.',
				type: 'Error',
				autoHide: false,
				autoHideTime: 60 * 1000,
			});
		}
	}, [formData.billingDetailOutstandingAmount]);

	const getMiniProfile = () => {
		if (formData?.b2bloginuserId) {
			// new b2b user is just created
			GetMiniProfileByNewB2BLoginUserId(formData.b2bloginuserId).then(
				(data) => {
					if (data) {
						setFormData(data);
						hideLoader();
					}
				}
			);
		} else {
			GetMiniProfileById(userEmail ? userEmail : 'user').then((data) => {
				if (data && data.userId) {
					if (
						data?.userState === 'Churn' ||
						(!data.userProfileMembershipId &&
							data.billingDetailOutstandingAmount.length > 0 &&
							data.billingDetailOutstandingAmount > '0.00')
					) {
						//re-direct to churn flow
						updateFlow(FlowTypes.Churn);
						goToChurnFlow(data?.userId);
					} else if (!data.userProfileMembershipId) {
						updateFlow(FlowTypes.NoProductsAvailable);
						goToChurnFlow(userEmail);
					} else {
						setFormData(data);
						setTimeout(() => {
							hideLoader();
						}, 1000);
						
					}
				}
			});
		}
	};

	const openQR = () => {
		setShowQRCode(true);
	};

	const closeQR = () => {
		setShowQRCode(false);
	};

	const navigate = useNavigate();

	const goToChurnFlow = (userId: string) => {
		navigate('/churnSelectIdEmpty?email=' + userEmail, {
			state: { userId: userId },
		});
	};

	return (
		 formData?.userId ? <>
			<PageWrapper padding='10px 0px'>
				<Sidebar email={formData?.userEmail} />
				<ContentContainer>
					<ProfilePicture
						openQRCode={openQR}
						profileImageUrl={formData?.userProfileImage || ProfileImage}
						MemberName={formData?.userProfileName || ''}
						MembershipId={formData?.userProfileMembershipId || ''}
						// disableEdit={formData?.userProfileImage}
						disableEdit={false}
						UserId={formData?.userId || ''}
						userEmail={formData?.userEmail || ''}
					/>
					<Spacer space={{ x: '100%', y: '16px', yMin: '16px' }} />
					<ProfileDetails
						EmailProp={formData?.userEmail || ''}
						MobileNumberProp={formData?.mobileNumber || ''}
					/>
					<BillingDetails
						CurrencyCode={formData?.currencyCode || ''}
						StartDate={formData?.billingDetailStartDate || ''}
						NextBillingDate={formData?.billingDetailNextBillingDate || ''}
						OutstandingAmount={formData?.billingDetailOutstandingAmount || ''}
						UserId={formData?.userId}
						OutstandingInvoiceIds={formData?.amountDueInvoicesIds}
						updateMiniProfile={getMiniProfile}
						email={formData?.userEmail || ''}
					/>
					<MembershipDetails
						CurrencyCode={formData?.currencyCode || ''}
						MembershipName={formData?.membershipDetailName || ''}
						MonthlyBilling={
							formData?.membershipDetailMonthlyBillingWithVAT || ''
						}
						UserId={formData.userId}
						SubcriptionId={formData?.userProfileMembershipId}
						NextBillingDate={formData?.billingDetailNextBillingDate || ''}
					/>
					<ViewVenuesButton />
				</ContentContainer>
				<QRCodeDisplay
					show={showQRCode}
					close={closeQR}
					name={formData?.userProfileName}
					membershipId={formData?.userProfileMembershipId}
					imgeUrl={formData?.userProfileImage}
					expiryDate={
						formData?.billingDetailNextBillingDate ||
						formData?.automaticCancellationDate
					}
					userId={formData?.userId}
				/>
				<GoWellFooter />
			</PageWrapper>
		</> : <PageWrapper></PageWrapper>
	);
};

export default MiniProfile;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;

	& .MuiAccordion-root {
		background-color: unset !important;
	}
`;
