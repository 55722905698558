import React from 'react';
import { styled } from 'styled-components';
import { customInputProps } from '../../types_and_interfaces/custom-input';
import { Colors } from '../../utils/configs/Colors';

const CustomInput = React.forwardRef(
	({ title, inputProps, error, errorComp, ...rest }: customInputProps) => {
		return (
			<Container>
				<TitleContainer>
					<TitleText>{title}</TitleText>
				</TitleContainer>
				<Input
					{...inputProps}
					error={error}
					{...rest}
				/>
				{error ? (errorComp ? errorComp : null) : null}
			</Container>
		);
	}
);

export default CustomInput;

const Container = styled.div`
	width: 100%;
	margin-bottom: 8px;
`;

const TitleContainer = styled.div`
	margin-bottom: 6px;
`;

const TitleText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const Input = styled.input<{ error?: boolean }>`
	background: ${Colors.primary.white};
	border: 1px solid
		${(props) => (props.error ? Colors.primary.red : Colors.secondary.grey)};
	border-radius: 5px;
	padding: 12px 16px;
	width: 100%;
	box-sizing: border-box;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	${(props) => (props.error ? 'margin-bottom: 8px;' : '')}

	&:focus {
		outline: none;
	}
`;
