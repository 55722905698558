import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { PopupProps } from '../../types_and_interfaces/popup-component';
import CloseIcon from '@mui/icons-material/Close';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrimaryButton from '../CustomButtons/PrimaryButton';

const TermsAndConditionPopup = (props: PopupProps) => {
	const {
		show = false,
		hide = () => {},
		cancelAction = () => {},
		confirmAction = () => {},
	} = props;
	return (
		<Wrapper show={show}>
			<CloseButtonContainer>
				<Title>Terms and Conditions</Title>
				<CloseButton onClick={hide}>
					<CustomCloseIcon />
				</CloseButton>
			</CloseButtonContainer>
			<TermsAndConditions />
			<Row>
				<PrimaryButton onClick={confirmAction}>Agree</PrimaryButton>
				<PrimaryButton
					background={Colors.secondary.grey}
					color={Colors.primary.black}
					onClick={cancelAction}>
					Cancel
				</PrimaryButton>
			</Row>
		</Wrapper>
	);
};

export default TermsAndConditionPopup;

const Wrapper = styled.div<{ show?: boolean }>`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: ${(props) => (props?.show ? '0vh' : '100vh')};
	bottom: 0;
	left: 0;
	width: 100%;
	height: ${(props) => (props?.show ? '100vh' : '0vh')};
	box-sizing: border-box;
	background-color: ${Colors.grey.darkGrey2};
	z-index: 90;
	border-radius: 8px;
	padding: ${(props) => (props?.show ? '12px 40px' : 'unset')};
	overflow: hidden;
	transition: 0.35s ease;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: 10px -15px 15px 0px;
`;

const CloseButton = styled.button`
	background: unset;
	border: 0px solid transparent;
	color: ${Colors.primary.white};
	padding: 0px;
`;

const CustomCloseIcon = styled(CloseIcon)`
	font-size: 30px !important;
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 700;
	color: ${Colors.primary.white};
	margin-left: auto;
	margin-right: auto;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 40px;
	margin-top: 15px;
	margin-bottom: 3px;
`;
