import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CustomAccordion from '../CustomAccordian/CustomAccordian';
import { Colors } from '../../utils/configs/Colors';
import CreditCardIcon from '../../assets/images/CreditCardIcon.svg';
import EnvilopIcon from '../../assets/images/Envelop.svg';
import Spacer from '../Spacer/Spacer';
import PrimaryButton from '../CustomButtons/PrimaryButton';
import { MiniProfileProps } from '../../types_and_interfaces/mini-profile';
import {
	CollectPayment,
	GetUpdatePaymentMethod,
} from '../../utils/services/api/signup';
import { error } from 'console';
import { useCommonContext } from '../../context/CommonContext';
import { ToastTypes } from '../../types_and_interfaces/common-context';
import moment from 'moment';
import { Duration, Moment } from 'moment';


const ProfileDetails = (props: any) => {
	const {
		EmailProp,
		MobileNumberProp,
	} = props;
	const { addToast } = useCommonContext();
	const [duration, setDuration] = useState('');

	return (
		<Container>
			<CustomAccordion.Container>
				<CustomAccordion.Summary>
					<TitleContainer>
						<Icon src={EnvilopIcon} />
						<Title>Profile Details</Title>
					</TitleContainer>
				</CustomAccordion.Summary>
				<CustomAccordion.Details>
					<Row>
						<Left>
							<Text>Email</Text>
						</Left>
						<Right>
							<Text>{EmailProp}</Text>
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '14px' }} />
					<Row>
						<Left>
							<Text>Mobile Number</Text>
						</Left>
						<Right>
							<Text>{MobileNumberProp.slice(0, 4) + ' ' + MobileNumberProp.slice(4, 6) + ' ' + MobileNumberProp.slice(6, 9) + ' ' + MobileNumberProp.slice(9)}</Text>
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '24px' }} />
				</CustomAccordion.Details>
			</CustomAccordion.Container>
		</Container>
	);
};

export default ProfileDetails;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
	background-color: ${Colors.grey.darkGrey1};
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const Icon = styled.img`
	margin-right: 25px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const Text = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.white};
`;

const SubText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${Colors.grey.darkGrey5};
`;

const ErrorText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.red};
`;

const ButtonText = styled.span`
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.secondary.offGrey};
`;
