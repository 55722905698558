import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Colors } from '../../utils/configs/Colors';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import styled from 'styled-components';
import Topbar from '../../components/Topbar/Topbar';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';

const TermsConditions = () => {
	return (
		<PageWrapper>
			<Topbar title='Terms and Conditions' />
			<Spacer space={{ x: '100%', y: '40px', yMin: '40px', yMax: '40px' }} />
			<TermsAndConditions />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default TermsConditions;
