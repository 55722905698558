import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomInput from '../../components/CustomInput/CustomInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import styled from 'styled-components';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import { useLocation, useNavigate } from 'react-router-dom';
import Spacer from '../../components/Spacer/Spacer';
import { RecoveryPasswordProps, RecoveryPasswordPropsPattern, RecoveryPasswordPropsValid } from '../../types_and_interfaces/recovery-password';
import { SendRecoveryEmailOtp } from '../../utils/services/api/recovery';
import ErrorText from '../../components/CustomText/ErrorText';
import { ContextConstants } from '../../utils/services/storage/constants';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';

const EnterYourEmailChangeMobile = () => {
	const location = useLocation()

	const { state } = useLocation();

	const { addToast, flow, updateFlow } = useCommonContext();
	
	useEffect(() => {
		if(flow === null || flow === undefined){
			updateFlow(FlowTypes.ForgotPassword);
		}
	}, []);
	

	const [formData, setFormData] = useState<RecoveryPasswordProps>({
		UserId: '',
		Email: '',
		emailVerificationToken: '',
		otp: '',
		responseStatus: '',
		newPassword: ''
	});

	const [formDataValidState, setFormDataValidState] =
		useState<RecoveryPasswordPropsValid>({
			Email: false,
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'Email':
				reg = RecoveryPasswordPropsPattern.Email;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: RecoveryPasswordProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		if(state?.email !== formData?.Email){
			addToast({
				type: ToastTypes.Error,
				message: 'The email address you entered is incorrect. Please check your entry and try again.',
			});
			return;
		}
		setEnableSubmit(false);
		SendRecoveryEmailOtp(formData).then((data) => {
			console.log(data);
			setEnableSubmit(true);
			if(data.isSuccess){
				goToEmailConfirm();
			}
			else{
				addToast({
					type: ToastTypes.Error,
					message: 'The email address you entered is incorrect. Please check your entry and try again.',
				});
			}
		}).catch((err)=>{
			setEnableSubmit(true);
			console.log(err);
			addToast({
				type: ToastTypes.Error,
				message: 'Error occured while sending email OTP',
			});
			goToError();
		});
	};

	const navigate = useNavigate(); 

	const goToEmailConfirm = () => {
		navigate('/emailConfirmationChangeMobile', {state: {option: ContextConstants.recoveryPwd, email: formData.Email}});
	};

	const goToPreviouse=()=>{
		navigate(-1);
	}

	const goToError=()=>{
		navigate('/errorChangeMobile');
	}

	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title={'Enter your email'}
				color={Colors.primary.white}
			/>
			<CustomInput
				title='Email' value={formData.Email}
				onChange={(event) => handleChange(event.target.value, 'Email')} error={!formDataValidState.Email && formData.Email !== ''}
				errorComp={
					<ErrorText>Please enter a valid email</ErrorText>
				}
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>{flow === FlowTypes.UpdateMobile || flow === FlowTypes.UpdatePassword? 'Continue':'Request Recovery'}</PrimaryButton>
			<SwitcherContainer>
				<SwitcherLink onClick={goToPreviouse}>&nbsp;Go Back</SwitcherLink>
			</SwitcherContainer>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default EnterYourEmailChangeMobile;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
