import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomPasswordInput from '../../components/CustomInput/CustomPasswordInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
// import CustomCheckBox from '../../components/CustomInput/CustomCheckBox';
import styled from 'styled-components';
import TextButton from '../../components/CustomButtons/TextButton';
import Spacer from '../../components/Spacer/Spacer';
import { LoginProps, LoginPropsPattern, LoginPropsValid } from '../../types_and_interfaces/login';
import { LoginApi, ValidateToken } from '../../utils/services/api/signup';
import { CommonEncryption } from '../../utils/helper/commonEncryption';
import ErrorText from '../../components/CustomText/ErrorText';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';
import CustomCheckBox from '../../components/CustomInput/CustomCheckBox';
import ProfileInUsePopup from '../SignUp/ProfileInUsePopup';

const Login = () => {
	const { addToast, updateFlow } = useCommonContext();

	const [isAuthValidate, setIsAuthValidate] = useState<boolean>(false);

	const [formData, setFormData] = useState<LoginProps>({
		Username: '',
		Password: '',
		RememberMe: false,
		ReturnUrl: '',
	});

	const [formDataValidState, setFormDataValidState] =
		useState<LoginPropsValid>({
			Username: false,
			Password: false
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);
	const [popupState, setPopupState] = useState<boolean>(false);

	const search = useLocation().search;
	const GoWellUserId = new URLSearchParams(search).get('userid') || '';
	const AuthToken = new URLSearchParams(search).get('auth-token') || '-|-';

	useEffect(()=>{
		callNativeApp();
		var gwuid =  window.localStorage.getItem("GoWellUserId") || '';
		window.localStorage.setItem("GoWellUserId", GoWellUserId || gwuid);

		ValidateToken(AuthToken).then((data) => {
			console.log(data);
						if(data === true){
				goToMiniProfile();
			}
			else{
				setIsAuthValidate(true);
			}
		}).catch((err)=>{
			setIsAuthValidate(true);
			console.log(err);
		});
	}, [])

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'Username':
				reg = LoginPropsPattern.Username;
				break;
			case 'Password':
				reg = LoginPropsPattern.Password;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: LoginProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		setEnableSubmit(false);
		let submitFormData = {...formData};
		submitFormData.Password =  CommonEncryption(formData.Password);
		submitFormData.GoWellUserId = window.localStorage.getItem("GoWellUserId") || '';
		LoginApi(submitFormData).then((data) => {
			console.log(data);
			setEnableSubmit(true);
			if(data === 200){
				goToMiniProfile();
			}
			else if (data?.returnStatus === 403 && data?.result?.errorCode === 4){
				//email mis-match with goWellUserId
				openPopup();
			}
			else if (data?.returnStatus === 403 && data?.result?.errorCode === 5){
				// select profile exsists
				navigate('/existingMembership');
			}
			//ExistingMembership
			else{
				addToast({
					type: ToastTypes.Error,
					message: "The email or password you entered is incorrect. Please check your entry and try again.",
				});
			}
		}).catch((err)=>{
			console.log(err);
			setEnableSubmit(true);
			// addToast({
			// 	type: ToastTypes.Error,
			// 	message: "Invalid user name or password",
			// });
			navigate('/errorTechIssue');
		});
	};

	const openPopup = () => {
		setPopupState(true);
	};

	const navigate = useNavigate();

	const goToMiniProfile = () => {
				navigate('/miniProfile?email='+ (formData.Username || 'user'));
	};

	const goToRecoverPassword = () => {
		updateFlow(FlowTypes.ForgotPassword);
		navigate('/recoverPassword');
	};

	const goToSignUP = () => {
		navigate('/');
	};

	const goToLogin = () => {
		setPopupState(false);
	};

	const goToSupport = () => {
		navigate('/accountSupport');
	}

	const callNativeApp = () => {
		try {
            (window as any).webkit.messageHandlers.callbackHandler.postMessage("signup");
        } catch(err) {
			console.log(err)
            console.log('The native context does not exist yet');
			alert((err as any).message);
        }
	}

	return (
		isAuthValidate === false ? <PageWrapper></PageWrapper> : <>
			<PageWrapper>
				<LogoHeader />
				<Title
					title='Join our exclusive community'
					color={Colors.primary.white}
				/>
				<CustomInput title='Email' value={formData.Username}
						onChange={(event) => handleChange(event.target.value, 'Username')} error={!formDataValidState.Username && formData.Username !== ''}
						errorComp={
							<ErrorText>Please enter a valid email</ErrorText>
						} />
				<CustomPasswordInput
					title='Password'
					enablePasswordToggle
					disableErrorBox={true}
					value={formData.Password}
						onChange={(event) => handleChange(event.target.value, 'Password')}
				/>
				<Row>
					{/* <CustomCheckBox title='Remember me' /> */}
					<TextButton
						padding='unset'
						color={Colors.primary.red}
						onClick={goToRecoverPassword}
						textAlign='right'>
						Forgot password?
					</TextButton>
				</Row>
				<Spacer space={{ x: '100%', y: '290px' }} />
				<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Login</PrimaryButton>
				<SwitcherContainer>
					<SwitcherText>Want to create an account?&nbsp;</SwitcherText>
					<SwitcherLink onClick={goToSignUP}>&nbsp;Sign Up</SwitcherLink>
				</SwitcherContainer>
				<ProfileInUsePopup
					show={popupState}
					confirmAction={goToLogin}
					cancelAction={goToSupport}
				/>
				<Spacer space={{ x: '100%', y: '49px' }} />
				<GoWellFooter />
			</PageWrapper>
		</>
	);
};

export default Login;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;

const Row = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;
