import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomInput from '../../components/CustomInput/CustomInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import styled from 'styled-components';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import { useLocation, useNavigate } from 'react-router-dom';
import Spacer from '../../components/Spacer/Spacer';
import { RecoveryPasswordProps, RecoveryPasswordPropsPattern, RecoveryPasswordPropsValid } from '../../types_and_interfaces/recovery-password';
import { SendRecoveryEmailOtp } from '../../utils/services/api/recovery';
import { ContextConstants } from '../../utils/services/storage/constants';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';
import { getUserByAdmin, getVitalityUser } from '../../utils/services/api/signup';
import { UserAdmin, UserAdminProps } from '../../types_and_interfaces/user-admin';
import CustomAccordion from '../../components/CustomAccordian/CustomAccordian';
import { VitalityRequestProps, VitalityResponseProps } from '../../types_and_interfaces/vitality';

const UserDetail = () => {

    const { addToast} = useCommonContext();
	
	useEffect(() => {
	}, []);

    const [inputFormData, setInputFormData] = useState<UserAdmin>({
		UserId: '',
	});

    const [vitalityRequestData, setVitalityRequestData] = useState<VitalityRequestProps>({
		evaluateOn: new Date().toISOString().split('T')[0],
        partyReferenceIn: {
            resolverReferenceTypeKey: '',
            resolverReferenceTypeValue: ''
        }
	});

    useEffect(() => {
        console.log('vitalityRequestData', vitalityRequestData);
        getVitalityStatus();
      }, [vitalityRequestData]);

    const [vitalityResponseData, setVitalityResponseData] = useState<VitalityResponseProps>({
        effectiveFrom: '',
        effectiveTo: '',
        statusTypeCode: '',
        statusTypeKey: '',
        statusTypeName: '',
    })

    const [formData, setFormData] = useState<UserAdminProps>({
        userId: '', 
        goWellUserId: '', 
        selectUserId: '',
        goWellSignupRequestId: '',
        firstName: '', 
        lastName: '', 
        mobileNumber: '',
        email: '', 
        password: '', 
        mobileNumberOtp: '',
        mobileNumberOtpSentTime: '', 
        mobileNumberConfirmed: false, 
        mobileNumberConfirmedTime: '', 
        emailOtp: '', 
        emailOtpSentTime: '', 
        emailConfirmed: false, 
        emailConfirmedTime: '', 
        userConfirmed: false, 
        id: '', 
        recordStatus: '', 
        createdBy: '',
        createdDate: '', 
        modifiedBy: '',
        modifiedDate: '', 
    })
	
    const handleChange = (value: string, target: string) => {
		setInputFormData((current: UserAdmin) => ({
			...current,
			[target]: value || '',
		}));
		// let reg = getRegex(target);
		// setFormDataValidState((current) => ({
		// 	...current,
		// 	[target]: reg.test(value),
		// }));
	};

    const setFormDataToEmpty = () => {
        setFormData({
            userId: '', 
            goWellUserId: '', 
            selectUserId: '',
            goWellSignupRequestId: '',
            firstName: '', 
            lastName: '', 
            mobileNumber: '',
            email: '', 
            password: '', 
            mobileNumberOtp: '',
            mobileNumberOtpSentTime: '', 
            mobileNumberConfirmed: false, 
            mobileNumberConfirmedTime: '', 
            emailOtp: '', 
            emailOtpSentTime: '', 
            emailConfirmed: false, 
            emailConfirmedTime: '', 
            userConfirmed: false, 
            id: '', 
            recordStatus: '', 
            createdBy: '',
            createdDate: '', 
            modifiedBy: '',
            modifiedDate: '', 
        });
    }

    const setVitalityResponseToEmpty = () => {
        setVitalityResponseData({
            effectiveFrom: '',
            effectiveTo: '',
            statusTypeCode: '',
            statusTypeKey: '',
            statusTypeName: ''
        });
    }

	const submitForm = (e: { preventDefault: () => void }) => {
        setFormDataToEmpty();
        setVitalityResponseToEmpty();
        
		getUserByAdmin(inputFormData.UserId).then((data) => {
			console.log(data);
            if(data){
                setVitalityRequestData({
                    evaluateOn: new Date().toISOString().split('T')[0],
                    partyReferenceIn: {
                        resolverReferenceTypeKey: '13',
                        resolverReferenceTypeValue: data?.goWellUserId
                    }
                });

                setFormData(data);
                // getVitalityStatus(data.goWellUserId);
            }
            console.log(formData);
		}).catch((err)=>{
			console.log(err);
			addToast({
				type: ToastTypes.Error,
				message: err,
			});
		});
	};

    const getVitalityStatus = () => {
       
        if(vitalityRequestData?.partyReferenceIn?.resolverReferenceTypeValue == null || vitalityRequestData?.partyReferenceIn?.resolverReferenceTypeValue === ''){
            return;
        }
        
        getVitalityUser(vitalityRequestData).then((data) => {
			console.log(data);
            if(data?.state != null){
                setVitalityResponseData(data.state);
            }
            console.log(vitalityResponseData);
		}).catch((err)=>{
			console.log(err);
			addToast({
				type: ToastTypes.Error,
				message: err,
			});
		});
    }

	return (
        <>
		<PageWrapper>
			<LogoHeader />
			<Title
				title={'Enter your email'}
				color={Colors.primary.white}
			/>
			<CustomInput
				title='GoWellUserId or Email' value={inputFormData.UserId}
				onChange={(event) => handleChange(event.target.value, 'UserId')}
			/>
			<Spacer space={{ x: '100%', y: '14px' }} />
			<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
            <Spacer space={{ x: '100%', y: '15px' }} />
        </PageWrapper>
        <PageWrapper2>
            <CustomAccordion.Container>
                <CustomAccordion.Summary>
                    <TitleContainer>
                        <Icon src='' />
                        <Title>B2B User Details</Title>
                    </TitleContainer>
                </CustomAccordion.Summary>
                <CustomAccordion.Details>
                <Spacer space={{ x: '100%', y: '7px' }} />
                    <Row>
                        <Left>
                            <Text>Id</Text>
                        </Left>
                        <Right>
                            <Text>{formData.id}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>User Id</Text>
                        </Left>
                        <Right>
                            <Text>{formData.userId}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>First Name</Text>
                        </Left>
                        <Right>
                            <Text>{formData.firstName}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Last Name</Text>
                        </Left>
                        <Right>
                            <Text>{formData.lastName}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Email</Text>
                        </Left>
                        <Right>
                            <Text>{formData.email}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Mobile Number</Text>
                        </Left>
                        <Right>
                            <Text>{formData.mobileNumber}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>GoWell User Id</Text>
                        </Left>
                        <Right>
                            <Text>{formData.goWellUserId}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Created Date</Text>
                        </Left>
                        <Right>
                            <Text>{formData.createdDate}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '24px' }} />
                </CustomAccordion.Details>
            </CustomAccordion.Container>
            <Spacer space={{ x: '100%', y: '15px' }} />
            <CustomAccordion.Container>
                <CustomAccordion.Summary>
                    <TitleContainer>
                        <Icon src='' />
                        <Title>Vitality Details</Title>
                    </TitleContainer>
                </CustomAccordion.Summary>
                <CustomAccordion.Details>
                    <Spacer space={{ x: '100%', y: '7px' }} />
                    <Row>
                        <Left>
                            <Text>Effective From</Text>
                        </Left>
                        <Right>
                            <Text>{vitalityResponseData.effectiveFrom}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Effective To</Text>
                        </Left>
                        <Right>
                            <Text>{vitalityResponseData.effectiveTo}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Status Type Code</Text>
                        </Left>
                        <Right>
                            <Text>{vitalityResponseData.statusTypeCode}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Status Type Key</Text>
                        </Left>
                        <Right>
                            <Text>{vitalityResponseData.statusTypeKey}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '14px' }} />
                    <Row>
                        <Left>
                            <Text>Status Type Name</Text>
                        </Left>
                        <Right>
                            <Text>{vitalityResponseData.statusTypeName}</Text>
                        </Right>
                    </Row>
                    <Spacer space={{ x: '100%', y: '24px' }} />
                </CustomAccordion.Details>
            </CustomAccordion.Container>
        </PageWrapper2>
        <GoWellFooter />
        </>
	);
};

export default UserDetail;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const PageWrapper2 = styled.div`
	display:  'flex';
	flex: 1;
	max-height: 100%;
	max-width: 100vw;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	max-height: 120vh;
	background-color: ${Colors.primary.black};
	padding: '10px 0px';
	box-sizing: border-box;
	overflow: 'scroll';
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;

	& .MuiAccordion-root {
		background-color: unset !important;
	}
`;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
	background-color: ${Colors.grey.darkGrey1};
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const Icon = styled.img`
	margin-right: 25px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const Text = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.white};
`;

const SubText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${Colors.grey.darkGrey5};
`;

const ErrorText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.red};
`;

const ButtonText = styled.span`
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.secondary.offGrey};
`;
