/* eslint-disable no-useless-escape */
export interface RecoveryPasswordProps {
  UserId?: string;
  Email?: string;
  emailVerificationToken?: string;
  otp?: string;
  responseStatus?: string;
  newPassword?: string;
}

export interface RecoveryPasswordPropsValid {
  Email: boolean;
}

export const RecoveryPasswordPropsPattern = {
  // Email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/,
  Email: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
};
