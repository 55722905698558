import React from 'react';
import { styled } from 'styled-components';
import { customInputProps } from '../../types_and_interfaces/custom-input';
import { Colors } from '../../utils/configs/Colors';

const CustomCheckBox = (props: customInputProps) => {
	const { title, value, onChange, inputProps } = props;

	return (
		<Container>
			<InputWrapper>
				<Input
					id={'chekbox' + title}
					name={'chekbox' + title}
					type='checkbox'
					value={value}
					checked={value}
					onChange={onChange}
					{...inputProps}
				/>
				<TitleContainer>
					<TitleText htmlFor={'chekbox' + title}>{title}</TitleText>
				</TitleContainer>
			</InputWrapper>
		</Container>
	);
};

export default CustomCheckBox;

const Container = styled.div`
	width: 100%;
	margin-bottom: 8px;
	cursor: pointer;
`;

const TitleContainer = styled.div`
	margin-left: 6px;
	cursor: pointer;
`;

const TitleText = styled.label`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${Colors.grey.lightGrey1};
	cursor: pointer;
`;

const Input = styled.input`
	width: 16px;
	height: 16px;
	background-color: ${Colors.primary.red};
	accent-color: ${Colors.primary.red};
	border: unset;
	cursor: pointer;
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
`;
