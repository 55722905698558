import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import ViewVenuesIcon from '../../assets/images/ViewVenuesIcon.svg';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useNavigate } from 'react-router-dom';

const ViewVenuesButton = () => {
	const navigate = useNavigate();

	const goToVenues = () => {
		navigate('/venues');
	};
	return (
		<Container>
			<Button onClick={goToVenues}>
				<TitleContainer>
					<Icon src={ViewVenuesIcon} />
					<Title>View Available Venues</Title>
				</TitleContainer>
				<CaretIcon fontSize='medium' />
			</Button>
		</Container>
	);
};

export default ViewVenuesButton;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
	background-color: ${Colors.grey.darkGrey1};
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 12px 0px;
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const Icon = styled.img`
	margin-right: 25px;
`;

const Button = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 21px 16px;
	width: 100%;
	box-sizing: border-box;
	background-color: ${Colors.grey.darkGrey1};
	border: unset;
`;

const CaretIcon = styled(ArrowForwardIosSharpIcon)`
	color: ${Colors.primary.red};
	font-size: 24px !important;
`;
