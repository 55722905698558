import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomText from '../../components/CustomText/CustomText';
import { Colors } from '../../utils/configs/Colors';
import Spacer from '../../components/Spacer/Spacer';
import TextButton from '../../components/CustomButtons/TextButton';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { useLocation, useNavigate } from 'react-router-dom';

const ChangedMobileNumber = () => {

	const {state} = useLocation();

	const navigate = useNavigate();

	const goToChangeConfirmation = () => {
		navigate('/miniProfile?email=' + state?.email);
	};

	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title='Mobile number changed'
				color={Colors.primary.white}
			/>
			<CustomText
				text={'You have successfully changed your mobile number to '+state?.mobileNumber+'.'}
				alignment='left'
			/>
			<CustomText
				text={'Click the button below to go back to your profile'}
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<TextButton color={Colors.primary.red} onClick={goToChangeConfirmation}>Go Back to Profile</TextButton>
			<Spacer space={{ x: '100%', y: '60px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default ChangedMobileNumber;
