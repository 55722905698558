import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { Colors } from '../../utils/configs/Colors';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import styled from 'styled-components';
import Topbar from '../../components/Topbar/Topbar';

const PrivacyPolicy = () => {
	return (
		<PageWrapper>
			<Topbar title='Privacy Policy' />
			<Spacer space={{ x: '100%', y: '40px', yMin: '40px', yMax: '40px' }} />
			<ContentContainer>
				<div>
					<div>
						<div>
							<p>
								We know that in this digital age, your privacy is important.
								This Privacy Policy reflects our commitment to protect personal
								data and the choices we offer you regarding how your data is
								used. We welcome you to read more about how we keep your
								information safe, as well as how you can exercise your rights.
								In addition, our Privacy policy covers our treatment of data
								that may be personal to you.
							</p>
							<p>
								&zwj;We will review, update and amend these policies from time
								to time consistent with our business needs and technology. We
								encourage you to check back periodically for new updates or
								changes. Your continued use of the service makes up your
								acceptance of any change to this Privacy Policy. We are the data
								controller of your information. We handle and process all data
								on behalf of our customers.
							</p>

							<h3>
								What private data do we collect from the people who visit our
								website/App?
							</h3>

							<p>
								When you create an account and use the Services, including
								through a third-party platform, we collect any data you provide
								directly, including:
							</p>

							<ul>
								<li>
									<p>Name and address</p>
								</li>
								<li>
									<p>Phone number</p>
								</li>
								<li>
									<p>Email address</p>
								</li>
								<li>
									<p>Profile picture</p>
								</li>
								<li>
									<p>Payment information (credit and debit card details)</p>
								</li>
								<li>
									<p>Home country</p>
								</li>
								<li>
									<p>Gender</p>
								</li>
								<li>
									<p>Job title</p>
								</li>
								<li>
									<p>Industry sector</p>
								</li>
							</ul>

							<ul>
								<li>
									<p>
										Account Data: To use certain features (like Paid or unpaid
										Services), you need to create a user account. When you
										create or update your account, we collect and store the data
										you provide, like your email address, password, gender, and
										date of birth, and assign you a unique identifying number
										(&quot;Account Data&quot;).
									</p>
								</li>
							</ul>

							<ul>
								<li>
									<p>
										Personal Data: Personal Data is information that can be used
										to identify you specifically, including your name, zip code,
										time zone, email address, telephone number, or demographic
										information like your age, gender, or hometown. You consent
										to give us this information by providing it to us
										voluntarily on our website or any mobile application. You
										provide some of this information when you register with or
										make purchases from our website/App. You may also provide
										this information by participating in various activities
										associated with our site, including responding to blogs,
										contacting us with questions, or participating in group
										training. Your decision to disclose this data is entirely
										voluntary. You are under no obligation to provide this
										information, but your refusal may prevent you from accessing
										certain benefits from our website/App or from making
										purchases.
									</p>
								</li>
							</ul>

							<ul>
								<li>
									<p>
										Financial Data: Financial data is related to your payment
										methods, such as credit card or bank transfer details. We
										collect financial data to allow you to purchase, order,
										return or exchange products or services from our website and
										any related mobile apps. We store limited financial data.
										Most financial data is transferred to our payment processor,
										Chargebee, and you should review these processors&apos;
										Privacy Policy to determine how they use, disclose and
										protect your financial data.&nbsp;
									</p>
								</li>
							</ul>

							<h3>When do we acquire information?</h3>

							<p>
								We get data from you when you get enlisted on our site, respond
								to an audit, give us reactions on our items or enter information
								on our site. Below are the examples:
							</p>

							<ul>
								<li>
									<p>
										To register on our website/App to get updated about services
										or to buy services.
									</p>
								</li>
								<li>
									<p>
										To create your account on the website/App (e.g., your name
										and email address)
									</p>
								</li>
								<li>
									<p>
										To process your orders via our online services (e.g., your
										name, address, date of birth, and payment details)&nbsp;
									</p>
								</li>
								<li>
									<p>
										To deal with our relationship with you, including notifying
										you about changes to our terms or security arrangement.
										Requesting that you leave an auditor to take an
										overview.&nbsp;
									</p>
								</li>
								<li>
									<p>
										To manage and ensure our business and this site (counting
										investigating, information examination, testing, framework
										upkeep, backing, announcing, and facilitating information).
									</p>
								</li>
								<li>
									<p>
										To send you our email pamphlet and other robotized email
										correspondences.&nbsp;
									</p>
								</li>
								<li>
									<p>
										To make proposals and suggestions to you about merchandise
										or administrations that might hold any importance with you.
									</p>
								</li>
							</ul>

							<h3>
								Automatically collected information about your use of our
								Services or tools,
							</h3>

							<p>
								This information is registered automatically with the visit by
								own configuration or manual of each tool on the website/App
							</p>

							<ul>
								<li>
									<p>
										When you visit, connect with, or utilize our service, we may
										gather, record or create specific specialized data about
										you. We do so either autonomously or with the assistance of
										third gathering Service Providers, including using
										&quot;cookies&quot; and other following innovations.&nbsp;
									</p>
								</li>
							</ul>

							<ul>
								<li>
									<p>
										Such data comprises of availability, specialized and
										collected utilization data, for example, IP locations and
										general areas, gadget data (like sort, working framework,
										cell phone id, program form, region and language settings,
										Browser Information, Geographic location, Time of Visit,
										Referring site, applications or service, search engine
										utilized), date and time stamps of use, the and pixels
										introduced or used on such gadget and the recorded movement
										(meetings, clicks and further cooperation&apos;s) of
										Visitors and Users regarding our Service. for purposes
										including examination, service-, tasks, and business quality
										control and enhancements, and record-keeping purposes.
									</p>
								</li>
							</ul>

							<p>
								This is to improve the website/App, services, and security,
								among which we include security inspection by the administration
								of the website/App and third parties.
							</p>

							<p>Geo-Location Tracking:</p>

							<p>
								We use geofencing to automatically check-out our users from
								their visited properties, in order to help them avoid getting
								fees for not checking out. For this feature to work, it is
								critical for users to enable Location Services for the SELECT
								UAE app on their phone. This geo-location tracking is solely
								used to suggest the closest properties to the user, and to help
								them check out automatically when they leave the premises. It is
								not used for any other tracking, nor is this information shared
								with any third party.
							</p>

							<h3>How do we use your details?</h3>

							<p>
								We may utilize the data we procure from you when you enlist,
								make a buy, join our bulletin, respond to an examination or
								promoting correspondence, peruse the site, or utilize specific
								other site includes in the following ways:
							</p>

							<ul>
								<li>
									<p>Create your account; and</p>
								</li>
								<li>
									<p>Deliver any services purchased by you to you; and</p>
								</li>
								<li>
									<p>Correspond with you; and</p>
								</li>
								<li>
									<p>
										Users can log in on our app, but we do not track their
										activities there for marketing purposes.
									</p>
								</li>
								<li>
									<p>
										Compile anonymous statistical data for our own use or for a
										third party&apos;s use; and
									</p>
								</li>
								<li>
									<p>Assist law enforcement as necessary; and</p>
								</li>
								<li>
									<p>
										Prevent fraudulent activity on our website or mobile app;
										and
									</p>
								</li>
								<li>
									<p>
										Analyze trends to improve our website/App and offerings.
									</p>
								</li>
								<li>
									<p>
										To fulfill or meet the reason you provided the information
										(e.g., to help provide our Site services to you).
									</p>
								</li>
								<li>
									<p>
										To personalize and develop our site and the services we
										provide you and improve our offerings.
									</p>
								</li>
								<li>
									<p>
										To provide certain features or functionality of the services
										on the site.
									</p>
								</li>
								<li>
									<p>For marketing and promotions.</p>
								</li>
								<li>
									<p>
										To create, maintain, customize, and secure your account with
										us.
									</p>
								</li>
								<li>
									<p>
										To provide you with support, to communicate with you and
										respond to your inquiries, including to investigate and
										address your concerns and monitor and improve our responses.
									</p>
								</li>
								<li>
									<p>
										To personalize your experience and to deliver content and
										services relevant to your interests.
									</p>
								</li>
								<li>
									<p>
										To help maintain the safety, security, and integrity of our
										site, services, databases, and other technology assets and
										business.
									</p>
								</li>
								<li>
									<p>
										To respond to law enforcement requests and as required by
										applicable law, court order, or governmental regulations.
									</p>
								</li>
								<li>
									<p>To prevent illegal activity, fraud, and abuse.</p>
								</li>
								<li>
									<p>
										Where we have to play out the agreement we are going to go
										into or have gone into with you
									</p>
								</li>
								<li>
									<p>To help our site that will ready to serve you better.</p>
								</li>
								<li>
									<p>
										To allow us to brought administration you up in furnishing a
										response to your client assistance demands.
									</p>
								</li>
								<li>
									<p>To procure rankings and audits of items</p>
								</li>
								<li>
									<p>
										To send messages after a certain time routinely, with
										respect to your administrations or items and different
										items.
									</p>
								</li>
								<li>
									<p>
										To catch up after correspondence with (live talk, email, or
										telephone requests)
									</p>
								</li>
							</ul>

							<h3>How do we protect your details?</h3>

							<ul>
								<li>
									<p>
										We do not use vulnerability scanning and/or scanning to PCI
										specifications.
									</p>
								</li>
								<li>
									<p>
										We only provide articles and information. We never require
										credit card volumes.
									</p>
								</li>
								<li>
									<p>We use regular Malware Scanning.</p>
								</li>
								<li>
									<p>
										Your individual information is comprised behind secured
										systems and is merely accessible by a restricted number of
										folks who&apos;ve special access privileges to such systems
										and must keep the information confidential carefully.
										Furthermore, all very sensitive/credit information you
										resource is encrypted via Secure Socket Layer (SSL)
										technology.
									</p>
								</li>
								<li>
									<p>
										We implement a number of security measures whenever a user
										gets into, submits, or accesses their information to keep up
										the protection of your individual information.
									</p>
								</li>
							</ul>

							<h3>Do we use cookies?</h3>

							<p>
								Yes. Cookies are small documents a site or its provider
								exchanges to your computer&apos;s hard drive through your
								browser (if you allow) that permit the site&apos;s or service
								provider&apos;s systems to identify your internet browser and
								capture please remember certain information. For example, we use
								cookies to help us keep in mind and process the things in your
								shopping cart software. Also, they are used to help us
								understand your requirements based on prior or current site
								activity, which permits us to offer you improved upon services.
								We also use cookies to help us put together aggregate data about
								site traffic and site conversation so that people may offer
								better site experience and tools in the foreseeable future.
							</p>

							<h3>We use cookies to:</h3>

							<ul>
								<li>
									<p>
										Understand and save user&apos;s tastes for future views or
										visits of our site.
									</p>
								</li>
								<li>
									<p>Keep an eye on advertisements.</p>
								</li>
								<li>
									<p>
										Compile aggregate data about site traffic and site
										connections in order to provide better site activities and
										tools in the foreseeable future.
									</p>
								</li>
							</ul>
							<p>
								You are able to choose that your personal computer warns you
								whenever a cookie has been directed, or you can select to turn
								off all cookies carefully. You can perform that through your web
								browser settings. Since the internet browser is just a little
								different, check out your browser&apos;s Help Menu to learn the
								way in which to change your cookies.
							</p>
							<p>
								If you change cookies off, many of the features that produce
								your site experience better might not exactly function properly.
								It will not impact the user&apos;s experience, which builds your
								site experience better and might not function properly.
							</p>

							<h3>Your Legal Rights</h3>

							<p>
								Under certain circumstances, you have rights under data
								protection laws in relation to your personal data.
							</p>

							<h3>You may have the following rights:&nbsp;</h3>

							<ol>
								<li>
									<p>
										Request access to your personal data (commonly known as a
										&quot;data subject access request&quot;). This enables you
										to receive a copy of the personal data we hold about you and
										to check that we are lawfully processing it.
									</p>
								</li>
								<li>
									<p>
										Request correction of the personal data that we hold about
										you. This enables you to have any incomplete or inaccurate
										data we hold about you corrected, though we may need to
										verify the accuracy of the new data you provide to us.
									</p>
								</li>
								<li>
									<p>
										Request erasure of your personal data. This enables you to
										ask us to delete or remove personal data where there is no
										good reason for us continuing to process it. You also have
										the right to ask us to delete or remove your personal data
										where you have successfully exercised your right to object
										to processing (see below), where we may have processed your
										information unlawfully or where we are required to erase
										your personal data to comply with local law. Note, however,
										that we may not always be able to comply with your request
										of erasure for specific legal reasons which will be notified
										to you, if applicable, at the time of your request.
									</p>
								</li>
								<li>
									<p>
										Object to processing of your personal data where we are
										relying on a legitimate interest (or those of a third party)
										and there is something about your particular situation which
										makes you want to object to processing on this ground as you
										feel it impacts on your fundamental rights and freedoms. You
										also have the right to object where we are processing your
										personal data for direct marketing purposes. In some cases,
										we may demonstrate that we have compelling legitimate
										grounds to process your information which override your
										rights and freedoms.
									</p>
								</li>
								<li>
									<p>
										Request restriction of processing of your personal data.
										This enables you to ask us to suspend the processing of your
										personal data in the following scenarios:
									</p>
									<ol>
										<li>
											<p>
												If you want us to establish the data&apos;s accuracy.
											</p>
										</li>
										<li>
											<p>
												Where our use of the data is unlawful, but you do not
												want us to erase it.
											</p>
										</li>
										<li>
											<p>
												Where you need us to hold the data even if we no longer
												require it as you need it to establish, exercise or
												defend legal claims.
											</p>
										</li>
										<li>
											<p>
												You have objected to our use of your data but we need to
												verify whether we have overriding legitimate grounds to
												use it.
											</p>
										</li>
									</ol>
								</li>
								<li>
									<p>
										Request the transfer of your personal data to you or to a
										third party. We will provide to you, or a third party you
										have chosen, your personal data in a structured, commonly
										used, machine-readable format. Note that this right only
										applies to automated information which you initially
										provided consent for us to use or where we used the
										information to perform a contract with you.
									</p>
								</li>
								<li>
									<p>
										Withdraw consent at any time where we are relying on consent
										to process your personal data. However, this will not affect
										the lawfulness of any processing carried out before you
										withdraw your consent. If you withdraw your consent, we may
										not be able to provide certain services to you.
									</p>
								</li>
							</ol>

							<h3>Limitation of liability</h3>

							<ul>
								<li>
									<p>
										Some jurisdictions do not allow the limitation or exclusion
										of liability for incidental or consequential damages so some
										of the above limitations may not apply to you.
									</p>
								</li>
								<li>
									<p>
										We make no legal representation that the website/App or
										services are appropriate or available for use in locations
										outside United Arab Emirates. You may access the website
										from outside United Arab Emirates.at your own risk and
										initiative and must bear all responsibility for compliance
										with any applicable foreign laws.
									</p>
								</li>
							</ul>

							<h3>Indemnification</h3>

							<ul>
								<li>
									<p>
										Upon visiting this Platform you agree release, indemnify,
										defend and hold harmless Theselectapp.com and any of its
										contractors, agents, employees, officers, directors,
										shareholders, affiliates and assigns from all liabilities,
										claims, damages, costs and expenses, including reasonable
										attorneys&apos; fees and expenses, of third parties relating
										to or arising out of your use of the website content; your
										use of the services; your violation of any provision of
										these terms; any unauthorized information or data you
										supplied to us. You will have sole responsibility to defend
										us against any claim, but you must receive our prior written
										consent regarding any related settlement.
									</p>
								</li>
							</ul>

							<h3>Governing Law and Jurisdiction</h3>

							<ul>
								<li>
									<p>
										This website originates from United Arab Emirates. The laws
										of United Arab Emirates. without regard to its conflict of
										law principles will govern these terms to the contrary. You,
										hereby agree that, all disputes arising out of or in
										connection with these terms shall be submitted to the
										exclusive jurisdiction of the United Arab Emirates. By using
										this website, you consent to the jurisdiction and venue of
										such courts in connection with any action, suit, proceeding
										or claim arising under or by reason of these terms. You
										hereby waive any right to trial by jury arising out of these
										terms.
									</p>
								</li>
							</ul>

							<h3>Changes to this privacy notice</h3>

							<p>
								We reserve the right to alter this privacy notice at any time.
								Such alterations will be posted on our website. You can also
								obtain an up-to-date copy of our privacy notice by contacting
								us.
							</p>

							<h3>Contacting us</h3>

							<p>
								If you would like to contact us to understand more about this
								Policy or wish to contact us concerning any matter relating to
								individual rights and your Personal Information, you may do so
								via the{' '}
								<a
									href='https://www.theselectapp.com/contact-us'
									target='_blank'>
									contact us
								</a>{' '}
								or email us at
								<a
									href='mailto:Info@theselectapp.com'
									target='_self'>
									Info@theselectapp.com
								</a>
							</p>

							<p>SELECT LOYALTY CARD SERVICES</p>
							<p>United Arab Emirates&nbsp;</p>

							<p>This document was last updated on September 1, 2021</p>
						</div>
					</div>
				</div>
			</ContentContainer>
			<GoWellFooter />
		</PageWrapper>
	);
};

export default PrivacyPolicy;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	margin-left: -40px;
	margin-right: -40px;
	padding-left: 40px;
	padding-right: 40px;
	color: ${Colors.primary.white};
`;
