export const Colors = {
	primary: {
		red: '#E00800',
		white: '#FFFFFF',
		black: '#000000',
		blue: '#0076FF',
	},
	secondary: {
		offWhite1: '#E6E6DC',
		offWhite2: '#E6E6DCB3',
		offWhite3: '#E6E6DC4D',
		offGrey: '#DFDFDF',
		grey: '#C8C8C8',
		grey1: '#7C7C7A',
		grey2: '#7C7C7AB3',
		grey3: '#7C7C7A4D',
		grey4: '#F9F9F9C7',
		grey5: '#959595',
		brown1: '#906A51',
		brown2: '#906A51B3',
		brown3: '#906A514D',
	},
	tertiary: {
		cyan: '#75E6E5',
		magenta: '#FB4667',
		green: '#99EE56',
	},
	grey: {
		darkGrey1: '#121314',
		darkGrey2: '#1E1F21',
		darkGrey3: '#393A3A',
		darkGrey4: '#252424',
		darkGrey5: '#8E8E8E',
		darkGrey6: '#17181A',
		darkGrey7: '#2F2F2F',
		darkGrey8: '#030303',
		lightGrey: '#616161',
		lightGrey1: '#717172',
		lightGrey2: '#D0D0D0',
		lightGrey3: '#838383',
	},
	system: {
		error: '#E30327',
		errorLight: '#FCE6E9',
		success: '#54CA2B',
		successLight: '#EEFAEA',
	},
};
