/* eslint-disable no-useless-escape */
export interface UserSignUpProps {
	UserId?: string;
	FirstName: string;
	LastName: string;
	MobileNumber: string;
	Email: string;
	OTP?: string;
	Password: string;
	GoWellUserId?: string;
	GoWellSignupRequestId?: string;
}

export interface UserSignUpPropsValid {
	UserId?: boolean;
	FirstName: boolean;
	LastName: boolean;
	MobileNumber: boolean;
	Email: boolean;
	OTP?: boolean;
	Password: boolean;
}

export interface UserSignUpOtpPropsValid {
	OTP?: boolean;
}

export const UserSignUpPropsPattern = {
	UserId: /([A-z]){2,}/,
	FirstName: /([A-z]){2,}/,
	LastName: /([A-z]){2,}/,
	MobileNumber: /^([0|\+[0-9]{1,5})?([7-9][0-9]{8,})$/,
	// Email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	Email: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
	Password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
	OTP: /([0-9]){6,}/,
};
