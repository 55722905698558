import React from 'react';
import { styled } from 'styled-components';
import GoWellLogo from '../../assets/images/GoWell Logo.png';
import { Colors } from '../../utils/configs/Colors';

const GoWellFooter = () => {
	return (
		<></>
		// <Container>
		// 	<Text>Go back to</Text>
		// 	<Logo src={GoWellLogo} />
		// </Container>
	);
};

export default GoWellFooter;

const Container = styled.div`
	display: flex;
	flex: 1;
	width: max-content;
	max-height: 21px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 16px;
	margin: 0px auto;
	gap: 16px;
	background-color: ${Colors.primary.black};
`;

const Text = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	color: ${Colors.primary.white};
`;

const Logo = styled.img`
	height: 17px;
	aspect-ratio: 37/9;
`;
