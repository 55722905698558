import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { styled } from 'styled-components';
import Router from './routes/router';
import { CommonContextProvider } from './context/CommonContext';
import { AxiosInterceptor } from './utils/services/api/api';

const App = () => {
	return (
		<MainWrapper>
			<BrowserRouter>
				<CommonContextProvider>
					<AxiosInterceptor>
						<Routes>{Router()}</Routes>
					</AxiosInterceptor>
				</CommonContextProvider>
			</BrowserRouter>
		</MainWrapper>
	);
};

export default App;

const MainWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
`;
