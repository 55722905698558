export const getHashValue = (hashKey: string): string => {
	let hashStr = (window.location.hash || '').replace('#', '');
	if (!!hashStr && hashStr === 'null') {
		return '';
	}
	const hashes = hashStr.split('&');
	let hashObj: any = {};
	for (const hash of hashes) {
		let [key, value] = hash.split('=');
		hashObj[key] = value;
	}
	return hashObj[hashKey] || '';
};
