import { ChangePasswordProps } from "../../../types_and_interfaces/change-password";
import { CurrentPasswordProps } from "../../../types_and_interfaces/current-password";
import { NewMobileOtp } from "../../../types_and_interfaces/new-mobile";
import { RecoveryPasswordProps } from "../../../types_and_interfaces/recovery-password";
import { SendMobileOtpProps } from "../../../types_and_interfaces/send-mobile-otp";
import { post } from "./api";

export const SendRecoveryEmailOtp = async (data: RecoveryPasswordProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/sendEmailToRecovery",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyEmailOtp = async (data: RecoveryPasswordProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/verifyEmailOtp",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyMobileOtp = async (data: RecoveryPasswordProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/verifyMobileOtp",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyMobileOtpSignUp = async (data: RecoveryPasswordProps) => {
  try {
    const response = await post(
      "origin1",
      "b2buser/verifymobilenumber",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (data: ChangePasswordProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/changePassword",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const SendMobileOtp = async (data: SendMobileOtpProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/sendMobileOtp",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyCurrentPassword = async (data: CurrentPasswordProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/verifyPassword",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const AddNewMobileNumber = async (data: NewMobileOtp) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/addNewMobileNumber",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
