import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import Topbar from '../../components/Topbar/Topbar';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import TextButton from '../../components/CustomButtons/TextButton';
import { useLocation, useNavigate } from 'react-router-dom';

const PurchasingSupport2 = () => {
	const {state} = useLocation();

	const navigate = useNavigate();

	const goToChangeConfirmation = () => {
		navigate('/miniProfile?email=' + state?.email);
	};
	return (
		<PageWrapper>
            <LogoHeader />
			<Spacer space={{ x: '100%', y: '48px' }} />
			<Title
				title='Oops'
				color={Colors.primary.white}
			/>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<CustomText
				text='Looks like you are having trouble purchasing a gym membership. If you would like assistance from our team, please contact us with the contacts below'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '49px' }} />
			<ContactDetails />
			<Spacer space={{ x: '100%', y: '100%' }} />
			<TextButton color={Colors.primary.red} onClick={goToChangeConfirmation}>Go Back to Profile</TextButton>
			<Spacer space={{ x: '100%', y: '120px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default PurchasingSupport2;
