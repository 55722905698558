import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	FlowTypes,
	FlowTypesType,
	ToastProps,
	ToastTypes,
	commonContextProps,
	loaderProps,
} from '../types_and_interfaces/common-context';
import { getLSItem, setLSItem } from '../utils/services/storage';
import { StorageConstants } from '../utils/services/storage/constants';
import Toast from '../components/Toast/Toast';
import LoaderPopup from '../components/Popup/LoaderPopup';

export const defaultCommonContext: commonContextProps = {
	flow: FlowTypes.SignUp,
	disableLoader: false,
	updateFlow: (_newFlow = FlowTypes.SignUp) => {},
	addToast: (_newToast: ToastProps) => {},
	openLoader: (_props?: loaderProps) => {},
	hideLoader: () => {},
	toggleLoaderDisableState: (_state: boolean = false) => {},
};

export const CommonContext =
	createContext<commonContextProps>(defaultCommonContext);

export const CommonContextProvider = (props: any) => {
	const [flow, setFlow] = useState<FlowTypesType>(FlowTypes.SignUp);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toast, setToast] = useState<ToastProps | null>(null);
	const [showLoader, setShowLoader] = useState<boolean>(false);
	const [disableLoader, setDisableLoader] = useState<boolean>(false);

	useEffect(() => {
		getCurrentFlow();
	}, []);

	const getCurrentFlow = async () => {
		try {
			let newFlow = (await getLSItem(StorageConstants.flow))?.value;
			updateFlow(newFlow || FlowTypes.SignUp);
		} catch (error) {
			updateFlow(FlowTypes.SignUp);
		}
	};

	const updateFlow = async (newFlow: FlowTypesType = FlowTypes.SignUp) => {
		setFlow(newFlow);
		await setLSItem(StorageConstants.flow, newFlow);
	};

	const addToast = (newToast: ToastProps) => {
		const { autoHide = true } = newToast;
		setShowToast(true);
		setToast(newToast);
		if (autoHide) {
			let timer = setTimeout(() => {
				hideToast();
				if (timer) clearTimeout(timer);
			}, newToast.autoHideTime || 5000);
		}
	};

	const hideToast = () => {
		setShowToast(false);
	};

	const openLoader = (props?: loaderProps) => {
		if (!disableLoader) {
			const { autoHide = false, autoHideTime = 10000 } = props || {};
			setShowLoader(true);
			if (autoHide) {
				let timer = setTimeout(() => {
					hideLoader();
					if (timer) clearTimeout(timer);
				}, autoHideTime);
			}
		}
	};

	const hideLoader = () => {
		setShowLoader(false);
	};

	const toggleLoaderDisableState = (state: boolean = false) => {
		setDisableLoader(state);
	};

	const value = {
		flow,
		disableLoader,
		updateFlow,
		addToast,
		openLoader,
		hideLoader,
		toggleLoaderDisableState,
	};

	return (
		<CommonContext.Provider
			value={value}
			{...props}>
			{props.children}
			<Toast
				show={showToast}
				hide={hideToast}
				type={toast?.type || ToastTypes.Error}
				message={toast?.message || ''}
			/>
			<LoaderPopup show={showLoader} />
		</CommonContext.Provider>
	);
};

export const useCommonContext = () => {
	const context = useContext(CommonContext);
	return context;
};
