import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import styled from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { VenueList } from '../../utils/contants/venues';
import Topbar from '../../components/Topbar/Topbar';
import CustomAccordion from '../../components/CustomAccordian/CustomAccordian';
import Spacer from '../../components/Spacer/Spacer';

const Venues = () => {
	const renderCards = () => {
		return VenueList.map((venue) => {
			return (
				<Card>
					<CustomAccordion.Container>
						<CustomAccordion.Summary>
							<TitleContainer>
								<TitleLeft>{venue.city}</TitleLeft>
								<TitleRight>{venue.noOfGyms}</TitleRight>
							</TitleContainer>
						</CustomAccordion.Summary>
						<CustomAccordion.Details>
							<Banner src={venue.banner} />
								{venue.locationList.map((locationObj: any) => (
									locationObj.gyms.length > 0
										?
											<>
												<TextH>{locationObj.item}</TextH>
												{locationObj.gyms.map((item: any) => (
													<Row>
														<Text>{item}</Text>
													</Row>
												))}
												<Spacer space={{ x: '100%', y: '14px' }} />
											</>
										:
											<Row>
												<TextH>{locationObj.item}</TextH>
											</Row>
									
								))}
						</CustomAccordion.Details>
					</CustomAccordion.Container>
				</Card>
			);
		});
	};
	return (
		<PageWrapper>
			<Topbar title='List of eligible gyms' />
			<ContentContainer>{renderCards()}</ContentContainer>
		</PageWrapper>
	);
};

export default Venues;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	margin-left: -40px;
	margin-right: -40px;
	padding-left: 16px;
	padding-right: 16px;
`;

const Card = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 0px;
	border-radius: 8px;
	background-color: ${Colors.grey.darkGrey6};
	margin-top: 8px;
	margin-bottom: 8px;

	& .MuiAccordion-root {
		border-radius: 8px;
		border-bottom: 0px solid transparent !important;
		background-color: unset !important;
	}

	& .MuiAccordionSummary-root {
		padding: 8px 16px;
		border-radius: 8px;
		background-color: ${Colors.grey.darkGrey6} !important;
	}

	& .MuiAccordionDetails-root {
		border-radius: 0px 0px 8px 8px;
		background-color: ${Colors.grey.darkGrey6} !important;
		border-top: 2.4px solid ${Colors.grey.darkGrey7};
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	margin-right: 16px;
`;

const TitleLeft = styled.span`
	font-size: 24px;
	font-weight: 500;
	line-height: 120%;
	color: ${Colors.primary.white};
	padding-right: 16px;
	/* border-right: 1px solid ${Colors.grey.darkGrey7}; */
`;

const TitleRight = styled.span`
	font-size: 24px;
	font-weight: 500;
	line-height: 120%;
	color: ${Colors.primary.white};
	margin-left: 16px;
`;

const Banner = styled.img`
	width: 100%;
	box-sizing: border-box;
	aspect-ratio: 343/119;
	object-fit: cover;
	margin-top: 16px;
	margin-bottom: 16px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Text = styled.span`
	font-size: 14px;
	line-height: 18px;
	color: ${Colors.primary.white};
`;

const TextH = styled.span`
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	color: ${Colors.primary.white};
`;
