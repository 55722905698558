import React from 'react';
import { styled } from 'styled-components';
import { titleProps } from '../../types_and_interfaces/title';
import { Colors } from '../../utils/configs/Colors';

const Title = (props: titleProps) => {
	const { styles, title } = props;
	return (
		<TitleContainer style={styles?.container}>
			<TitleText style={styles?.text}>{title}</TitleText>
		</TitleContainer>
	);
};

export default Title;

const TitleContainer = styled.div`
	text-align: center;
	margin-top: 24px;
	margin-bottom: 40px;
`;

const TitleText = styled.div`
	font-family: 'SF-Pro-Display-Bold';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: ${Colors.primary.white};
`;
