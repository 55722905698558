/* eslint-disable no-useless-escape */
export interface SendMobileOtpProps {
	MobileNumber: string;
	EmailVerificationToken?: string;
	Email?: string;
}

export interface SendMobileOtpPropsValid {
	MobileNumber?: boolean;
}

export const SendMobileOtpPropsPattern = {
	MobileNumber: /^([0|\+[0-9]{1,5})?([7-9][0-9]{8,})$/,
};
