import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import Spacer from '../../components/Spacer/Spacer';
import Title from '../../components/Title/Title';
import { Colors } from '../../utils/configs/Colors';
import CustomText from '../../components/CustomText/CustomText';
import CustomOTPInput from '../../components/CustomInput/CustomOTPInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import TextButton from '../../components/CustomButtons/TextButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserSignUpOtpPropsValid, UserSignUpProps, UserSignUpPropsPattern } from '../../types_and_interfaces/user-signup';
import { SendEmailOtp, SendMobileOtp, SendMobileOtpSignupflow, VerifyEmailByOtp } from '../../utils/services/api/signup';
import ErrorText from '../../components/CustomText/ErrorText';
import { ContextConstants } from '../../utils/services/storage/constants';
import { RecoveryPasswordProps } from '../../types_and_interfaces/recovery-password';
import { SendRecoveryEmailOtp, VerifyEmailOtp } from '../../utils/services/api/recovery';
import { CommonContext, CommonContextProvider, useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';

const EmailConfirmationRecoveryPwd = () => {

	const search = useLocation().search;

	const { state } = useLocation();

	const { addToast, flow } = useCommonContext();

	const [sendAgainText, setSendAgaintext] = useState(true)
	const [timer, setTimer] = useState(30);
	const [timerLabel, setTimerLabel] = useState("30");

	useEffect(() => {
		const updateInterval = setInterval(() => {
			if (timer === 30) {
				setTimerLabel("30");
				setTimer(timer - 1);
			} else if (timer === 0) {
				setSendAgaintext(false);
				clearInterval(updateInterval)
			} else {
				setTimerLabel(`${timer}`);
				setTimer(timer - 1);
			}
		}, 1000);
		return () => clearInterval(updateInterval);
	}, [timer]);

	const userId = new URLSearchParams(search).get('userId') || '';

	const [formData, setFormData] = useState<UserSignUpProps>({
		UserId: userId,
		FirstName: '',
		LastName: '',
		MobileNumber: '',
		Email: '',
		Password: '',
	});

	const [recoverFormData, setRecoverFormData] = useState<RecoveryPasswordProps>({
		UserId: userId,
		Email: state?.email,
	});

	const [recoveryData, setRecoveryData] = useState<RecoveryPasswordProps>({});

	const [formDataValidState, setFormDataValidState] =
		useState<UserSignUpOtpPropsValid>({
			OTP: false,
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'OTP':
				reg = UserSignUpPropsPattern.OTP;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);

		let otp = { ...formData };
		setRecoveryData({ otp: otp.OTP, Email: state?.email });
	}, [formDataValidState]);


	const handleChange = (value: string, target: string) => {
		setFormData((current: UserSignUpProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault:  () => void }) =>  {
		console.log(recoveryData);
			VerifyEmailOtp(recoveryData).then((data) => {
				console.log(data);
				if (data.isSuccess) {
                    goToMobileConfirmationByRecoveryFlow(data);
					// if (state.option === ContextConstants.recoveryPwd || state.option === ContextConstants.changePwd) {
					// 	// goToCreateNewPassword(data);
					// 	goToMobileConfirmationByRecoveryFlow(data.verifyToken);
					// }
					// else if (state.option === ContextConstants.changeMobileFlow) {
					// 	goToAddNewMobileNumber(data.verifyToken);
					// } else if (state.option === ContextConstants.setMobileOtp) {
					// 	goToMobileChangeSuccess();
					// }
				}
				else{
					addToast({
						type: ToastTypes.Error,
						message: data.message,
					});
				}
			}).catch((err) => {
				console.log(err);
                goToError();
			});
	};

	const sendEmailOTPAgain = () => {
		setTimerLabel("30");
		setTimer(30);
		setSendAgaintext(true);
		sendEmailOtp();
	}
	
	const sendEmailOtp = () => {
		SendRecoveryEmailOtp(recoverFormData).then((response) => {
			console.log(response);
			if (!response?.isSuccess) {
				setTimerLabel('');
				setTimer(0);
				addToast({
					type: ToastTypes.Error,
					message: "Failed to send SMS OTP",
				})
			}
		});
	}

	const navigate = useNavigate();

	const goToMobileConfirmation = (userId: string) => {
		navigate('/mobileConfirmationRecoveryPwd?userId=' + userId, {state : { email : state?.Email ||  formData.Email, mobileNumber : state?.MobileNumber || formData.MobileNumber}});
	};

	const goToMobileConfirmationByRecoveryFlow = (data: any) => {
		const opt = state.option;
		navigate('/mobileConfirmationRecoveryPwd', { state: { option: opt, email: state?.email, recoveryToken: data.verifyToken, mobileNumber: data.mobileNumber } });
	};

	// const goToCreateNewPassword = (token: string) => {
	// 	navigate('/createNewPassword', { state: { option: ContextConstants.recoveryPwd, email: state?.email, recoveryToken: token } });
	// };

	const goToAddNewMobileNumber = (token: string) => {
		navigate('/addNewMobileNumber', { state: { email: state?.email, recoveryToken: token } });
	};

	const goToMobileChangeSuccess = () => {
		navigate('/changedMobileNumber', { state: { option: ContextConstants.changeMobileFlow, mobile: state?.mobile } });
	};

	const goBack = () => {
		navigate(-1);
	};

    const goToError=()=>{
		navigate('/errorRecoverPwd');
	}

	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title={'Check your email'}
				color={Colors.primary.white}
			/>
			<CustomText
				text={'Enter a code we have just sent to ' + state?.email + ' to continue'}
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '49px' }} />
			<CustomOTPInput
				title='Enter code'
				value={formData.OTP}
				onChange={(event) => handleChange(event, 'OTP')} error={!formDataValidState.OTP && !['', null, undefined].includes(formData?.OTP)}
				errorComp={
					<ErrorText>The entered OTP is invalid. Please try again, or click Send Again for a new OTP.</ErrorText>
				}
			/>
			<Spacer space={{ x: '100%', y: '40px' }} />
			{ flow !== 'UpdatePassword' 
			    && <CustomText
				text={
					<>
						Didn{`'`}t receive OTP?{' '}{sendAgainText ? ('Send again (' + timerLabel + ')') : ''}
						{!sendAgainText && <TextButton
							onClick={sendEmailOTPAgain}
							width='unset'
							padding='unset'
							color={Colors.primary.red}>
							Send again
						</TextButton>}
					</>
				}
				alignment='center'
			/>
            }
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Continue</PrimaryButton>
			<TextButton
				onClick={goBack}
				color={Colors.primary.red}>
				Go Back
			</TextButton>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default EmailConfirmationRecoveryPwd;
