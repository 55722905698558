export const baseURL = {
	//origin2: 'https://localhost:5001/api/',
	//origin1: 'https://localhost:44327/api/',
	//origin2: 'https://test-env.theselectapp.com/app2/api/',
	//origin1: 'https://test-env.theselectapp.com/app/api/',
	origin2: 'https://admin.theselectapp.com/app/api/',
	origin1: 'https://admin.theselectapp.com/appb2b/api/',

	//http://test-env.theselectapp.com/
};
