import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';

const TermsAndConditions = () => {
	return (
		<ContentContainer>
			<p>
				THE AGREEMENT: The use of this website and services on this website and
				mobile application provided by Select Loyalty Card Services (hereinafter
				referred to as &quot;Website&quot; and &ldquo;App&rdquo;) are subject to
				the following Terms &amp; Conditions, all parts and sub-parts of which
				are specifically incorporated by reference here. This Agreement shall
				govern the use of all pages on this website (hereinafter collectively
				referred to as &quot;Website&quot;) and SELECT UAE mobile application
				(hereinafter referred to as &quot;App&quot;) and any services provided
				by or on this Website or App (&quot;Services&quot;).
			</p>

			<h4>1. DEFINITIONS</h4>
			<p>
				&ldquo;Agreement&rdquo; denotes to this Terms and Conditions and the
				Privacy Policy and other documents provided to you by the Website;
			</p>
			<p>
				&ldquo;We&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; are references
				to Select Loyalty Card Services;
			</p>
			<p>
				&ldquo;User&rdquo;, &ldquo;You&rdquo; and &ldquo;your&rdquo; denotes the
				person who is accessing the website for taking or availing any service
				from us. User shall include the company, partnership, sole trader,
				person, body corporate or association taking services of this Website;
			</p>
			<p>
				&rdquo; Website&rdquo; and &ldquo;App&rdquo; shall mean and include
				Select Loyalty Card Services and any successor Website of the Company or
				any of its affiliates;
			</p>
			<p>
				Parties: Collectively, the parties to this Agreement (We and You) will
				be referred to as Parties.
			</p>

			<h4>2. ASSENT &amp; ACCEPTANCE</h4>
			<p>
				By using the Website, you warrant that you have read and reviewed this
				Agreement and that you agree to be bound by it. If you do not agree to
				be bound by this Agreement, please leave the Website immediately. We
				only agree to provide the users of this Website and Services to you if
				you assent to this Agreement.
			</p>

			<h4>3. MEMBERSHIP</h4>

			<ul>
				<li>
					<p>
						The Membership gives you access to a wide range of facilities,
						activities and outlets operated by third parties (collectively
						&ldquo;Venues&rdquo;). Select UAE does not own, operate or control
						any of the Services that are offered at or through such Venues.
					</p>
				</li>
				<li>
					<p>
						You can sign up for different types of Memberships with various
						subscription plans, non-subscription plans and promotional plans.
						These options may consist of different products, activities,
						experiences, or services and may be subject to additional and
						differing conditions, prices, policies and limitations.
					</p>
				</li>
				<li>
					<p>
						Some Membership options allow you to register your children, either
						free of charge or for an additional fee, to your account and bring
						them to select Venues. Children must be under your guardianship to
						be registered to your account.
					</p>
				</li>
				<li>
					<p>
						A Membership is valid for the duration you sign up for, unless
						earlier terminated or suspended by Select UAE or by request of you
						or as otherwise permitted under these Terms.
					</p>
				</li>
				<li>
					<p>
						When you sign up for a 12 months subscription plan you agree to
						automatically renew your Membership on the same terms as you
						initially signed up to unless you terminate your Membership, or it
						is otherwise terminated before the renewal date.
					</p>
				</li>
				<li>
					<p>
						When you sign up for a monthly-payment option (i.e. subscription),
						you agree to have your registered payment method charged with the
						agreed membership price every month.
					</p>
				</li>
			</ul>

			<h3>a) &nbsp; SIGNING UP FOR A MEMBERSHIP</h3>

			<ul>
				<li>
					<p>
						In order to purchase a Membership and sign up to become a Select UAE
						member (&quot;Member&quot;) you are required to sign up via the App
						and provide all required information.
					</p>
				</li>
				<li>
					<p>
						You agree that the information you provide at sign up or at any time
						throughout the period of your Membership is truthful, accurate,
						current and complete information about yourself, Guests and/or your
						children.
					</p>
				</li>
				<li>
					<p>
						You must provide a real and valid email and mobile phone number that
						belongs to you to sign up for a Membership. These will both be
						verified during the registration process.
					</p>
				</li>
				<li>
					<p>
						If you want to bring your own children to selected Venues you need
						to choose an eligible Membership. We reserve the right to require
						you to provide documentation to verify that the children registered
						in the App are under your guardianship.
					</p>
				</li>
				<li>
					<p>
						If you change your mobile phone number, email and/or address, you
						must update your information via the App. Should you require to edit
						fields that are non-editable in the App, please reach out to our
						customer service for support.
					</p>
				</li>
				<li>
					<p>
						At sign up you are required to create a password to your Select UAE
						account. You are solely responsible for all activity that occurs
						under your account, including any activity by unauthorized users.
					</p>
				</li>
				<li>
					<p>
						Your Membership will commence from the date of receipt of your
						completed sign up or re-activation of a pre-existing Membership and
						received payment of the Membership Fee.
					</p>
				</li>
			</ul>

			<h3>b) &nbsp; USING YOUR MEMBERSHIP</h3>

			<ul>
				<li>
					<p>
						To access our partner Venues with your Membership, you are required
						to present your Membership QR code on your App, and check in using
						the system to register visits by Members (Select UAE Registration
						System) upon arrival to our partner Venues. When you leave, you are
						required to check out via the App or via the Select UAE Registration
						System.
					</p>
				</li>
				<li>
					<p>
						If you are bringing your children to a Venue, we may require you to
						check them in via the Select UAE Registration System upon arrival to
						the Venue. When you leave a Venue, we may require you to check out
						your children via the App or via the Select UAE Registration System.
					</p>
				</li>
				<li>
					<p>
						Venues may refuse entry to Select membership holders at their
						discretion, during days of unavailable capacity (such as public
						holidays, private functions, venue maintenance closures,
						non-operational hours, and all other scenarios that Venues see fit).
					</p>
				</li>
				<li>
					<p>
						We reserve the right to charge a fee if you do not check in upon
						arrival to a Venue prior to using our Services and/or if you do not
						check out when you leave a Venue.
					</p>
				</li>
				<li>
					<p>
						To redeem discounts at our Venues, you are required to show your
						Membership QR Code on the App, and have the discount validated by
						the Venue staff either manually or via the App.
					</p>
				</li>
				<li>
					<p>
						When using our Services, you shall at all times behave with decorum
						and in a manner appropriate to the settings of our Services, in
						addition to following the venue&apos;s stated rules and regulations.
					</p>
				</li>
				<li>
					<p>
						If you have held a membership directly with a Venue that can be
						accessed with Select UAE, the Venue has the right to refuse entry
						for a maximum of three months from the date your membership ended
						with the venue.
					</p>
				</li>
			</ul>

			<h3>c) &nbsp; &nbsp;TRANSFERRING OF YOUR MEMBERSHIP</h3>

			<ul>
				<li>
					<p>
						Any Member (&quot;Transferor&quot;) who has signed up to a 12 months
						prepaid non-subscription plan are entitled to transfer their
						Membership to any third-party individual (&quot;Transferee&quot;) at
						any time within the first 12 months by sending an email to&nbsp;
						<a href='mailto:info@theselectapp.com'>
							info@theselectapp.com
						</a>{' '}
						with all required information to sign up to become a member.
					</p>
				</li>
				<li>
					<p>
						To transfer such Membership the Transferor is required to pay a fee
						corresponding to one month&rsquo;s Membership fee.
					</p>
				</li>
				<li>
					<p>
						Upon receipt by Select UAE of the email from the Transferor, Select
						UAE will within five days create an account for the Transferee.
					</p>
				</li>
				<li>
					<p>
						The Transfer of Membership will be terminated the day that the
						Transferees Membership will Commence.
					</p>
				</li>
				<li>
					<p>
						The Transferees Membership will expire on the date on which the
						Transferor&apos;s Membership was due to expire.
					</p>
				</li>
			</ul>

			<h3>d) &nbsp; TERMINATING YOUR MEMBERSHIP</h3>

			<ul>
				<li>
					<p>
						You can terminate your Membership at any time by sending an email
						with your termination to&nbsp;
						<a href='mailto:info@theselectapp.com'>info@theselectapp.com</a>
					</p>
				</li>
				<li>
					<p>
						Termination of your Membership will be effective from the date of
						receipt of your written termination.
					</p>
				</li>
				<li>
					<p>
						No refunds will be given in respect of the Membership Fee, following
						termination of your Membership.
					</p>
				</li>
				<li>
					<p>
						To terminate a 12 months subscription plan, within the 12 months
						period you are required to pay an early-termination fee
						corresponding to one month Membership Fee.
					</p>
				</li>
				<li>
					<p>
						Within the first 30 days of a 12 month subscription plan, the
						early-termination fee is waived for all users, thereby facilitating
						a free cancellation policy within the first month of the plan.
					</p>
				</li>
				<li>
					<p>
						Exceptions to the early-termination fee will only be considered in
						the event of unforeseen relocation outside of the country, due to
						job loss.
					</p>
				</li>
				<li>
					<p>
						Requests for early-termination fee waiver will be handled on a
						case-by-case basis. To be considered, please write to our customer
						service team by sending an email to{' '}
						<a
							href='mailto:info@theselectapp.com'
							target='_self'>
							info@theselectapp.com
						</a>{' '}
						with supporting documentation.
					</p>
				</li>
				<li>
					<p>
						Accepted supporting documents include; redundancy notice from your
						employer with a company stamp and confirmed relocation travel plans.
					</p>
				</li>
				<li>
					<p>
						Select UAE reserves the right to terminate your Membership,
						immediately and without notice if you fail to pay the Membership Fee
						prior to use of the Services or such payment is in any way rendered
						void following payment; or are otherwise in breach of any obligation
						in these Terms
					</p>
				</li>
				<li>
					<p>
						If you terminate your Membership, we reserve the right to charge a
						reactivation fee if you want to return to Select UAE within the
						following three months.
					</p>
				</li>
				<li>
					<p>
						If you terminate your subscription or it is terminated by Select UAE
						for any reason, we reserve the right to terminate your access to our
						Services, the App and all other features and products available
						through the Membership
					</p>
				</li>
				<li>
					<p>
						Pausing your Membership is allowed once per year for Annual
						membership holders, for a period of 1 month.
					</p>
				</li>
			</ul>

			<h3>e) &nbsp; GUEST PASS</h3>

			<ul>
				<li>
					<p>
						You have the option to purchase guest passes for use of your
						guest(s) (&ldquo;Guest(s)&rdquo;).
					</p>
				</li>
				<li>
					<p>All obligations in these Terms apply to Guests.</p>
				</li>
				<li>
					<p>
						You are liable for the actions of your Guests, when they use our
						Services in accordance with these Terms.
					</p>
				</li>
				<li>
					<p>
						You are responsible for bringing these Terms to the attention of
						your Guest(s) prior to use of our Services.
					</p>
				</li>
				<li>
					<p>
						By signing up a Guest you agree that they have acknowledged to
						adhere in full to these Terms.
					</p>
				</li>
				<li>
					<p>
						In addition to the liability of you for the actions of your Guest(s)
						whilst using our Services your Guest(s) are personally liable for
						their actions in accordance with these Terms.&nbsp;
					</p>
				</li>
			</ul>

			<h4>4) AGE RESTRICTION</h4>

			<p>
				You must be at least 16 (Sixteen) years of age to use this Website or
				any Services contained herein. By using this Website, you represent and
				warrant that you are at least 16 years of age and may legally agree to
				this Agreement. We assume no responsibility or liability for any
				misrepresentation of your age.
			</p>

			<h4>5) GENERAL CONDITION</h4>

			<ul>
				<li>
					<p>
						We do not guarantee the accuracy, completeness, validity, or
						timeliness of information listed by us.
					</p>
				</li>
				<li>
					<p>
						We make material changes to these terms and conditions from time to
						time, we may notify you either by prominently posting a notice of
						such changes or via email communication.
					</p>
				</li>
				<li>
					<p>
						The website is licensed to you on a limited, non-exclusive,
						non-transferable, non-sublicensable basis, solely to be used in
						connection with the Service for your private, personal,
						non-commercial use, subject to all the terms and conditions of this
						Agreement as they apply to the Service.
					</p>
				</li>
			</ul>

			<h4>6) LICENSE TO USE WEBSITE</h4>

			<p>
				We may provide you with certain information as a result of your use of
				the Website or Services. Such information may include but is not limited
				to, documentation, data, or information developed by us, and other
				materials which may assist in your use of the Website or Services
				(&quot;Our Materials&quot;). Subject to this Agreement, we grant you a
				non-exclusive, limited, non-transferable, and revocable license to use
				Our Materials solely in connection with your use of the Website and
				Services. Our Materials may not be used for any other purpose, and this
				license terminates upon your cessation of use of the Website or Services
				or at the termination of this Agreement.
			</p>

			<h4>7) USER CONTENT</h4>

			<h3>Content Responsibility.&nbsp;</h3>

			<p>
				The website permits you to share content, post comments, feedback, etc.
				but you are solely responsible for the content posted by you. You
				represent that you have required permission to use the content.
			</p>
			<p>
				When posting content to the website, please do not post content that:
			</p>
			<ul>
				<li>
					<p>
						contains ill-mannered, profane, abusive, racist or hateful language
						or expressions, text, photographs or illustrations that are
						pornographic or in poor taste, inflammatory attacks of a personal,
						racial or religious nature;
					</p>
				</li>
				<li>
					<p>
						is defamatory, threatening, disparaging, grossly inflammatory,
						false, misleading, fraudulent, inaccurate, unfair, contains gross
						exaggeration or unsubstantiated claims;
					</p>
				</li>
				<li>
					<p>
						violates the privacy rights of any third party, is unreasonably
						harmful or offensive to any individual or community;
					</p>
				</li>
				<li>
					<p>
						discriminates on the grounds of race, religion, national origin,
						gender, age, marital status, sexual orientation or disability, or
						refers to such matters in any manner prohibited by law;
					</p>
				</li>
				<li>
					<p>
						violates or inappropriately encourages the violation of any
						municipal, state, federal, or international law, rule, regulation,
						or ordinance;
					</p>
				</li>
				<li>
					<p>
						uses or attempts to use another&apos;s account, password, service,
						or system except as expressly permitted by the Terms of use uploads
						or transmits viruses or other harmful, disruptive, or destructive
						files;
					</p>
				</li>
				<li>
					<p>
						sends repeated messages related to another user and/or makes
						derogatory or offensive comments about another individual or repeats
						prior posting of the same message under multiple emails or subjects.
					</p>
				</li>
				<li>
					<p>
						Any submitted content that includes, but is not limited to the
						following, will be refused. If repeated violations occur, we reserve
						the right to cancel user access to the website without advanced
						notice.
					</p>
				</li>
			</ul>

			<h4>8) INTELLECTUAL PROPERTY</h4>

			<p>
				You agree that the Website and all Services provided by us are the
				property of Theselectapp.com, including all copyrights, trademarks,
				trade secrets, patents, and other intellectual property (&quot;Our
				IP&quot;). You agree that we own all rights, title, and interest in and
				to the Our IP and that you will not use Our IP for any unlawful or
				infringing purpose. You agree not to reproduce or distribute Our IP in
				any way, including electronically or via registration of any new
				trademarks, trade names, service marks, or Uniform Resource Locators
				(URLs), without express written permission from us.
			</p>
			<ul>
				<li>
					<p>
						To make the Website and Services available to you, you hereby grant
						us a royalty-free, non-exclusive, worldwide license to copy,
						display, use, broadcast, transmit and make derivative works of any
						content you publish, upload, or otherwise make available to the
						Website (&quot;Your Content&quot;). We claim no further proprietary
						rights in your Content.
					</p>
				</li>
				<li>
					<p>
						If you feel that any of your intellectual property rights have been
						infringed or otherwise violated by the posting of information or
						media by another of our users, please contact us and let us know.
					</p>
				</li>
			</ul>

			<h4>9) USER OBLIGATIONS</h4>

			<p>
				As a user of the Website or Services, you may be asked to register with
				us. When you do so, you will choose a user identifier, which may be your
				email address or another term, as well as a password. You may also
				provide personal information, including, but not limited to, your name.
				You are responsible for ensuring the accuracy of this information. This
				identifying information will enable you to use the Website and Services.
				You must not share such identifying information with any third party,
				and if you discover that your identifying information has been
				compromised, you agree to notify us immediately in writing. An email
				notification will suffice. You are responsible for maintaining the
				safety and security of your identifying information as well as keeping
				us apprised of any changes to your identifying information. Providing
				false or inaccurate information, or using the Website or Services to
				further fraud or unlawful activity is grounds for immediate termination
				of this Agreement.
			</p>

			<h4>10) PROMOTIONS AND OFFERS</h4>

			<ul>
				<li>
					<p>
						By participating in promotions, you agree to be bound by these Terms
						and any promotional specific terms and conditions.
					</p>
				</li>
				<li>
					<p>
						We reserve the right to add, remove, terminate, extend, unpublish
						and/or remove any promotion or venues at any time without prior
						notice.
					</p>
				</li>
				<li>
					<p>
						We cannot be held responsible for the prices, products and/or
						services offered to you by third-parties through the
						promotions.&nbsp;
					</p>
				</li>
			</ul>

			<h4>11) FEES, BILLING AND PAYMENT</h4>

			<ul>
				<li>
					<p>
						Fees for prepaid non-subscription plan must be paid in full upfront.
					</p>
				</li>
				<li>
					<p>
						Fees for subscription plans must be paid in monthly installments.
					</p>
				</li>
				<li>
					<p>
						The Membership fee must be paid at sign up and in advance of using
						our Services.
					</p>
				</li>
				<li>
					<p>
						Fee(s) are payable in Arab Emirate Dirham (&ldquo;AED&rdquo;) and
						may be paid by credit card.
					</p>
				</li>
				<li>
					<p>
						Corporate Memberships may be paid via bank transfer according to
						separate agreement between the company and select.
					</p>
				</li>
				<li>
					<p>
						The credit card used for the initial payment of your Membership will
						be kept on file with our chosen payment processor for the duration
						of your Membership.
					</p>
				</li>
				<li>
					<p>
						While your credit card details will be kept on file, Select UAE has
						no access to your complete payment details. These details will be in
						custody of our chosen payment processor, which holds security
						accreditations as per international and local standards.
					</p>
				</li>
				<li>
					<p>
						The owner of the credit card will be liable for any additional fees
						or charges applicable for the duration of the Membership
					</p>
				</li>
				<li>
					<p>
						The description of any Membership, including the Membership fee,
						purchased hereunder will be confirmed in Select&apos;s dispatch note
						and/or invoice at point of payment.
					</p>
				</li>
				<li>
					<p>You may edit your payment details by calling us.</p>
				</li>
				<li>
					<p>
						We reserve the right to adjust pricing at any time, including
						introducing an initial joining and/or re-activation fee. Unless
						otherwise stated, adjustment to pricing of your subscription occur
						on your next billing cycle upon notice communicated to you via the
						Website, App, email, text message, phone, mail or other means. If
						you do not terminate your subscription, you agree to the adjusted
						fees.
					</p>
				</li>
				<li>
					<p>
						Any increase in VAT, other sales or indirect taxes which may be due
						or introduced as a result of changes to Federal Law that are payable
						on payments under these Terms, Select reserves the right to collect
						these taxes from the Member.
					</p>
				</li>
				<li>
					<p>
						If we cannot charge your selected payment method for any reason
						(such as expiration or insufficient funds) remain responsible for
						any uncollected amounts, and we will attempt to charge your credit
						card or any other payment method you have provided again as you may
						update your payment method information, including in the event you
						attempt to sign up for a new account or reactivate.
					</p>
				</li>
				<li>
					<p>
						We may suspend your access to any of our Services until you have
						settled all outstanding fees.
					</p>
				</li>
				<li>
					<p>
						We reserve the right to charge you a late payment fee for any
						delayed payments.
					</p>
				</li>
			</ul>

			<h4>12) ACCEPTABLE USE</h4>

			<p>
				You agree not to use the Website or Services for any unlawful purpose or
				any purpose prohibited under this clause. You agree not to use the
				Website or Services in any way that could damage the Website, Services,
				or general business of theselectapp.com.
			</p>
			<ul>
				<li>
					<p>You further agree not to use the Website or Services:</p>
				</li>
				<li>
					<p>
						To harass, abuse, or threaten others or otherwise violate any
						person&apos;s legal rights;
					</p>
				</li>
				<li>
					<p>
						To violate any of our intellectual property rights or any third
						party;
					</p>
				</li>
				<li>
					<p>
						To upload or otherwise disseminate any computer viruses or other
						software that may damage the property of another;
					</p>
				</li>
				<li>
					<p>To perpetrate any fraud;</p>
				</li>
				<li>
					<p>
						To engage in or create any unlawful gambling, sweepstakes, or
						pyramid scheme;
					</p>
				</li>
				<li>
					<p>To publish or distribute any obscene or defamatory material;</p>
				</li>
				<li>
					<p>
						To publish or distribute any material that incites violence, hate,
						or discrimination towards any group;
					</p>
				</li>
				<li>
					<p>To unlawfully gather information about others.</p>
				</li>
			</ul>

			<h4>13) ASSUMPTION OF RISK</h4>

			<p>
				The Website and Services are provided for communication purposes only.
				You acknowledge and agree that any information posted on Our Website is
				not intended to be legal advice, medical advice, or financial advice,
				and no fiduciary relationship has been created between you and us. You
				further agree that your purchase of any of the products on the Website
				is at your own risk. We do not assume responsibility or liability for
				any advice or other information given on the Website.
			</p>

			<h4>14) REVERSE ENGINEERING &amp; SECURITY</h4>

			<p>You agree not to undertake any of the following actions:</p>
			<p>
				a) Reverse engineer, or attempt to reverse engineer or disassemble any
				code or software from or on the Website or Services;
			</p>
			<p>
				b) Violate the security of the Website or Services through any
				unauthorized access, circumvention of encryption or other security
				tools, data mining, or interference to any host, user, or network.
			</p>

			<h4>15) INDEMNIFICATION</h4>

			<p>
				You agree to defend and indemnify us and any of our affiliates (if
				applicable) and hold us harmless against any legal claims and demands,
				including reasonable attorney&apos;s fees, which may arise from or
				relate to your use or misuse of the Website or Services, your breach of
				this Agreement, or your conduct or actions. You agree that we shall be
				able to select its legal counsel and may participate in its defense if
				we wish.
			</p>

			<h4>16) EXCLUSION OF LIABILITY</h4>

			<p>
				You understand and agree that we (A) do not guarantee the accuracy,
				completeness, validity, or timeliness of information listed by us or any
				third parties; and (B) shall not be responsible for any materials posted
				by us or any third party. You shall use your judgment, caution, and
				common sense in evaluating any prospective methods or offers and any
				information provided by us or any third party.
			</p>
			<p>
				Further, we shall not be liable for direct, indirect consequential, or
				any other form of loss or damage that may be suffered by a user through
				the use of the theselectapp.com Website including loss of data or
				information or any kind of financial or physical loss or damage.
			</p>
			<p>
				In no event shall Theselectapp.com, nor its Owner, directors, employees,
				partners, agents, suppliers, or affiliates, be accountable for any
				indirect, incidental, special, eventful, or exemplary costs, including
				without limitation, loss of proceeds, figures, usage, goodwill, or other
				intangible losses, consequential from (i) your use or access of or
				failure to access or use the Service; (ii) any conduct or content of any
				third party on the Service; (iii) any content attained from the Service;
				and (iv) unlawful access, use or alteration of your transmissions or
				content, whether or not based on guarantee, agreement, domestic wrong
				(including carelessness) or any other lawful concept, whether or not
				we&apos;ve been aware of the possibility of such damage, and even if a
				cure set forth herein is originated to have futile of its important
				purpose.
			</p>

			<h4>17) SPAM POLICY</h4>

			<p>
				You are strictly prohibited from using the Website or any of our
				Services for illegal spam activities, including gathering email
				addresses and personal information from others or sending any mass
				commercial emails.
			</p>

			<h4>18) THIRD-PARTY LINKS &amp; CONTENT</h4>

			<p>
				We may occasionally post links to third-party websites or other
				services. You agree that we are not responsible for any loss or damage
				caused as a result of your use of any third-party services linked to or
				from Our Website.
			</p>

			<h4>19) MODIFICATION &amp; VARIATION</h4>

			<p>
				We may, from time to time and at any time without notice to you, modify
				this Agreement. You agree that we have the right to modify this
				Agreement or revise anything contained herein. You further agree that
				all modifications to this Agreement are in full force and effect
				immediately upon posting on the Website and that modifications or
				variations will replace any prior version of this Agreement unless prior
				versions are specifically referred to or incorporated into the latest
				modification or variation of this Agreement.
			</p>

			<h4>20) ENTIRE AGREEMENT</h4>

			<p>
				This Agreement constitutes the entire understanding between the Parties
				concerning any use of this Website. This Agreement supersedes and
				replaces all prior or contemporaneous agreements or understandings,
				written or oral, regarding the use of this Website.
			</p>

			<h4>21) SERVICE INTERRUPTIONS</h4>

			<p>
				We may need to interrupt your access to the Website to perform
				maintenance or emergency services on a scheduled or unscheduled basis.
				You agree that your access to the Website may be affected by
				unanticipated or unscheduled downtime, for any reason, but that we shall
				have no liability for any damage or loss caused as a result of such
				downtime.
			</p>

			<h4>22) TERM, TERMINATION &amp; SUSPENSION</h4>

			<p>
				We may terminate this Agreement with you at any time for any reason,
				with or without cause. We specifically reserve the right to terminate
				this Agreement if you violate any of the terms outlined herein,
				including, but not limited to, violating the intellectual property
				rights of us or a third party, failing to comply with applicable laws or
				other legal obligations, and/or publishing or distributing illegal
				material. If you have registered for an account with Us, you may also
				terminate this Agreement at any time by contacting us and requesting
				termination. At the termination of this Agreement, any provisions that
				would be expected to survive termination by their nature shall remain in
				full force and effect.
			</p>

			<h4>23) NO WARRANTIES</h4>

			<p>
				You agree that your use of the Website and Services is at your sole and
				exclusive risk and that any Services provided by us are on an &quot;As
				Is&quot; basis. We hereby expressly disclaim any express or implied
				warranties of any kind, including, but not limited to the implied
				warranty of fitness for a particular purpose and the implied warranty of
				merchantability. We make no warranties that the Website or Services will
				meet your needs or that the Website or Services will be uninterrupted,
				error-free, or secure. We also make no warranties as to the reliability
				or accuracy of any information on the Website or obtained through the
				Services. You agree that any damage that may occur to you, through your
				computer system, or as a result of the loss of your data from your use
				of the Website or Services is your sole responsibility and that we are
				not liable for any such damage or loss.
			</p>

			<h4>24) LIMITATION ON LIABILITY</h4>

			<p>
				We are not liable for any damages that may occur to you as a result of
				your use of the Website or Services, to the fullest extent permitted by
				law. This section applies to any claims by you, including, but not
				limited to, lost profits or revenues, consequential or punitive damages,
				negligence, strict liability, fraud, or torts of any kind.
			</p>

			<h4>25) GENERAL PROVISIONS:</h4>

			<ol>
				<li>
					<p>
						JURISDICTION, VENUE &amp; CHOICE OF LAW: The terms herein will be
						governed by and construed by the laws of UAE without giving effect
						to any principles of conflicts of law. The Courts of UAE shall have
						exclusive jurisdiction over any dispute arising from the use of the
						Website.
					</p>
				</li>
				<li>
					<p>
						ASSIGNMENT: This Agreement, or the rights granted hereunder, may not
						be assigned, sold, leased, or otherwise transferred in whole or part
						by you. Should this Agreement, or the rights granted hereunder, be
						assigned, sold, leased, or otherwise transferred by us, the rights
						and liabilities of theselectapp.com will bind and inure to any
						assignees, administrators, successors, and executors.
					</p>
				</li>
				<li>
					<p>
						SEVERABILITY: If any part or sub-part of this Agreement is held
						invalid or unenforceable by a court of law or competent arbitrator,
						the remaining parts and sub-parts will be enforced to the maximum
						extent possible. In such a condition, the remainder of this
						Agreement shall continue in full force.
					</p>
				</li>
				<li>
					<p>
						NO WAIVER: If we fail to enforce any provision of this Agreement,
						this shall not constitute a waiver of any future enforcement of that
						provision or any other provision. Waiver of any part or sub-part of
						this Agreement will not constitute a waiver of any other part or
						sub-part.
					</p>
				</li>
				<li>
					<p>
						HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under
						this Agreement are for convenience and organization, only. Headings
						shall not affect the meaning of any provisions of this Agreement.
					</p>
				</li>
				<li>
					<p>
						NO AGENCY, PARTNERSHIP, OR JOINT VENTURE: No agency, partnership, or
						joint venture has been created between the Parties as a result of
						this Agreement. No Party has any authority to bind the other to
						third parties.
					</p>
				</li>
				<li>
					<p>
						FORCE MAJEURE: We are not liable for any failure to perform due to
						causes beyond its reasonable control including, but not limited to,
						acts of God, acts of civil authorities, acts of military
						authorities, riots, embargoes, acts of nature, and natural
						disasters, and other acts which may be due to unforeseen
						circumstances, e.g., COVID-19!
					</p>
				</li>
				<li>
					<p>
						ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are
						permitted to both Parties under this Agreement, including e-mail.
						For any questions or concerns, please use the contact us form on the
						website or email us.
					</p>
				</li>
			</ol>

			<p>This document was last updated on October 6, 2022</p>
		</ContentContainer>
	);
};

export default TermsAndConditions;

const ContentContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	margin-left: -40px;
	margin-right: -40px;
	padding-left: 40px;
	padding-right: 40px;
	color: ${Colors.primary.white};
`;
