import React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import MobileIcon from '../../assets/images/MobileIcon.svg';
import EmailIcon from '../../assets/images/EmailIcon.svg';
import WhatsAppIcon from '../../assets/images/WhatsAppIcon.svg';

const ContactDetails = () => {
	return (
		<Container>
			<Row href='tel:+97142433926'>
				<IconContainer>
					<Icon src={MobileIcon} />
				</IconContainer>
				<ContactDetailContainer>
					<ContactDetail>+971 4 24 33 926</ContactDetail>
				</ContactDetailContainer>
			</Row>
			<Row href='mailto:info@theselectapp.com'>
				<IconContainer>
					<Icon src={EmailIcon} />
				</IconContainer>
				<ContactDetailContainer>
					<ContactDetail>info@theselectapp.com</ContactDetail>
				</ContactDetailContainer>
			</Row>
			<Row href=' https://wa.me/97142433926'>
				<IconContainer>
					<Icon src={WhatsAppIcon} />
				</IconContainer>
				<ContactDetailContainer>
					<ContactDetail>+971 4 24 33 926</ContactDetail>
				</ContactDetailContainer>
			</Row>
		</Container>
	);
};

export default ContactDetails;

const Container = styled.div``;

const Row = styled.a`
	text-decoration: none !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 8px 0px;
`;

const IconContainer = styled.div`
	border-radius: 8px;
	padding: 10px 16px;
	background: ${Colors.grey.darkGrey4};
	margin-right: 8px;
`;

const Icon = styled.img`
	width: 40px;
	aspect-ratio: 1/1;
`;

const ContactDetailContainer = styled.div`
	box-sizing: border-box;
	height: 64px;
	min-width: 160px;
	width: 100%;
	max-width: 180px;
	border-radius: 8px;
	padding: 19px;
	background: ${Colors.grey.darkGrey4};
`;

const ContactDetail = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: ${Colors.primary.white};

	&:visited,
	&:active {
		text-decoration: none !important;
	}
`;
