import { LoginProps } from "../../../types_and_interfaces/login";
import { MiniProfileProps } from "../../../types_and_interfaces/mini-profile";
import { UserSignUpProps } from "../../../types_and_interfaces/user-signup";
import { get, post } from "./api";

export const GetCheckinQRCode = async (userId : string, subcriptionId : string) => {
  try {
    const response = await get(
        "origin2",
        "B2BMiniProfile/getCheckinQRCode/"+userId+"/"+subcriptionId,
        false
      );
      return response;
  } catch (error) {
    console.log(error);
  }
};

export const CancelGoWellGymMembership = async (userId : string, subcriptionId : string) => {
    try {
      const response = await get(
          "origin2",
          "B2BMiniProfile/CancelGoWellGymMembership/"+userId+"/"+subcriptionId,
          false
        );
        return response;
    } catch (error) {
      console.log(error);
    }
  };