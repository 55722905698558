import React, {useEffect, useState} from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomPasswordInput from '../../components/CustomInput/CustomPasswordInput';
import { Colors } from '../../utils/configs/Colors';
import TextButton from '../../components/CustomButtons/TextButton';
import Spacer from '../../components/Spacer/Spacer';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import styled from 'styled-components';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { useLocation, useNavigate } from 'react-router-dom';
import Title from '../../components/Title/Title';
import { ContextConstants } from '../../utils/services/storage/constants';
import { CurrentPasswordProps, CurrentPasswordPropsPattern, CurrentPasswordPropsValid } from '../../types_and_interfaces/current-password';
import ErrorText from '../../components/CustomText/ErrorText';
import { VerifyCurrentPassword } from '../../utils/services/api/recovery';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';
import { CommonEncryption } from '../../utils/helper/commonEncryption';

const EnterCurrentPassword = () => {

	const { state } = useLocation();

	const { addToast, updateFlow } = useCommonContext();

	useEffect(() => {
		updateFlow(FlowTypes.UpdatePassword);
	}, []);

    const [formData, setFormData] = useState<CurrentPasswordProps>({
		email: state?.email,
		currentPassword: ''
	});

	const [formDataValidState, setFormDataValidState] =
		useState<CurrentPasswordPropsValid>({
			currentPassword: false,
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'currentPassword':
				reg = CurrentPasswordPropsPattern.currentPassword;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: CurrentPasswordProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		setEnableSubmit(false);
		let submitFormData = {...formData};
		submitFormData.currentPassword =  CommonEncryption(formData.currentPassword);
		VerifyCurrentPassword(submitFormData).then((data) => {
			console.log(data);
			setEnableSubmit(true);
			if(data.isSuccess){
				updateFlow(FlowTypes.UpdatePassword);
				goToRecoverPassword();
			}else{
				addToast({
					type: ToastTypes.Error,
					message: data.message,
				});
			}
		}).catch((err)=>{
			setEnableSubmit(true);
			console.log(err);
			goToError();
		});
	};

    const navigate = useNavigate();

    const goToRecoverPassword = ()=> {
        navigate('/enterYourEmailChangePwd', {state: {email: state?.email}});
    }

	const goToPreviouse=()=>{
		navigate(-1);
	}

	const goToError = () => {
		navigate('/errorChangePwd');
	}
    
	return (
		<PageWrapper>
			<LogoHeader />
            <Title
				title='Enter your current password'
				color={Colors.primary.white}
			/>
			<CustomPasswordInput
				enablePasswordToggle
				disableErrorBox={true}
				value={formData.currentPassword}
				onChange={(event) => handleChange(event.target.value, 'currentPassword')} error={!formDataValidState.currentPassword && formData.currentPassword !== ''}
			/>
            <Row>
                <TextButton color={Colors.primary.red} width={'max-content'} padding={'unset'}  onClick={() => {updateFlow(FlowTypes.ForgotPassword); navigate('/recoverPassword')}} >Forgot password?</TextButton>
            </Row>
            <Spacer space={{ x: '100%', y: '100%' }} />
            <PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Continue</PrimaryButton>
            <SwitcherContainer>
				<SwitcherLink onClick={goToPreviouse}>&nbsp;Go back</SwitcherLink>
			</SwitcherContainer>
            <Spacer space={{ x: '100%', y: '75px' }} />
            <GoWellFooter />
		</PageWrapper>
	);
};

export default EnterCurrentPassword;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;

const Row=styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
