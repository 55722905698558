export const StorageConstants = {
	token: 'user_token',
	compress_token: 'compressed_token',
	flow: 'app_flow',
};

export const ContextConstants = {
	success: 'Success',
	recoveryPwdPath: '/recoverPassword',
	recoveryPwd: 'recovery_password',
	changePwdPath: '/changePassword',
	changePwd: 'change_password',
	emailOtp: 'email_otp',
	mobileChange: 'mobile_number_change',
	mobileOtp: 'mobile_number_otp',
	changeMobilePath: '/changeMobile',
	changeMobileFlow: 'change_mobile_flow',
	setMobileOtp: 'set_as_mobile_otp'
};