import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/mobile';
import { customInputProps } from '../../types_and_interfaces/custom-input';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import ErrorText from '../CustomText/ErrorText';

const CustomPhoneInput = (props: customInputProps) => {
	const {
		title,
		value,
		onChange = (_value: any) => {},
		inputProps,
		error,
		errorComp,
		...rest
	} = props;
	return (
		<Container>
			<TitleContainer>
				<TitleText>{title}</TitleText>
			</TitleContainer>
			<Input
				{...inputProps}
				// inputComponent={(...props: any[]) => <NestedInput {...props} />}
				defaultCountry='AE'
				countries={["AE"]}
				addInternationalOption={false}
				value={value}
				onChange={(value: any) => onChange(value || null)}
				error={!!error}
				{...rest}
				maxLength="18"
			/>
			{/* <ErrorText>{value}</ErrorText> */}
			{(error || !isValidPhoneNumber(value) || !(""+value).startsWith('+971') ) ? (errorComp ? errorComp : null) : null}
		</Container>
	);
};

export default CustomPhoneInput;

const Container = styled.div`
	width: 100%;
	margin-bottom: 8px;
`;

const TitleContainer = styled.div`
	margin-bottom: 6px;
`;

const TitleText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const Input = styled(PhoneInput)<{ error?: boolean }>`
	background: ${Colors.primary.white};
	border: 1px solid
		${(props) => (props.error ? Colors.primary.red : Colors.secondary.grey)};
	border-radius: 5px;
	padding: 12px 16px;
	width: -webkit-fill-available;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	& input {
		width: -webkit-fill-available;
		border: 0px transparent;
		background: unset !important;
		background-color: unset !important;
		accent-color: unset !important;
	}

	& input:focus {
		outline: none;
	}
`;
