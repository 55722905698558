import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CustomAccordion from '../CustomAccordian/CustomAccordian';
import { Colors } from '../../utils/configs/Colors';
import CreditCardIcon from '../../assets/images/CreditCardIcon.svg';
import Spacer from '../Spacer/Spacer';
import PrimaryButton from '../CustomButtons/PrimaryButton';
import { MiniProfileProps } from '../../types_and_interfaces/mini-profile';
import {
	CollectPayment,
	GetUpdatePaymentMethod,
} from '../../utils/services/api/signup';
import { error } from 'console';
import { useCommonContext } from '../../context/CommonContext';
import { ToastTypes } from '../../types_and_interfaces/common-context';
import moment from 'moment';
import { Duration, Moment } from 'moment';
import ContinuePaymentConfirmationPopup from '../../screens/MiniProfile/ContinuePaymentConfirmationPopup';
import PaymentMethodFailedPopup from '../Popup/PaymentMethodFailedPopup';
import { useLocation, useNavigate } from 'react-router-dom';


const BillingDetails = (props: any) => {
	const {
		CurrencyCode,
		StartDate,
		NextBillingDate,
		OutstandingAmount,
		UserId,
		OutstandingInvoiceIds,
		updateMiniProfile,
		email
	} = props;
	const { addToast } = useCommonContext();

	const [duration, setDuration] = useState('')

	const navigate = useNavigate();

	const [paymentConfirmPopupState, setPaymentConfirmPopupState] = useState<boolean>(false);

	const [paymentFailedState, setPaymentFailedState] = useState<boolean>(false);

	useEffect(() => {
		setDuration(NextBillingDate ? moment(NextBillingDate, 'DD/MM/YYYY').fromNow() : "N/A");
	}, [NextBillingDate])

	const getUpdatePaymentMethod = (e: { preventDefault: () => void }) => {
		GetUpdatePaymentMethod(UserId).then((data) => {
			window.location.href = data?.url;
		});
	};

	const collectPayment = (e?: { preventDefault: () => void }) => {
		CollectPayment(UserId, OutstandingInvoiceIds, OutstandingAmount)
			.then((data) => {
				if (!data) {
					// addToast({
					// 	type: ToastTypes.Error,
					// 	message: 'Error on payment collection, try again.',
					// 	autoHide: true,
					// 	autoHideTime: 5000,
					// });
					openPaymentFailedPopup();
					// updateMiniProfile();
				} else {
					updateMiniProfile();
					// addToast({
					// 	type: ToastTypes.Success,
					// 	message: 'Payment collection successfull.',
					// 	autoHide: true,
					// 	autoHideTime: 5000,
					// });
					goToPaymentSuccessPage();
				}
			})
			.catch((error) => {
				console.log(error);
				openPaymentFailedPopup();
			});
	};

	const openPaymentConfirmPopup = () => {
		setPaymentConfirmPopupState(true);
	};

	const closePaymentConfirmPopup = () => {
		setPaymentConfirmPopupState(false);
	};

	const continuePayment = () => {
		closePaymentConfirmPopup();
		collectPayment();
	}

	const openPaymentFailedPopup = () => {
		setPaymentFailedState(true);
	}

	const closePaymentFailedPopup = () => {
		setPaymentFailedState(false);
	}

	const goToPaymentSuccessPage = () => {
		navigate('/paymentSuccessMiniProfile?email=' + email);
	}

	return (
		<Container>
			<CustomAccordion.Container>
				<CustomAccordion.Summary>
					<TitleContainer>
						<Icon src={CreditCardIcon} />
						<Title>Billing Details</Title>
					</TitleContainer>
				</CustomAccordion.Summary>
				<CustomAccordion.Details>
					<Row>
						<Left>
							<Text>Start Date</Text>
						</Left>
						<Right>
							<Text>{StartDate}</Text>
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '14px' }} />
					<Row>
						<Left>
							<Text>Next Billing Date</Text>
						</Left>
						<Right>
							{NextBillingDate &&
								<>
									<Text>{NextBillingDate}</Text>
									<SubText>{duration}</SubText>
								</>
							}
							{!NextBillingDate &&
								<>
									<Text>N/A</Text>
								</>
							}
						</Right>
					</Row>

					<Spacer space={{ x: '100%', y: '14px' }} />
					<Row>
						<Left>
							<Text>Outstanding Amount</Text>
						</Left>
						<Right>
							{OutstandingAmount == "0.00" &&
								<Text>
									{' '}
									{CurrencyCode} {OutstandingAmount}
								</Text>
							}
							{OutstandingAmount != "0.00" &&
								<ErrorText>
									{' '}
									{CurrencyCode} {OutstandingAmount}
								</ErrorText>
							}
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '24px' }} />
					<PrimaryButton
						background={Colors.grey.darkGrey3}
						color={Colors.primary.white}
						onClick={getUpdatePaymentMethod}>
						<ButtonText>Update Payment Method</ButtonText>
					</PrimaryButton>
					<Spacer space={{ x: '100%', y: '16px' }} />
					<PrimaryButton
						hidden={+OutstandingAmount <= 0}
						// hidden={OutstandingInvoiceIds.length == 0}
						background={Colors.grey.darkGrey3}
						color={Colors.primary.white}
						onClick={openPaymentConfirmPopup}>
						<ButtonText>Pay Now</ButtonText>
					</PrimaryButton>
				</CustomAccordion.Details>
			</CustomAccordion.Container>
			<ContinuePaymentConfirmationPopup
				show={paymentConfirmPopupState}
				confirmAction={continuePayment}
				cancelAction={closePaymentConfirmPopup}
				amount={OutstandingAmount}
			/>
			<PaymentMethodFailedPopup
				show={paymentFailedState}
				hide={closePaymentFailedPopup}
			/>
		</Container>
	);
};

export default BillingDetails;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
	background-color: ${Colors.grey.darkGrey1};
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const Icon = styled.img`
	margin-right: 25px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const Text = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.white};
`;

const SubText = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${Colors.grey.darkGrey5};
`;

const ErrorText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.red};
`;

const ButtonText = styled.span`
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.secondary.offGrey};
`;
