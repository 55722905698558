import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomPasswordInput from '../../components/CustomInput/CustomPasswordInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { useLocation, useNavigate } from 'react-router-dom';
import { Colors } from '../../utils/configs/Colors';
import styled from 'styled-components';
import Spacer from '../../components/Spacer/Spacer';
import { NewPasswordProps, NewPasswordPropsValid } from '../../types_and_interfaces/new-password';
import ErrorText from '../../components/CustomText/ErrorText';
import { ChangePassword } from '../../utils/services/api/recovery';
import { ContextConstants } from '../../utils/services/storage/constants';
import { ChangePasswordProps } from '../../types_and_interfaces/change-password';
import { useCommonContext } from '../../context/CommonContext';
import { ToastTypes } from '../../types_and_interfaces/common-context';

const CreateNewPassword = () => {
	const [formData, setFormData] = useState<NewPasswordProps>({
		NewPassword: '',
		ConfirmNewPassword: ''
	});

	const { addToast } = useCommonContext();

	const [changePasswordData, setChangePasswordData] = useState<ChangePasswordProps>({});

	const {state} = useLocation();

	console.log(state);

	const [formDataValidState, setFormDataValidState] =
	useState<NewPasswordPropsValid>({
		NewPassword: false,
		ConfirmNewPassword: false
	});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);

		let changePwd = {...formData};
		setChangePasswordData({
			Email: state?.email,
			EmailVerificationToken: state?.recoveryToken,
			NewPassword: changePwd.NewPassword
		});
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: NewPasswordProps) => ({
			...current,
			[target]: value || '',
		}));
		if(target === 'ConfirmNewPassword'){
				setFormDataValidState((current) => ({
				...current,
				[target]: value === formData.NewPassword,
			}));
		}else{
			setFormDataValidState((current) => ({
				...current,
				ConfirmNewPassword: value === formData.ConfirmNewPassword,
			}));
		}
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		setEnableSubmit(false);
		ChangePassword(changePasswordData).then((data) => {
			console.log(data);
			setEnableSubmit(true);
			if(data.isSuccess){
				goToChangeConfirmation();
			}
			else{
				addToast({
					type: ToastTypes.Error,
					message: data.message,
				});
			}
		}).catch((err)=>{
			console.log(err);
			goToError();
			// setEnableSubmit(true);
			// addToast({
			// 	type: ToastTypes.Error,
			// 	message: "Error occured when change the password",
			// });
		});
	};

	const goToError = () => {
		navigate('/errorChangePwd');
	}
    

	const navigate = useNavigate();

	const goToChangeConfirmation = () => {
		navigate('/createdNewPassword', {state: {opiton: state?.option, email: state?.email}});
	};

	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title='Create new password'
				color={Colors.primary.white}
			/>
			<CustomPasswordInput
				title='New password'
				enablePasswordToggle
				value={formData.NewPassword}
					onChange={(event) => handleChange(event.target.value, 'NewPassword')}
					getErrorState={(state) => {
						setFormDataValidState((current) => ({
							...current,
							NewPassword: !state,
						}));
					}}
			/>
			<CustomPasswordInput
				title='Confirm new password'
				enablePasswordToggle
				disableErrorBox={true}
				value={formData.ConfirmNewPassword}
					onChange={(event) => handleChange(event.target.value, 'ConfirmNewPassword')}
			/>
			{!formDataValidState.ConfirmNewPassword && formData.ConfirmNewPassword !== '' ? <ErrorText>Password and confirmation are different</ErrorText> : null}
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Continue</PrimaryButton>
			<SwitcherContainer>
				<SwitcherLink onClick={()=>{navigate(-1)}}>&nbsp;Go Back</SwitcherLink>
			</SwitcherContainer>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default CreateNewPassword;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
