import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Spacer from '../../components/Spacer/Spacer';
import { styled } from 'styled-components';
import SadIcon from '../../assets/images/SadIcon.svg';
import Title from '../../components/Title/Title';
import { Colors } from '../../utils/configs/Colors';
import CustomText from '../../components/CustomText/CustomText';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { useNavigate } from 'react-router';

const MembershipCancelSuccess = () => {

	const navigate = useNavigate();
	
	const goToMiniProfile = () => {
		navigate('/miniProfile?email=user')
	}

	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '38px' }} />
			<Loader src={SadIcon} />
			<Spacer space={{ x: '100%', y: '22px' }} />
			<Title
				title='We are sad to see you go'
				color={Colors.primary.white}
			/>
			<CustomText
				text='Your membership has been canceled, and will not renew for the next billing term.'
				alignment='center'
			/>
			<CustomText
				text='You can still use your benefits until the end of your current billing period.'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '193px' }} />
			<SwitcherContainer>
				<SwitcherLink onClick={goToMiniProfile}>&nbsp;Go Back to Profile
				</SwitcherLink>
			</SwitcherContainer>
			<Spacer space={{ x: '100%', y: '74px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default MembershipCancelSuccess;

const Loader = styled.img`
	width: 178px;
	aspect-ratio: 1/1;
	margin-left: auto;
	margin-right: auto;
`;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
