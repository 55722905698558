import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import Spacer from '../../components/Spacer/Spacer';
import Title from '../../components/Title/Title';
import { Colors } from '../../utils/configs/Colors';
import CustomText from '../../components/CustomText/CustomText';
import CustomOTPInput from '../../components/CustomInput/CustomOTPInput';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import TextButton from '../../components/CustomButtons/TextButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserSignUpOtpPropsValid, UserSignUpProps, UserSignUpPropsPattern } from '../../types_and_interfaces/user-signup';
import { SendMobileOtp, SendMobileOtpSignupflow } from '../../utils/services/api/signup';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';
import { AddNewMobileNumber, VerifyMobileOtp, VerifyMobileOtpSignUp } from '../../utils/services/api/recovery';
import { ContextConstants } from '../../utils/services/storage/constants';
import ErrorText from '../../components/CustomText/ErrorText';
import { NewMobileOtp } from '../../types_and_interfaces/new-mobile';

const MobileConfirmationChangeMobile = () => {
	const search = useLocation().search;

	const userId = new URLSearchParams(search).get('userId') || '';

	const { addToast, flow } = useCommonContext();

	const { state } = useLocation();
	const initialized = useRef(false);

	const [sendAgainText, setSendAgaintext] = useState(true)
	const [timer, setTimer] = useState(30);
	const [timerLabel, setTimerLabel] = useState("30");


	const [formData, setFormData] = useState<UserSignUpProps>({
		UserId: userId,
		FirstName: '',
		LastName: '',
		MobileNumber: '',
		Email: state?.email,
		Password: '',
	});

	const [formDataValidState, setFormDataValidState] =
		useState<UserSignUpOtpPropsValid>({
			OTP: false,
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'OTP':
				reg = UserSignUpPropsPattern.OTP;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		if (state?.token !== '' && flow !== FlowTypes.UpdateMobile) {
			if (!initialized.current) {
				initialized.current = true
				sendSmsOtp();
			}
		}
	}, []);

	useEffect(() => {
		const updateInterval = setInterval(() => {
			if (timer === 30) {
				setTimerLabel("30");
				setTimer(timer - 1);
			} else if (timer === 0) {
				setSendAgaintext(false);
				clearInterval(updateInterval)
			} else {
				setTimerLabel(`${timer}`);
				setTimer(timer - 1);
			}
		}, 1000);
		return () => clearInterval(updateInterval);
	}, [timer]);

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);

		// let otp = { ...formData };
		// setRecoveryData({ otp: otp.OTP, Email: state.email });
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: UserSignUpProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		let formDataObj = {...formData};
			let newMobileObj = {
				email: state?.email,
				newMobile: state?.mobileNumber,
				mobileVerificationToken: state?.recoveryToken,
				otp: formDataObj?.OTP
			} as NewMobileOtp;

			AddNewMobileNumber(newMobileObj).then((data) => {
				if (data.isSuccess) {
					goToMobileChangeSuccess();
				} else {
					addToast({
						type: ToastTypes.Error,
						message: 'The entered OTP is invalid. Please try again, or click Send Again for a new OTP.',
					});
				}
			}).catch(err => {
                console.log(err);
                goToError();
            });
	};

	const navigate = useNavigate();

	const goToCreateNewPassword = () => {
		if (state?.token !== '') {
			navigate('/createNewPassword', { state: { option: ContextConstants.recoveryPwd, email: state?.email, recoveryToken: state.recoveryToken } });
		}
	};

	const goToProfilePicture = () => {
		navigate('/addProfilePhoto?userId=' + userId);
	};

	const goToMobileChangeSuccess = () =>{
		navigate('/changedMobileNumber', { state: { email: state?.email, mobileNumber: state?.mobileNumber, recoveryToken: state.recoveryToken } } );
	}

	const goBack = () => {
		navigate(-1);
	};

    const goToError=()=>{
		navigate('/errorChangeMobile');
	}

	const sendSMSOTPAgain = () => {
		setTimerLabel("30");
		setTimer(30);
		setSendAgaintext(true);
		sendSmsOtp();
	}

	const sendSmsOtp = () => {
		console.log(flow);
		console.log(state);
		if (flow === 'SignUp') {
			SendMobileOtpSignupflow(formData).then((response) => {
				console.log(response);
				if (!response?.isSuccess) {
					// addToast({
					// 	type: ToastTypes.Error,
					// 	message: "Failed to send SMS OTP",
					// })
				}
			});
		} else {

			SendMobileOtp(formData).then((response) => {
				console.log(response);
				if (!response?.isSuccess) {
					// addToast({
					// 	type: ToastTypes.Error,
					// 	message: "Failed to send SMS OTP",
					// })
				}
			});
		}
	}


	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title='One-Time-Password'
				color={Colors.primary.white}
			/>
			<CustomText
				text={'A One-Time-Password has been sent to your mobile number ending with *******' + state?.mobileNumber?.substr(state.mobileNumber.length - 3) + '.  Please confirm this number to verify your account.'}
				alignment='left'
			/>
			<Spacer space={{ x: '100%', y: '40px', yMin: '40px' }} />
			<CustomOTPInput
				title='Enter code'
				value={formData.OTP}
				onChange={(event) => handleChange(event, 'OTP')} error={!formDataValidState.OTP && !['', null, undefined].includes(formData?.OTP)}
				errorComp={
					<ErrorText>The entered OTP is invalid. Please try again, or click Send Again for a new OTP.</ErrorText>
				}
			/>
			<Spacer space={{ x: '100%', y: '40px', yMin: '40px' }} />
			<CustomText
				text={
					<>
						Didn{`'`}t receive OTP?{' '}{sendAgainText ? ('Send again in (' + timerLabel + ')') : ''}
						{!sendAgainText && <TextButton
							onClick={sendSMSOTPAgain}
							width='unset'
							padding='unset'
							color={Colors.primary.red}>
							Send again
						</TextButton>}
					</>
				}
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Continue</PrimaryButton>
			<Spacer space={{ x: '100%', y: '24px' }} />
			<TextButton
				onClick={goBack}
				color={Colors.primary.red}>
				Go Back
			</TextButton>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default MobileConfirmationChangeMobile;
