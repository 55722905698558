import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import Topbar from '../../components/Topbar/Topbar';
import LogoHeader from '../../components/LogoHeader/LogoHeader';

const AccountSupport = () => {
	return (
		<PageWrapper>
            <LogoHeader />
			<Spacer space={{ x: '100%', y: '48px' }} />
			<Title
				title='Oops'
				color={Colors.primary.white}
			/>
			<Spacer space={{ x: '100%', y: '40px' }} />
			<CustomText
				text='Looks like you need some help regarding your account. Please contact customer support using any of the below contact methods.'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '49px' }} />
			<ContactDetails />
			<Spacer space={{ x: '100%', y: '579px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default AccountSupport;
