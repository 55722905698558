import React, { useState } from 'react';
import { styled } from 'styled-components';
import CustomAccordion from '../CustomAccordian/CustomAccordian';
import { Colors } from '../../utils/configs/Colors';
import MembershipIcon from '../../assets/images/MembershipIcon.svg';
import Spacer from '../Spacer/Spacer';
import PrimaryButton from '../CustomButtons/PrimaryButton';
import CancelMembershipPopup from '../Popup/CancelMembershipPopup';
import { CancelGoWellGymMembership } from '../../utils/services/api/miniProfile';
import { useNavigate } from 'react-router-dom';
import PaymentMethodFailedPopup from '../Popup/PaymentMethodFailedPopup';

const MembershipDetails = (props: any) => {
	const {
		CurrencyCode,
		MembershipName,
		MonthlyBilling,
		UserId,
		SubcriptionId,
		NextBillingDate
	} = props;
	const navigate = useNavigate();

	const [paymentFailedState, setPaymentFailedState] = useState<boolean>(false);

	const [popupState, setPopupState] = useState<boolean>(false);

	const openPopup = () => {
		setPopupState(true);
	};

	const closePopup = () => {
		setPopupState(false);
	};

	const cancelMebership = () => {
		CancelGoWellGymMembership(UserId, SubcriptionId).then((res) => {
			if (res == true) {
				navigate('/membershipCancelSuccess');
			} else {
				openPaymentFailedPopup();

			}
		});
		closePopup();
	};

	const openPaymentFailedPopup = () => {
		setPaymentFailedState(true);
	}

	const closePaymentFailedPopup = () => {
		setPaymentFailedState(false);
		window.location.reload();
	}

	return (
		<Container>
			<CustomAccordion.Container>
				<CustomAccordion.Summary>
					<TitleContainer>
						<Icon src={MembershipIcon} />
						<Title>Membership Details</Title>
					</TitleContainer>
				</CustomAccordion.Summary>
				<CustomAccordion.Details>
					<Row>
						<Left>
							<Text>Name</Text>
						</Left>
						<Right>
							<Text>{MembershipName}</Text>
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '14px' }} />
					<Row>
						<Left>
							<Text>Monthly Billing</Text>
						</Left>
						<Right>
							<Text>
								{CurrencyCode} {MonthlyBilling}
							</Text>
						</Right>
					</Row>
					<Spacer space={{ x: '100%', y: '24px' }} />
					{MembershipName && NextBillingDate && (
						<PrimaryButton
							onClick={openPopup}
							background={Colors.grey.darkGrey3}
							color={Colors.primary.white}>
							<ButtonText>Cancel Membership</ButtonText>
						</PrimaryButton>
					)}
				</CustomAccordion.Details>
			</CustomAccordion.Container>
			<CancelMembershipPopup
				show={popupState}
				confirmAction={cancelMebership}
				cancelAction={closePopup}
			/>
			<PaymentMethodFailedPopup
				show={paymentFailedState}
				hide={closePaymentFailedPopup}
			/>
		</Container>
	);
};

export default MembershipDetails;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
	background-color: ${Colors.grey.darkGrey1};
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const Title = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: -0.408px;
	color: ${Colors.primary.white};
`;

const Icon = styled.img`
	margin-right: 25px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const Text = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.white};
`;

const ButtonText = styled.span`
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.secondary.offGrey};
`;
