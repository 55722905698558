/* eslint-disable no-useless-escape */
export interface CurrentPasswordProps {
	currentPassword: string;
	email: string;
}

export interface CurrentPasswordPropsValid {
	currentPassword: boolean;
}

export const CurrentPasswordPropsPattern = {
	currentPassword: /([A-z0-9\@\#\$\%\&\*\!]){6,}/,
};
