import { styled } from 'styled-components';
import { spacerProps } from '../../types_and_interfaces/spacer';

const Spacer = styled.div<spacerProps>`
	display: inline-block;
	${(props) => (props.space.x ? 'width: ' + props.space.x + ';' : '')}
	${(props) => (props.space.xMin ? 'min-width: ' + props.space.xMin + ';' : '')}
	${(props) => (props.space.xMax ? 'max-width: ' + props.space.xMax + ';' : '')}
	${(props) => (props.space.y ? 'height: ' + props.space.y + ';' : '')}
	${(props) => (props.space.yMin ? 'min-height: ' + props.space.yMin + ';' : '')}
	${(props) => (props.space.yMax ? 'max-height: ' + props.space.yMax + ';' : '')}
`;

export default Spacer;
