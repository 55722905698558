import DubaiBanner from '../../assets/images/DubaiBanner.png';
import AbhuDabiBanner from '../../assets/images/AbuDhabiBanner.png';
import OtherCityBanner from '../../assets/images/OtherCitiesBanner.png';

export const VenueList = [
	{
		city: 'Dubai',
		noOfGyms: '19 gyms',
		banner: DubaiBanner,
		locationList: [
			{item: 'UFC Gym', gyms: [
			'UFC: JBR',
			'UFC: Once Central',
			'UFC: Business bay',
			'UFC: Mirdif',
			'UFC: Silicon Oasis']},
			{item: 'Metrofitt', gyms: ['Metrofitt: Al Ghurair Center', 'Metrofitt: DAFZA', 'Metrofitt: SZR', 'Metrofitt: Jumeirah 1', 'Metrofitt: Business Village']},
			{item: 'Prime Fitness', gyms: ['Prime Fitness: Damac JVC',
			'Prime Fitness: Sandhurst JVC']},
			{item: 'Fitness 101: Meydan', gyms: []},
			{item: 'Max Burn: Al Quoz', gyms: []},
			{item: '247 Gym: Our Metha', gyms: []},
			{item: 'AB Fitness: Mina Rashid', gyms: []},
			{item: 'Level X : Jaddaf', gyms: []},
			{item: 'Iron Street Gym: Garhoud', gyms: []},
			{item: 'Movenpick: JLT', gyms: []},
		],
		itemList: [
			'247 Gym - Our Metha',
			'AB Fitness - Mina Rashid',
			'Fitness 101 - Meydan',
			'Iron Street Gym - Garhoud',
			'Level X  - Jaddaf',
			'Max Burn - Al Quoz',
			'Metrofitt - Al Ghurair Center',
			'Metrofitt - Business Village',
			'Metrofitt - DAFZA',
			'Metrofitt - Jumeirah 1',
			'Metrofitt - SZR',
			'Movenpick JLT - JLT',
			'Prime Fitness - Damac JVC',
			'Prime Fitness - Sandhurst JVC',
			'UFC - Business bay',
			'UFC - JBR',
			'UFC - Mirdif',
			'UFC - Once Central',
			'UFC - Silicon Oasis'
		],
	},
	{
		city: 'Abu Dhabi',
		noOfGyms: '5 gyms',
		banner: AbhuDabiBanner,
		locationList: [
			{item: 'UFC', gyms: ['UFC - MBZ City',
			'UFC - Maqtaa Mall']},
			{item: 'Metrofitt - Bawabat Al Sharq', gyms: []},
			{item: 'Adrenagy - Khalifah Park', gyms: []},
			{item: 'Body Move - Al Zahiyah', gyms: []},
		],
		itemList: [
			'Adrenagy - Khalifah Park',
			'Body Move - Al Zahiyah',
			'Metrofitt - Bawabat Al Sharq',
			'UFC - MBZ City',
			'UFC - Maqtaa Mall'
		],
	},
	{
		city: 'Other cities',
		noOfGyms: '3 gyms',
		banner: OtherCityBanner,
		locationList: [
			{item: 'Olympia Gym: University City Sharjah', gyms: []},
			{item: 'Toronto Star Bodybuilding: Al Nasiriyah Sharjah', gyms: []},
			{item: 'Infinity Fitness Club: Grand Mall Ajman', gyms: []},
		],
		itemList: [
			'Infinity Fitness Club - Grand Mall Ajman',
			'Olympia Gym - University City Sharjah',
			'Toronto Star Bodybuilding - Al Nasiriyah Sharjah'
		],
	},
	
];
