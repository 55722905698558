import React from 'react';
import { ToastComponentProps } from '../../types_and_interfaces/toast-component';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import {
	ToastTypes,
	ToastTypesType,
} from '../../types_and_interfaces/common-context';
import CloseIcon from '@mui/icons-material/Close';

const Toast = (props: ToastComponentProps) => {
	const { show = false, hide = () => {}, message = '', type } = props;

	return (
		<Wrapper
			show={show}
			type={type}>
			<Text>{message}</Text>
			<CloseButton onClick={hide} />
		</Wrapper>
	);
};

export default Toast;

const generateToastColor = (type: ToastTypesType = ToastTypes.Error) => {
	switch (type) {
		case ToastTypes.Success:
			return Colors.system.success;
		case ToastTypes.Error:
		default:
			return Colors.primary.red;
	}
};

const Wrapper = styled.div<{ show?: boolean; type?: ToastTypesType }>`
	display: flex;
	position: absolute;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	gap: 13px;
	top: ${(props) => (props?.show ? '0vh' : '-100vh')};
	left: 0;
	width: 100%;
	box-sizing: border-box;
	background-color: ${(props) => generateToastColor(props.type)};
	z-index: 100;
	border-radius: 8px;
	padding: ${(props) => (props?.show ? '12px 16px' : 'unset')};
	overflow: hidden;
	animation-name: ${(props) => (props?.show ? 'expand' : 'unset')};
	animation-duration: ${(props) => (props?.show ? '0.55s' : 'unset')};
	transition: 0.55s ease;

	@keyframes expand {
		0% {
			height: 0px;
			min-height: 0px;
			max-height: 0px;
		}

		100% {
			height: auto;
			min-height: auto;
			max-height: auto;
		}
	}
`;

const Text = styled.div`
	color: ${Colors.primary.white};
	font-size: 14px;
`;

const CloseButton = styled(CloseIcon)`
	color: ${Colors.primary.white};
	font-size: 30px;
`;
