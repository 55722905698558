import { post } from './api';
import { UploadProfilePictureProps } from '../../../types_and_interfaces/upload-profile-picture';

export const uploadProfilePicture = async (data: UploadProfilePictureProps) => {
	try {
		let response = await post('origin1', 'b2buser/document/upload', data);
	} catch (error) {
		console.log(error);
	}
};
