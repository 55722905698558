import React from 'react';
import { styled } from 'styled-components';
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined';
import WarningIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Colors } from '../../utils/configs/Colors';
import { profilePicturePreviewBoxProps } from '../../types_and_interfaces/profile-picture-preview-box';

const ProfilePicturePreviewBox = (props: profilePicturePreviewBoxProps) => {
	const { image, error } = props;

	if (!!image && !error) {
		return (
			<Wrapper>
				<ProfileImage src={image} />
			</Wrapper>
		);
	}

	return (
		<>
			<Wrapper>
				<CameraIcon />
				<CriteriaText error={error}>20 MB max</CriteriaText>
				<CriteriaText error={error}>1200x1600 px max</CriteriaText>
			</Wrapper>
			{error && (
				<ErrorText>
					<ErrorIcon />
					Please choose a photo that meets our requirements
				</ErrorText>
			)}
		</>
	);
};

export default ProfilePicturePreviewBox;

const Wrapper = styled.div`
	background: ${Colors.grey.darkGrey3};
	border-radius: 8px;
	width: 176px;
	height: 126px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 18px 14px;
	aspect-ratio: 245 / 245;
`;

const ProfileImage = styled.img`
	border-radius: 8px;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const CameraIcon = styled(CameraAltOutlined)`
	font-size: 48px !important;
	color: ${Colors.secondary.grey};
	margin-bottom: 14px;
`;

const CriteriaText = styled.div<{ error?: boolean }>`
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: ${(props) =>
		props.error ? Colors.primary.red : Colors.grey.lightGrey2};
	margin: 2px;
`;

const ErrorText = styled.div`
	font-size: 12px;
	color: ${Colors.primary.red};
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const ErrorIcon = styled(WarningIcon)`
	color: ${Colors.primary.red};
	font-size: 16px;
	margin-right: 8px;
`;
