import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Spacer from '../../components/Spacer/Spacer';
import { styled } from 'styled-components';
import LogoLoadingIcon from '../../assets/images/GoWellLoader.svg';
import GoWellLogoLarge from '../../assets/images/GoWellLogoLarge.svg';
import Loader1 from '../../assets/images/loader1.svg';
import Loader2 from '../../assets/images/loader2.svg';
import Loader3 from '../../assets/images/loader3.svg';
import Loader4 from '../../assets/images/loader4.svg';
import Loader5 from '../../assets/images/loader5.svg';
import Loader6 from '../../assets/images/loader6.svg';

const Loading = () => {
	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '297px' }} />
			<Logo src={GoWellLogoLarge} />
			<Spacer space={{ x: '100%', y: '30px', yMin: '30px', yMax: '30px' }} />
			<Loader />
			<Spacer space={{ x: '100%', y: '370px' }} />
		</PageWrapper>
	);
};

export default Loading;

const Logo = styled.img`
	width: 190px;
	aspect-ratio: 190/45;
	margin-left: auto;
	margin-right: auto;
`;

const Loader = styled.div`
	width: 82px;
	aspect-ratio: 82/10;
	margin-left: auto;
	margin-right: auto;
	background-image: url(${Loader1});
	animation-name: animate;
	animation-duration: 0.8s;
	animation-iteration-count: infinite;

	@keyframes animate {
		0% {
			background-image: url(${Loader1});
		}
		20% {
			background-image: url(${Loader2});
		}
		40% {
			background-image: url(${Loader3});
		}
		60% {
			background-image: url(${Loader4});
		}
		80% {
			background-image: url(${Loader5});
		}
		100% {
			background-image: url(${Loader6});
		}
	}
`;
