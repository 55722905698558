import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import styled from 'styled-components';
import ErrorIcon from '../../assets/images/ErrorTechIssue.svg';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import BorderButton from '../../components/CustomButtons/BorderButton';
import Topbar from '../../components/Topbar/Topbar';
import { useNavigate } from 'react-router-dom';

const ErrorTechIssue = () => {
	const navigate = useNavigate();
	
	const goBack = () => {
		navigate('/');
	};

	return (
		<PageWrapper>
			<Topbar />
			<Spacer space={{ x: '100%', y: '55px' }} />
			<IconContainer>
				<Icon src={ErrorIcon} />
			</IconContainer>
			<Spacer space={{ x: '100%', y: '36px' }} />
			<Title>Oops!</Title>
			<Spacer space={{ x: '100%', y: '24px' }} />
			<CustomText
				text='We have run into a technical issue. Please try again later.'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '114px' }} />
			<PrimaryButton onClick={goBack}>Try again</PrimaryButton>
			<Spacer space={{ x: '100%', y: '24px' }} />
			<BorderButton
				background={Colors.primary.black}
				borderColor={Colors.primary.white} onClick={goBack}>
				Cancel
			</BorderButton>
			<Spacer space={{ x: '100%', y: '157px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default ErrorTechIssue;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

const Icon = styled.img`
	width: 191px;
	aspect-ratio: 1/1;
`;

const Title = styled.div`
	font-family: 'SF-Pro-Display-Bold';
	font-weight: 700;
	font-size: 28px;
	line-height: 32px;
	text-align: center;
	color: ${Colors.primary.white};
	width: 236px;
	margin-left: auto;
	margin-right: auto;
`;
