import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import PrimaryButton from '../CustomButtons/PrimaryButton';

const ImageUploader = (props: any) => {
	const { show = true, close = () => {}, onChange = (_img: any) => {} } = props;

	// const [imgSrc, setImgSrc] = useState<any>(null);

	const openCamera = () => {
		// return;
		// let video: any = document.getElementById('video-feedback');
		// let mediaDevices = navigator.mediaDevices;
		// mediaDevices
		// 	.getUserMedia({
		// 		video: true,
		// 		audio: false,
		// 	})
		// 	.then((stream) => {
		// 		// Changing the source of video to current stream.
		// 		video.srcObject = stream;
		// 		video.addEventListener('loadedmetadata', () => {
		// 			video.play();
		// 		});
		// 	})
		// 	.catch(alert);
	};

	return (
		<Container>
			<Wrapper show={show}>
				{/* <VideoFeedBack
					id='video-feedback'
					src={imgSrc || ''}
				/> */}
				<ContentWrapper>
					<ActionButtons>
						<HiddenInput
							type='file'
							accept='.jpeg,.jpg,.png'
							onChange={(event: any) => {
								// 	const reader = new FileReader();
								// 	reader.readAsDataURL(event?.target?.files[0]);
								// 	// reader.readAsArrayBuffer(event?.target?.files[0]);
								// 	reader.onload = () => {
								// 		onChange(reader.result || null);
								// 	};
								onChange(event?.target?.files[0] || null);
							}}
						/>
						Open Gallery
					</ActionButtons>
					<ActionButtonGrey onClick={openCamera}>
						<HiddenInput
							type='file'
							accept='.jpeg,.jpg,.png'
							capture='environment'
							onChange={(event: any) => {
								// const reader = new FileReader();
								// reader.readAsDataURL(event?.target?.files[0]);
								// reader.onload = () => {
								// 	onChange(reader.result || null);
								// };
								onChange(event?.target?.files[0] || null);
							}}
						/>
						Use camera
					</ActionButtonGrey>
					<ActionButtons onClick={close}>Cancel</ActionButtons>
				</ContentWrapper>
			</Wrapper>
		</Container>
	);
};

export default ImageUploader;

const Container = styled.div`
	overflow: auto !important;
`;

const Wrapper = styled.div<{ show?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: absolute;
	width: 100vw;
	/* height: ${(props) => (props?.show ? '100vh' : '0vh')}; */
	height: 100vh;
	overflow: ${(props) => (props?.show ? 'unset' : 'hidden')} !important;
	background-color: ${Colors.primary.black}99;
	left: ${(props) => (props?.show ? '0vh' : '-100vh')};
	top: 0px;
	transition: 0.9s ease;
	/* backdrop-filter: blur(15px) !important; */
	-webkit-backdrop-filter: blur(15px) !important;
`;

const HiddenInput = styled.input`
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
`;

const ActionButtons = styled(PrimaryButton)`
	position: relative;
	background: ${Colors.primary.white} !important;
	color: ${Colors.primary.black} !important;
	border-radius: 14px;
	padding: 18px;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	margin: 4px 0px;
`;

const ActionButtonGrey = styled(ActionButtons)`
	background: ${Colors.secondary.grey4} !important;
`;

// const VideoFeedBack = styled.video`
const VideoFeedBack = styled.img`
	width: 60%;
	aspect-ratio: 1/1;
	background: white;
	border-radius: 15px;
	object-fit: cover;
`;
