import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import Title from '../../components/Title/Title';
import CustomPhoneInput from '../../components/CustomInput/CustomPhoneInput';
import Spacer from '../../components/Spacer/Spacer';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import { Colors } from '../../utils/configs/Colors';
import styled from 'styled-components';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { SendMobileOtpProps, SendMobileOtpPropsPattern, SendMobileOtpPropsValid } from '../../types_and_interfaces/send-mobile-otp';
import { SendMobileOtp } from '../../utils/services/api/recovery';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorText from '../../components/CustomText/ErrorText';
import { ContextConstants } from '../../utils/services/storage/constants';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes, ToastTypes } from '../../types_and_interfaces/common-context';
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'

const AddNewMobileNumber = () => {

	const { state } = useLocation();

	const { addToast, flow } = useCommonContext();
	
	const [formData, setFormData] = useState<SendMobileOtpProps>({
		MobileNumber: '',
		Email: state?.email
	});

	const [formDataValidState, setFormDataValidState] =
		useState<SendMobileOtpPropsValid>({
			MobileNumber: false,
		});

	const [enableSubmit, setEnableSubmit] = useState<boolean>(false);

	const getRegex = (target: string): RegExp => {
		let reg = new RegExp(/w+/);
		switch (target) {
			case 'MobileNumber':
				reg = SendMobileOtpPropsPattern.MobileNumber;
				break;
			default:
				reg = new RegExp(/w+/);
				break;
		}
		return reg;
	};

	useEffect(() => {
		let values = Object.values(formDataValidState);
		let result = values.find((value) => value !== true);
		setEnableSubmit(result === undefined);
	}, [formDataValidState]);

	const handleChange = (value: string, target: string) => {
		setFormData((current: SendMobileOtpProps) => ({
			...current,
			[target]: value || '',
		}));
		let reg = getRegex(target);
		setFormDataValidState((current) => ({
			...current,
			[target]: reg.test(value),
		}));
	};

	const submitForm = (e: { preventDefault: () => void }) => {
		if(!isValidPhoneNumber(formData.MobileNumber)){ 
			addToast({
				type: ToastTypes.Error,
				message: 'The entered number is invalid. Please enter a valid mobile number.',
			});
			return;
		}
		setEnableSubmit(false);
		SendMobileOtp(formData).then((data) => {
			console.log(data);
			setEnableSubmit(true);
			if (data.isSuccess) {
				goToNextPage(data.message);
			} else{
				addToast({
					type: ToastTypes.Error,
					message: data.message,
				});
			}
		}).catch((err)=>{
			console.log(err);
			setEnableSubmit(true);
			// addToast({
			// 	type: ToastTypes.Error,
			// 	message: 'Error occured while sending SMS OTP',
			// });
			goToError();
		});
	};

	const navigate = useNavigate();

	const goToNextPage = (mobileVerificationToken: string) => {
		navigate('/mobileConfirmationChangeMobile', {state: {mobileNumber: formData.MobileNumber, email: state.email, recoveryToken: mobileVerificationToken}});
	};

	const goBack = () => {
		navigate(-1);
	};

	const goToError = () => {
		navigate('/errorChangeMobile');
	}

	return (
		<PageWrapper>
			<LogoHeader />
			<Title
				title='Add new mobile number' 
				color={Colors.primary.white}
			/>
			<CustomPhoneInput value={formData.MobileNumber}
					onChange={(value) => handleChange(value, 'MobileNumber')}
					error={ 
						!formDataValidState.MobileNumber && formData.MobileNumber !== ''
					}
					errorComp={!isValidPhoneNumber(formData.MobileNumber) && formData.MobileNumber !== '' && <ErrorText>Please enter a valid phone number</ErrorText>}/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton onClick={submitForm} disabled={!enableSubmit}>Continue</PrimaryButton>
			<SwitcherContainer>
				<SwitcherLink onClick={goBack}>&nbsp;Go Back</SwitcherLink>
			</SwitcherContainer>
			<Spacer space={{ x: '100%', y: '75px' }} />
			<GoWellFooter />
		</PageWrapper>
	);
};

export default AddNewMobileNumber;

const SwitcherContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const SwitcherText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.grey.lightGrey1};
`;

const SwitcherLink = styled(SwitcherText)`
	color: ${Colors.primary.red};
	cursor: pointer;
`;
