import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import Spacer from '../../components/Spacer/Spacer';
// import Title from '../../components/Title/Title';
import CustomText from '../../components/CustomText/CustomText';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';
import BorderButton from '../../components/CustomButtons/BorderButton';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { MiniProfileProps } from '../../types_and_interfaces/mini-profile';
import {
	GetMiniProfileById,
	CollectPaymentHostedPage,
	BuyGoWellGymMembership,
} from '../../utils/services/api/signup';
import {
	FlowTypes,
	ToastTypes,
} from '../../types_and_interfaces/common-context';
import { useCommonContext } from '../../context/CommonContext';
import CustomCheckBox from '../../components/CustomInput/CustomCheckBox';
import TextButton from '../../components/CustomButtons/TextButton';
import TermsAndConditionPopup from '../../components/Popup/TermsAndConditionPopup';

const ChurnSelectIdEmpty = () => {
	const { state } = useLocation();
	const search = useLocation().search;
	const userEmail = new URLSearchParams(search).get('email') || '';

	const { addToast, flow } = useCommonContext();

	useEffect(() => {
		getMiniProfile();
	}, [userEmail]);

	const [TACState, setTACState] = useState<boolean>(false);
	const [agreedTAC, setAgreedTAC] = useState<boolean>(false);
	const [formData, setFormData] = useState<MiniProfileProps>({
		userId: '',
		userProfileImage: '',
		userProfileName: '',
		userProfileMembershipId: '',
		billingDetailStartDate: '',
		billingDetailNextBillingDate: '',
		billingDetailOutstandingAmount: '',
		membershipDetailName: '',
		membershipDetailMonthlyBilling: '',
		membershipDetailMonthlyBillingWithVAT: '',
		currencyCode: '',
		userState: '',
		b2bloginuserId: '',
		amountDueInvoicesIds: [],
		userEmail: '',
		mobileNumber: '',
		automaticCancellationDate: '',
	});

	const getMiniProfile = () => {
		GetMiniProfileById(userEmail || 'user').then((data) => {
			console.log(data);
			setFormData(data);
			// if (data &&
			// 	data?.userState == 'Churn' ||
			// 	(data?.billingDetailOutstandingAmount.length > 0 &&
			// 		data?.billingDetailOutstandingAmount > '0.00')
			// ) {
			// 	//re-direct to mini-profile flow
			// 	setFormData(data);
			// } else {
			// }
		});
	};

	const getPayNow = (e: { preventDefault: () => void }) => {
		CollectPaymentHostedPage(
			formData.userId,
			formData.amountDueInvoicesIds,
			formData.billingDetailOutstandingAmount
		).then((data) => {
			if (!data) {
				addToast({
					type: ToastTypes.Error,
					message: 'Error on payment collection, try again.',
					autoHide: true,
					autoHideTime: 5000,
				});
			}
			if (data && data.url) {
				window.location.href = data?.url;
			}
		});
	};

	const purchaseGoWellMembership = (e: { preventDefault: () => void }) => {
		BuyGoWellGymMembership(userEmail || 'user', flow == FlowTypes.Churn ? '1' : '2').then((res) => {
			window.location.href = res;
		});
	};

	const navigate = useNavigate();

	const goToContactUs = () => {
		if(flow == 'Churn'){
		navigate('/purchasingSupport');
		} else if (flow == 'NoProductsAvailable'){
			navigate('/purchasingSupport2');
		}
	};

	const openTAC = () => {
		setTACState(true);
	};

	const closeTAC = () => {
		setTACState(false);
	};

	const confirmTAC = () => {
		setAgreedTAC(true);
		closeTAC();
	};

	const cancelTAC = () => {
		setAgreedTAC(false);
		closeTAC();
	};

	const renderContent = () => {
		switch (flow) {
			case FlowTypes.NoProductsAvailable:
			default:
				return (
					<>
						{/* <Spacer space={{ x: '100%', y: '9px' }} /> */}
						<CustomText text='To enjoy the GoWell Gym Pass, sign up below:' />
						<Spacer space={{ x: '100%', y: '45px' }} />
						<Row>
							<Left>
								<Text>Product</Text>
							</Left>
							<Right>
								<Text>
									{formData?.membershipDetailName || 'GoWell Gym Pass'}
								</Text>
							</Right>
						</Row>
						<Spacer space={{ x: '100%', y: '12px' }} />
						<Row>
							<Left>
								<Text>Price</Text>
							</Left>
							<Right>
								<Text>
									{formData?.membershipDetailMonthlyBilling ||
										'AED 125 + VAT/ month'}
								</Text>
							</Right>
						</Row>
						<Spacer space={{ x: '100%', y: '12px' }} />
						<Row>
							<Left>
								<Text>Contract</Text>
							</Left>
							<Right>
								<Text>12 Months</Text>
							</Right>
						</Row>
						<Spacer space={{ x: '100%', y: '39px' }} />
						<CustomCheckBox
							title={
								<CheckBoxRow>
									By ticking this box you are agreeing with our
									<TextButton
										width='auto'
										color={Colors.primary.white}
										padding='0px'
										onClick={openTAC}>
										Terms and Conditions
									</TextButton>
								</CheckBoxRow>
							}
							value={agreedTAC}
							onChange={(e) => {
								setAgreedTAC(e?.target?.checked || false);
							}}
						/>
						<Spacer space={{ x: '100%', y: '18px' }} />
						<PrimaryButton
							onClick={purchaseGoWellMembership}
							disabled={!agreedTAC}>
							Pay now
						</PrimaryButton>
						<Spacer space={{ x: '100%', y: '24px' }} />
						<BorderButton
							background={Colors.primary.black}
							borderColor={Colors.primary.white}
							onClick={goToContactUs}>
							Contact us
						</BorderButton>
						<Spacer space={{ x: '100%', y: '56px' }} />
						<TermsAndConditionPopup
							show={TACState}
							hide={closeTAC}
							confirmAction={confirmTAC}
							cancelAction={cancelTAC}
						/>
					</>
				);
			case FlowTypes.Churn:
				return (
					<>
						<Title>Oops</Title>
						<Spacer space={{ x: '100%', y: '24px' }} />
						<CustomText
							text='Looks like you have an outstanding amount. Please clear your unsettled debt before continue.'
							alignment='center'
						/>
						<Spacer space={{ x: '100%', y: '24px' }} />
						<Row>
							<Left>
								<Text>Outstanding Amount</Text>
							</Left>
							<Right>
								<ErrorText>
									- {formData.currencyCode || 'AED'}{' '}
									{formData.billingDetailOutstandingAmount}
								</ErrorText>
							</Right>
						</Row>
						<Spacer space={{ x: '100%', y: '12px' }} />
						<Row>
							<Left>
								<Text>Due date</Text>
							</Left>
							<Right>
								<ErrorText>
									{formData.billingDetailNextBillingDate || 'Immediate'}
								</ErrorText>
							</Right>
						</Row>
						<Spacer space={{ x: '100%', y: '55px' }} />
						<PrimaryButton onClick={getPayNow}>Pay now</PrimaryButton>
						<Spacer space={{ x: '100%', y: '24px' }} />
						<BorderButton
							background={Colors.primary.black}
							borderColor={Colors.primary.white}
							onClick={goToContactUs}>
							Contact us
						</BorderButton>
						<Spacer space={{ x: '100%', y: '55px' }} />
					</>
				);
		}
	};

	return (
		<PageWrapper padding='10px 0px' chrun={true} >
			<Spacer space={{ x: '100%', y: '24px' }} />
			<ProfilePicture
				disableEdit={true}
				hideMembership={true}
				profileImageUrl={formData?.userProfileImage || ''}
				MemberName={formData?.userProfileName || ''}
				MembershipId={formData?.userProfileMembershipId || ''}
			/>
			<Spacer space={{ x: '100%', y: '16px', yMin: '23px' }} />
			<ContentWrapper>{renderContent()}</ContentWrapper>
			<GoWellFooter />
		</PageWrapper>
	);
};

export default ChurnSelectIdEmpty;

const Title = styled.div`
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: ${Colors.primary.white};
`;

const Text = styled.span`
	color: ${Colors.primary.white};
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${Colors.primary.red};
`;

const Left = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
`;

const Right = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
`;

const ErrorText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: ${Colors.primary.red};
`;

const CheckBoxRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
`;

const ContentWrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
`;
