import { LoginProps } from "../../../types_and_interfaces/login";
import { MiniProfileProps } from "../../../types_and_interfaces/mini-profile";
import { UserSignUpProps } from "../../../types_and_interfaces/user-signup";
import { VitalityRequestProps } from "../../../types_and_interfaces/vitality";
import { get, post } from "./api";

export const SubmitSignUp = async (data: UserSignUpProps) => {
  try {
    const response = await post("origin1", "b2buser/signup", data, false);

    if (response.isSuccess) {
      data.UserId = response.data;
      SendEmailOtp(data);
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const SendEmailOtp = async (data: UserSignUpProps) => {
  try {
    const response = await post("origin1", "b2buser/sendotpemail", data, false);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyEmailByOtp = async (data: UserSignUpProps) => {
  try {
    const response = await post("origin1", "b2buser/verifyemail", data, false);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const SendMobileOtp = async (data: UserSignUpProps) => {
  try {
    const response = await post(
      "origin2",
      "B2BRecoveryOption/sendMobileOtp",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const SendMobileOtpSignupflow = async (data: UserSignUpProps) => {
  try {
    const response = await post(
      "origin1",
      "b2buser/sendotpMobileNumber",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const VerifyMobileByOtp = async (data: UserSignUpProps) => {
  try {
    const response = await post(
      "origin1",
      "b2buser/verifymobilenumber",
      data,
      false
    );
    console.log("====================================");
    console.log(response);
    console.log("====================================");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const LoginApi = async (data: LoginProps) => {
  try {
    const response = await post(
      "origin2",
      "Authinticate/login-clientapp",
      data,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const ValidateToken = async (token: string) => {
  try {
    const response = await get(
      "origin2",
      "Authinticate/validate-token/" + token,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const GetMiniProfileById = async (id: string) => {
  try {
    const response = await get(
      "origin2",
      "B2BMiniProfile/getMiniProfileById/" + id,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const GetMiniProfileByNewB2BLoginUserId = async (
  b2bloginuserId: string
) => {
  try {
    const response = await get(
      "origin2",
      "B2BMiniProfile/getMiniProfileByNewB2BLoginUserId/" + b2bloginuserId,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const BuyGoWellGymMembership = async (
  email: string,
  churnType?: string
) => {
  try {
    const response = await get(
      "origin2",
      churnType !== null && churnType !== undefined
        ? "Sales/BuyGoWellGymMembership/" + email + "/" + churnType
        : "Sales/BuyGoWellGymMembership/" + email,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

//https://localhost:5001/api/Sales/BuyGoWellGymMembership/info10@techtranz.com

export const GetUpdatePaymentMethod = async (userId: string) => {
  try {
    const response = await get(
      "origin2",
      "B2BMiniProfile/getUpdatePaymentMethod/" + userId,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const IsSelectUserExsist = async (email: string) => {
  try {
    const response = await get(
      "origin2",
      "UserManagement/username/isTaken/" + email,
      false
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const CollectPayment = async (
  userId: string,
  outstandingInvoiceIds: string[],
  outstandingAmount: string
) => {
  try {
    const response = await post(
      "origin2",
      "B2BMiniProfile/CollectPayment/",
      {
        userId: userId,
        OutstandingInvoiceIds: outstandingInvoiceIds,
        amount: outstandingAmount,
      },
      false
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const CollectPaymentHostedPage = async (
  userId: string,
  outstandingInvoiceIds: string[],
  outstandingAmount: string
) => {
  try {
    const response = await post(
      "origin2",
      "B2BMiniProfile/CollectPaymentHostedPage/",
      {
        userId: userId,
        OutstandingInvoiceIds: outstandingInvoiceIds,
        amount: outstandingAmount,
      },
      false
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateVitality = async (b2bloginuserName: string) => {
  try {
    const response = await post(
      "origin2",
      "B2BMiniProfile/UpdateVitality/" + b2bloginuserName,
      false
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUserByAdmin = async (userId: string) => {
  try {
    const response = await get(
      "origin2",
      "Admin/getUserDetail/" + userId,
      false
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getVitalityUser = async (data: VitalityRequestProps) => {
  try {
    const response = await post(
      "origin1",
      "VitalityIntegration/GetUserStatus",
      data,
      false
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
