import React from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import LogoHeader from '../../components/LogoHeader/LogoHeader';
import CustomText from '../../components/CustomText/CustomText';
import Spacer from '../../components/Spacer/Spacer';
import GoWellFooter from '../../components/GoWellFooter/GoWellFooter';
import { Colors } from '../../utils/configs/Colors';
import Title from '../../components/Title/Title';
import styled from 'styled-components';
import SuccessIcon from '../../assets/images/SuccessIcon.svg';
import PrimaryButton from '../../components/CustomButtons/PrimaryButton';

const SignupSuccess = () => {
	return (
		<PageWrapper>
			<LogoHeader />
			<Spacer space={{ x: '100%', y: '32px' }} />
			<IconContainer>
				<Icon src={SuccessIcon} />
			</IconContainer>
			<Title
				title='Your account has been successfully created'
				color={Colors.primary.white}
			/>
			<CustomText
				text='Congratulations! You can now take advantage of our account benefits to enhance your gym experience.'
				alignment='center'
			/>
			<Spacer space={{ x: '100%', y: '100%' }} />
			<PrimaryButton >Go to profile</PrimaryButton>
			<Spacer space={{ x: '100%', y: '161px' }} />
		</PageWrapper>
	);
};

export default SignupSuccess;

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
`;

const Icon = styled.img`
	width: 191px;
	aspect-ratio: 1/1;
`;
