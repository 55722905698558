import * as React from 'react';
import { styled } from 'styled-components';
import { Colors } from '../../utils/configs/Colors';
import JohnDoe from '../../assets/images/JohnDoe.svg';
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined';
import Spacer from '../Spacer/Spacer';
import { ProfilePictureProps } from '../../types_and_interfaces/profile-picture';
import QRCodeSVG from '../../assets/images/QRCode.svg';
import { useNavigate } from 'react-router-dom';
import { useCommonContext } from '../../context/CommonContext';
import { FlowTypes } from '../../types_and_interfaces/common-context';

const ProfilePicture = (props: any) => {
	const {
		disableEdit,
		hideMembership,
		profileImageUrl,
		MemberName,
		MembershipId,
		UserId,
		userEmail,
		openQRCode = () => {},
	} = props;

	const navigate = useNavigate();
	const { updateFlow } = useCommonContext();

	const goToUpdatePhoto = () => {
		updateFlow(FlowTypes.MiniProfile);
		// navigate(`/addProfilePhoto?userId=${UserId}&userEmail=${userEmail}`);
		navigate(`/updateProfilePhoto?userId=${UserId}&email=${userEmail}`);
	};
	return (
		<Container>
			<ImageContainer>
				<Image src={profileImageUrl} />
				{disableEdit ? null : (
					<UploadButton>
						<CameraIcon onClick={goToUpdatePhoto} />
					</UploadButton>
				)}
			</ImageContainer>
			<Spacer space={{ x: '100%', y: '48px' }} />
			<UserName>{(MemberName || '').trim()}</UserName>
			{hideMembership ? null : (
				<>
					<MemberShipContainer>
						<MemberShipContentLeft>Membership ID</MemberShipContentLeft>
						<MemberShipContentRight>{MembershipId}</MemberShipContentRight>
					</MemberShipContainer>
					<Spacer space={{ x: '100%', y: '32px' }} />
					<CheckInButton onClick={openQRCode}>
						<CheckInButtonText>Check in</CheckInButtonText>
						<QRIcon src={QRCodeSVG} />
					</CheckInButton>
				</>
			)}
		</Container>
	);
};

export default ProfilePicture;

const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding-left: 40px;
	padding-right: 40px;
`;

const ImageContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Image = styled.img`
	width: 245px;
	aspect-ratio: 245/245;
	object-fit: cover;
	object-position: center;
	border: 1px solid ${Colors.grey.lightGrey3};
	border-radius: 40px;
`;

const UploadButton = styled.button`
	position: absolute;
	background-color: ${Colors.primary.red};
	color: ${Colors.primary.white};
	width: 46px;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 0px solid transparent;
	bottom: -5px;
	right: 20px;
`;

const CameraIcon = styled(CameraAltOutlined)`
	font-size: 24px !important;
`;

const UserName = styled.div`
	font-family: 'SF-Pro-Display-Bold';
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
	color: ${Colors.primary.white};
	margin-bottom: 9px;
	text-align: left;
	/* margin-left: -40px; */
`;

const MemberShipContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${Colors.primary.white};
`;

const MemberShipContentLeft = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
`;

const MemberShipContentRight = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
`;

const CheckInButton = styled.button`
	background-color: ${Colors.primary.red};
	color: ${Colors.primary.white};
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;
	box-sizing: border-box;
	width: 100%;
	border: 0px solid transparent;
`;

const CheckInButtonText = styled.div`
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
`;

const QRIcon = styled.img`
	width: 43px;
	aspect-ratio: 1/1;
`;
